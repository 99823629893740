export enum ChargeBackStore {
  dashChargeBacks = 'dashChargeBacks',
  dashUnAckChargeBacks = 'dashUnAckChargeBacks',
  dashOnHoldChargeBacks = 'dashOnHoldChargeBacks',
  dashAckUnSubmitChargeBacks = 'dashAckUnSubmitChargeBacks',
  dashChargeBackInstallments = 'dashChargeBackInstallments',
  dashUnsentInstallments = 'dashUnsentInstallments'
}

export enum ChargeBacksEvent {
  printChargeBack = 'printChargeBack',
  submitChargeBack = 'submitChargeBack',
}

export enum ChargeBackInstallmentTypeLabel {
  fullAmount = 'Submit For Full Amount',
  partialAmount = 'Submit As Installments',
  fullAmountSubmitted = 'Submitted For Full Amount',
  partialAmountSubmitted = 'Submitted As Installments'
}


export enum ChargeBackSummaryType {
  accounting = 'accounting',
  ackUnsubmitted = 'ackUnsubmitted',
  onHold = 'onHold',
  unacknowledged = 'unacknowledged',
}

export enum ChargeBackCountType {
  unSubmittedNotRefrain = 'unSubmittedNotRefrain',
  unAckChargeBacks = 'unAckChargeBacks',
  onHoldChargeBacks = 'onHoldChargeBacks',
  ackUnSubmitChargeBacks = 'ackUnSubmitChargeBacks',
  unSentInstallments = 'unSentInstallments'
}

export enum ChargeBackCountTabLabel {
  unSubmittedNotRefrain = 'Default (Unsubmitted)',
  unAckChargeBacks = 'Unacknowledged',
  onHoldChargeBacks = 'On Hold',
  ackUnSubmitChargeBacks = 'Acknowledged Unsubmitted',
  unSentInstallments = 'Unsent'
}

