<portal-detail-layout-container [portalId]="portalId"
                                [portalEntityId]="'-1'"
                                [title]="title"
                                [tabContainerName]="tabContainerName"
                                (tabsLoadedEvent)="onTabsLoaded($event)">
  <fw-section-title title="Chargeback Counts" [underline]="true"></fw-section-title>
  <div [ngClass]="{ 'loading-mask-no-spinner': loading$ | async }">
    <dashboard-counts *ngIf="(dashCounts$ | async) as dashCounts"
                      [dashCounts]="dashCounts"
                      [errorData]="errorData$ | async"
                      [errorMessage]="errorMessage">
    </dashboard-counts>
  </div>
  <fw-section-title title="Installment Counts" [underline]="true"></fw-section-title>
  <div [ngClass]="{ 'loading-mask-no-spinner': loading$ | async }">
    <dashboard-counts *ngIf="(dash2Counts$ | async) as dashCounts"
                      [dashCounts]="dashCounts"
                      [errorData]="errorData$ | async"
                      [errorMessage]="errorMessage">
    </dashboard-counts>
  </div>
</portal-detail-layout-container>
