import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

import { IListColumn, ListColumn, ListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { UtilitiesEvent, UtilitiesStore } from '../enums/utilities.enums';
import { UserPriviledgesService } from '../../../../auth/services';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { appConstants, IAppConstants } from '../../../../shared/constants';

@Injectable()
export class UtilitiesService {

  constructor(public datePipe: DatePipe,
    public userPriviledgesService: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  loadCustomerSearchDetailListDefinition(id: number): IListDefinition {
    const listColumns = this.loadCustomerSearchDetailListColumns();
    const listObjectLabel = 'Project';
    const listObjectController = 'Project';
    const listStoreName = UtilitiesStore.dashCustomerProjects;
    const detailRoutePath = '';
    const listRowKeyId = 'projectId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('startDate')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.showPrevNext = true;
    listDefinition.controllerMethod = 'ByContact';
    listDefinition.methodParameters = id.toString();

    listDefinition.rowButtons = [
      {
        title: 'Peek at Purchase Orders',
        icon: 'fad fa-ballot',
        cssName: 'app-btn-icon--custom-3',
        enabled: true,
        eventName: UtilitiesEvent.peekPOSummary
      },
      {
        title: 'Peek at Work Orders',
        icon: 'fad fa-clipboard-list-check',
        cssName: 'app-btn-icon--custom-2',
        enabled: true,
        eventName: UtilitiesEvent.peekWOSummary
      }
    ];

    //no crud buttons
    return listDefinition;
  }

  loadCustomerSearchDetailListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isWarranty');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectStatus');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    colDef.slowSort = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('startDate');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadCustomerSearchListDefinition(userId: number, canFlagCustomer: boolean): IListDefinition {
    const listColumns = this.loadCustomerSearchListColumns();
    const listObjectLabel = 'Customer Search';
    const listObjectController = 'Contact';
    const listStoreName = UtilitiesStore.dashCustomers;
    const detailRoutePath = 'customer-search-detail';
    const listRowKeyId = 'contactId';
    let defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('customerSearchDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath,
      false);

    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = userId.toString();
    listDefinition.parentId = -1;
    listDefinition.showFilter = true;
    listDefinition.showPrevNext = true;
    listDefinition.noGet = true;
    listDefinition.openInFilterMode = true;

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, 'Row'); //use generic name for btns
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View Contact Info',
        icon: 'fas fa-address-card',
        cssName: 'app-btn-icon--custom-1',
        enabled: true,
        eventName: UtilitiesEvent.viewContactInfo
      },
      {
        title: 'View Customer Flags',
        icon: 'fas fa-flag',
        cssName: 'app-btn-icon--neutral',
        enabled: canFlagCustomer,
        eventName: UtilitiesEvent.viewCustomerFlags
      }
    );

    return listDefinition;
  }

  loadCustomerSearchListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('customerName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('primaryAddress');
    colDef.visibleOnSmall = false;
    colDef.slowSort = true;
    colDef.slowFilter = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationPreferenceValue');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('primaryPhone');
    colDef.slowSort = true;
    colDef.slowFilter = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('primaryEmail');
    colDef.slowSort = true;
    colDef.slowFilter = true;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sssOptOut');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadScheduleMoveUpsListDefinition(userId: number): IListDefinition {
    const listColumns = this.loadScheduleMoveUpsListColumns();
    const listObjectLabel = 'Schedule Move Ups';
    const listObjectController = 'ProviderLocationScheduleMoveUp';
    const listStoreName = UtilitiesStore.dashScheduleMoveUps;
    const detailRoutePath = '';
    const listRowKeyId = 'providerLocationScheduleMoveUpId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{ term: 'onlyWarranty', value: false, searchType: SearchType.Equals, columnName: 'onlyWarranty', fieldType: this.myConstants.dataTypeBool }];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = userId.toString();
    listDefinition.parentId = -1;
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    listDefinition.showFilter = true;
    let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0, 
      {
        title: 'Schedule Work Order',
        icon: 'fad fa-calendar-alt',
        cssName: 'app-btn-icon--custom-2',
        enabled: true,
        eventName: UtilitiesEvent.scheduleWorkOrder
      }
    );

    listDefinition.rowStatus = {
      cssMethod: 'getImmediateMoveUpCss',
      methodService: 'utilitiesDynamicListService'
    };

    return listDefinition;
  }

  loadScheduleMoveUpsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('createDate');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('currentSchedule');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branch');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('program');
    colDef.visibleOnSmall = false; //maybe on all
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locationService');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('requestedDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('moveUpText');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('inventoryExpectedDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workCategories');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadEmailImportsListDefinition(): IListDefinition {
    const listColumns = this.loadEmailImportsListColumns();
    const listObjectLabel = 'Project Email Import';
    const listObjectController = 'EmailImport';
    const listStoreName = UtilitiesStore.dashEmailImports;
    const detailRoutePath = 'email-imports-detail';
    const listRowKeyId = 'emailImportId';
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('emailImportsDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.parentId = -1;
    listDefinition.parentKey = '';

    listDefinition.showFilter = true;
    listDefinition.showPrevNext = true;

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadEmailImportsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('fromUser');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sendDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('subject');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('body');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('numItems');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadDispatchCalendarListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadDispatchCalendarListColumns();
    const listObjectLabel = 'Dispatch Calendar';
    const listObjectController = 'WorkOrderViewModel';
    const listStoreName = UtilitiesStore.dashCalendar;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderId';
    const today: string = this.datePipe.transform(new Date(), 'MM/dd/yyyy');

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath,
      false);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'CalendarByProviderUser';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = [
      { term: 'scheduleStartDate', value: today, searchType: SearchType.Equals, columnName: 'scheduleStartDate', fieldType: this.myConstants.dataTypeDate }
    ];

    //no filter
    listDefinition.showFilter = false;
    const delDefaults: ICustomButton = {
      title: 'Delete Schedule',
      icon: '',
      cssName: '',
      enabled: true,
      eventName: UtilitiesEvent.deleteSchedule,
      enabledMethod: 'canDeleteSchedule',
      methodService: 'utilitiesDynamicListService'
    };

    let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: delDefaults }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Go To Work Order',
        icon: 'fad fa-share-square',
        cssName: 'app-btn-icon--custom-3',
        enabled: true,
        eventName: UtilitiesEvent.goToWorkOrder
      },
      {
        title: 'Show Packet',
        icon: 'fas fa-print',
        cssName: 'app-btn-icon--custom-1',
        cssMethod: '',
        enabled: true,
        enabledMethod: 'canViewWoPacket',
        eventName: UtilitiesEvent.showWoPacket,
        methodService: 'utilitiesDynamicListService'
      },
      {
        title: 'Reschedule Work Order',
        icon: 'fad fa-calendar-alt',
        cssName: 'app-btn-icon--custom-2',
        enabled: true,
        eventName: UtilitiesEvent.rescheduleWorkOrder
      },
      {
        title: 'Add Work Order Note',
        icon: 'fas fa-comment-plus',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: UtilitiesEvent.addWorkOrderNote
      },
      {
        title: 'Dispatch',
        icon: 'far fa-people-carry',
        cssName: 'app-btn-icon--neutral',
        enabled: true,
        eventName: UtilitiesEvent.dispatchWorkOrder,
        enabledMethod: 'canDispatch',
        methodService: 'utilitiesDynamicListService'
      }
    );

    listDefinition.rowStatus = {
      cssMethod: 'getDispatchServiceCss',
      methodService: 'utilitiesDynamicListService'
    };

    return listDefinition;
  }

  loadDispatchCalendarListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('customerWarningText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('scheduleStartAndEndDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locationName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderStatus');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderCity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workCrewName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderInfo');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadHomHolidaysListDefinition(): IListDefinition {
    const listColumns = this.loadHomHolidaysListColumns();
    const listObjectLabel = 'Hom Holidays';
    const listObjectController = 'SlotScheduleExclusion';
    const listStoreName = UtilitiesStore.homHolidays;
    const detailRoutePath = ''; //tbd when implement admin component for holidays
    const listRowKeyId = 'slotScheduleExclusionId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('day')];
    defaultListFilter.getAll = true;

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath,
      false);

    listDefinition.parentId = -1;

    //no filter
    listDefinition.showFilter = false;
    listDefinition.controllerMethod = 'Index';
    listDefinition.requiredSearchTerms = [];

    let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadHomHolidaysListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('day');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadInstallerVacationsListDefinition(): IListDefinition {
    const listColumns = this.loadInstallerVacationListColumns();
    const listObjectLabel = 'Installer Vacations';
    const listObjectController = 'InstallerWorkCrewScheduleExclusionViewModel';
    const listStoreName = UtilitiesStore.dashInstallerVacations;
    const detailRoutePath = '';
    const listRowKeyId = 'InstallerWorkCrewScheduleExclusionId';

    let defaultListFilter: IListFilter = new ListFilter();
    const todayDate = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');

    defaultListFilter.searchTerm = [{ term: 'jobSchedule_jobStartDate', value: todayDate, searchType: SearchType.GreaterThanEqual, columnName: 'jobStartDate', fieldType: this.myConstants.dataTypeDate }];
    defaultListFilter.orderTerm = null;

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.showPrevNext = false;
    listDefinition.controllerMethod = 'Index';

    //no crud buttons
    return listDefinition;
  }

  loadInstallerVacationListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('installerWorkCrewName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobStartDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobEndDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobScheduleSummary');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('providerLocationNames');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceNames');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
