import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { UserPriviledgesService } from '../../../auth/services/index';

import { AcctgDashboardRoutingModule } from './acctg-dashboard-routing.module';

//containers
import {
  AcctgDashboardComponent,
  AcknowledgedUnsubmittedChargebacksComponent,
  OnHoldChargebacksComponent,
  UnsentInstallmentsComponent,
  UnacknowledgedChargebacksComponent,
  UnsubmittedChargebacksComponent
} from './containers/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import { AcctgDashboardService } from './services/acctg-dashboard.service';
import { ChargeBackDashboardService } from '../portal-shared/services/charge-back-dashboard.service';


@NgModule({
  declarations: [
    AcctgDashboardComponent,
    AcknowledgedUnsubmittedChargebacksComponent,
    OnHoldChargebacksComponent,
    UnsentInstallmentsComponent,
    UnacknowledgedChargebacksComponent,
    UnsubmittedChargebacksComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FwModule,
      FwSharedModule,
      SharedModule,
      AcctgDashboardRoutingModule,
      PortalSharedModule
  ],
  providers: [
    IsCurrentGuard,
    UserPriviledgesService,
    AcctgDashboardService,
    ChargeBackDashboardService
  ]
})

export class AcctgDashboardModule {}
