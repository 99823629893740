import { Input, Component, OnInit, OnChanges, SimpleChanges, Output,  Inject, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup } from '@angular/forms';
import { Subscription } from "rxjs";
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IInstaller, IAddress, IPhone, IEmail, IInstallerUpdateViewModel } from '../../../view-models/index';
import { CommunicationEventEvent } from "../../../portal-shared/enums/communication-event.enums";
import { IConversationRequest } from "../../../portal-shared/interfaces/i-conversation-request";
import { IAddressLines, IAddressZips } from '../../../../contact/interfaces';
import { IFormDefinition, FormDefinition } from '../../../../../fw/dynamic-forms';
import { ICustomDetailButton } from '../../../../../fw/dynamic-detail/interfaces';

import { MetaDataService} from '../../../../../fw/dynamic-list/services/index'
import { ContactUtilityService } from '../../../../contact/services';
import { IErrorData } from '../../../../../../../installer-portal/src/app/ip-fw/interfaces';

@Component({
  selector: 'installer-detail',
  templateUrl: './installer-detail.component.html',
  providers: [MetaDataService]
})
export class InstallerDetailComponent implements OnInit, OnChanges, OnDestroy  {
  @Input() installer: IInstaller;  
  @Input() displayFields: string[];
  @Input() storeName: string;
  @Input() canIEdit: boolean;
  @Input() operation: string;
  @Input() staleData: boolean;
  @Input() errorData: IErrorData[];

  @Output() public createEvent = new EventEmitter<IInstallerUpdateViewModel>();
  @Output() public cancelEvent = new EventEmitter();
  @Output() public dirtyEvent = new EventEmitter();
  
  public title: string = 'Installer';
  public form: FormGroup;
  public states: any[] = [];
  public businessTypes: any[] = []; 
  public customButtons: ICustomDetailButton[] = [];
  public formDefinition: IFormDefinition;
  subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public mds: MetaDataService,
    public emitterService: HomEventEmitterService,
    public contactUtility: ContactUtilityService,
    @Inject(appConstants) public myConstants: IAppConstants) {  }

  public onCancelCreate() {
    this.cancelEvent.emit();
  }

  public onCreate(): void {
    let model: IInstallerUpdateViewModel = this.setCommonProperties();
    this.createEvent.emit(model);
  }

  public getFormModel(): IInstallerUpdateViewModel {
    return this.setCommonProperties();
  }

  ngOnInit() {
    this.mds.setFieldDefinitions(this.storeName);
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.installer.installerId);
    this.formDefinition = new FormDefinition('Installer', false, false, 'installer-general-information','','', this.customButtons);
    this.loadForm();
    this.subscription.add(this.mds.mdsReady$.subscribe(x => {
      if (x) {
        this.states = this.mds.getSelectItems('state');
        this.businessTypes = this.mds.getSelectItems('businessType');
      }
    }))

    this.subscription.add(this.emitterService.smsEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.event === CommunicationEventEvent.openConversation) {
          this.showConvo(e.data);
        }
      }));

    this.subscription.add(this.mds.valueChanged$
      .subscribe(val => {
      if (this.form.dirty) {
        this.dirtyEvent.emit();
      }
    }));
  }

  //handles flipping between one installer tab and the next and then back
  ngOnChanges(changes: SimpleChanges) {
    if (changes['installer'] && !changes['installer'].isFirstChange()) {
      this.loadForm();
    }
    if (changes['operation'] && !changes['operation'].isFirstChange()) {
      if (this.operation === this.myConstants.operationTypeDetails) {
        this.loadForm();
      }
    }
    if (changes['errorData'] && !changes['errorData'].isFirstChange()) {
      this.mds.setErrorMessages(this.errorData);
    }

    
  }

  loadForm(): void {
    const installer = Object.assign({}, this.installer);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, installer, this.operation);
  }

  isValid(key: string): boolean {
    return  this.mds.isValid(this.form, key, this.operation);
  }

  setCommonProperties(): IInstallerUpdateViewModel {
    const existingInstaller = cloneDeep(this.installer);
    const formData: IInstaller = this.form.getRawValue();
    let installer: IInstaller = existingInstaller;
    const lines: IAddressLines = this.contactUtility.getAddressLines(formData.addressLine1)
    const zips: IAddressZips = this.contactUtility.getAddressZips(formData.addressZipcode5);

    installer.installerName = formData.installerName;
    installer.businessType = formData.businessType.hasOwnProperty('name') ? formData.businessType['name'] : '';
    installer.isActive = formData.isActive
    installer.wantScheduleDailySummary = formData.wantScheduleDailySummary;
    installer.taxIdNumber = formData.taxIdNumber;
    installer.state_stateId = formData.hasOwnProperty('state') ? formData['state']['stateId'] : existingInstaller.state_stateId;
    installer.contactName = formData.contactName;
    const address: IAddress = formData.addressLine1
      ? {
        addressId: installer.address_addressId,
        line1: lines.line1,
        line2: lines.line2,
        line3: lines.line3,
        city: formData.addressCity.trim(),
        state_stateId: formData.addressStateId.hasOwnProperty('stateId') ? formData.addressStateId['stateId'] : 0,
        stateAbbr: formData.addressStateId.hasOwnProperty('stateAbbr') ? formData.addressStateId['stateAbbr'] : '',
        zipcode5: !zips ? '' : zips.zip5.toString(),
        zipcode4: !zips ? '' : zips.zip4 ? zips.zip4.toString() : null,
        yearBuilt: null,
        sanitizeOverride: false,
        latitude: 0,
        longitude: 0
      }
      : null
    const phone: IPhone = formData.phoneNumber ?
      {
        phoneId: installer.phone_phoneId,
        phoneNumber: formData.phoneNumber
      }
      : null;
    const email: IEmail = formData.emailAddress ?
      {
        emailId: installer.email_emailId,
        emailAddress: formData.emailAddress
      }
      : null;

    return {
      installer: installer,
      address: address,
      email: email,
      phone: phone
    };
  }

  showConvo(request: IConversationRequest): void {
    this.nullChatbar();
    this.router.navigate([{ outlets: { chatbar: ['sms-convo', request.contactId.toString(), request.phone, request.name, request.parentName] } }], {
      relativeTo: this.activeRoute
    }).catch(e => {
      console.log('Route not found, route stopped with no error raised', request);
    });
  }

  nullChatbar(): void {
    if (this.router.url.includes('chatbar:')) {
      this.router.navigate([{ outlets: { chatbar: null } }],
        { relativeTo: this.activeRoute.parent });
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.nullChatbar();
  }
}
