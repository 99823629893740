import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomPipesModule } from 'hom-lib/hom-pipes';
import { FwModule } from '../../../fw/fw.module';
import { SharedModule } from '../../../shared/shared.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { ContactModule } from '../../contact/contact.module';
import { ManagerDashboardRoutingModule } from './manager-dashboard-routing.module';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';

//services
import { UserPriviledgesService } from '../../../auth/services/index';
import { ManagerDashboardService } from './services/manager-dashboard.service';
import { MetaDataService } from '../../../fw/dynamic-list/services';

//components
import {
  InventoryOrderItemsComponent,
  InventoryOrderRequestComponent,
  WorkOrdersRequiringInventoryComponent,  CommunicationEventLogComponent
} from './components/index';

//containers
import {
  ManagerDashboardComponent,
  InventoryOrdersComponent,
  InventoryNeedsComponent,
  PendingApprovalsComponent,
  PendingChangeDenyComponent,
  ManagerToolsComponent,
  PurchaseOrderCreationWizardComponent,
  CommunicationEventLogsComponent,
  OutstandingEntityDocumentsComponent,
  AccountingChargeBacksComponent
} from './containers/index';

@NgModule({
  declarations: [
    ManagerDashboardComponent,
    InventoryOrdersComponent,
    InventoryNeedsComponent,
    PendingApprovalsComponent,
    PendingChangeDenyComponent,
    InventoryOrderItemsComponent,
    InventoryOrderRequestComponent,
    WorkOrdersRequiringInventoryComponent,
    ManagerToolsComponent,
    PurchaseOrderCreationWizardComponent,
    CommunicationEventLogsComponent,
    CommunicationEventLogComponent,
    OutstandingEntityDocumentsComponent,
    AccountingChargeBacksComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      HomPipesModule,
      FwModule,
      SharedModule, 
      FwSharedModule,
      ManagerDashboardRoutingModule,
      PortalSharedModule,
      ContactModule
  ],
  providers: [
    IsCurrentGuard,
    UserPriviledgesService,
    ManagerDashboardService,
    MetaDataService
  ]
})

export class ManagerDashboardModule {}
