  /*
Common Methods Used by Accounting Dashboard components
*/
import { Injectable, Inject } from '@angular/core';
import { IDashboardCount } from '../../portal-shared/interfaces';
import { appConstants, IAppConstants } from '../../../../shared/constants';

import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Injectable()
export class AcctgDashboardService {
  lastDashCounts: IDashboardCount[] = [];
  lastDash2Counts: IDashboardCount[] = [];

  constructor(@Inject(appConstants) public myConstants: IAppConstants,
      public dynamicListService: DynamicListService) { }

  public initSavedValues(): void {
    //clean up for log off
    this.lastDashCounts = [];
    this.lastDash2Counts = [];
  }
}
