<fw-section-title *ngIf="showTitle" title="Job Site Details" [underline]="true" icon="fa-map-marked-alt"></fw-section-title>
<div [ngClass]="{ 'loading-mask': loading$ | async }">

  <!-- ADDRESS -->
  <div class="jsm" *ngIf="(jobSiteAddress$ | async) as jobSiteAddress">
    <div class="jsm__icon">
      <i class="fas fa-globe-americas"></i>
    </div>
    <div class="jsm__values">
      <ng-container *ngIf="jobSiteAddress.addressLine1">
        <div>
          <span>{{jobSiteAddress.addressLine1}}</span>
        </div>
        <div>
          <span *ngIf="jobSiteAddress.addressLine2"> {{jobSiteAddress.addressLine2}}</span>
          <span *ngIf="jobSiteAddress.addressLine3"> {{jobSiteAddress.addressLine3}}</span>
        </div>
        <span>{{jobSiteAddress.addressCity}}</span>
        <span *ngIf="jobSiteAddress.addressCity && jobSiteAddress.addressState">, </span>
        <span>{{jobSiteAddress.addressState}}</span>
        <span *ngIf="jobSiteAddress.addressCity || jobSiteAddress.addressState">&nbsp;&nbsp;</span>
        <span>{{jobSiteAddress.addressZip}}</span>
        <div class="jsm__values__address-type" *ngIf="jobSiteAddress.zoneName">
          <span class="jsm__values__address-type__label">Zone:</span> {{jobSiteAddress.zoneName}}
        </div>
        <div class="jsm__values__address-type">
          <span class="jsm__values__address-type__label">Type:</span> {{jobSiteAddress.addressType}}
        </div>
      </ng-container>
      <ng-container *ngIf="!jobSiteAddress.addressLine1">
        <div *ngIf="(jobSiteAddress$ | async) && !(jobSiteAddress$ | async).addressLine1">
          <span class="app-text--danger"><b>Address Not Found.</b></span>
        </div>
      </ng-container>
    </div>
    <div class="jsm__actions">
      <div class="jsm__actions__items">
        <button *ngIf="jobSiteAddress.addressLine1 && !jobSiteAddress.sanitizeOveride" type="button" class="app-btn-icon app-btn-icon--success" title="View Map" (click)="openMap()">
          <span class="fas fa-map-marker-alt  fa-lg"></span>
        </button>
        <fw-multi-tier-menu *ngIf="!justInfo && (contactAddresses$ | async)" class="jsm__actions__items__btn"
                            [menu]="contactAddresses$ | async"
                            [hoverText]="!(contactAddresses$ | async) ? 'No Other Addresses Exist For this Customer' : 'Select Different Address For This Customer' "
                            [disabled]="!canIEdit || projectStatus === myConstants.projectStatusClosed || !(contactAddresses$ | async)"
                            [customMultiBtnCss]="'job-site-multi-menu'"
                            (selected)="onMechanismChanged($event, myConstants.emitterEventAddressChanged)">
        </fw-multi-tier-menu>
        <fw-info *ngIf="canIEdit && !justInfo" [infoTitle]="''">
          <div class="job-site__help--text">Switch between this customer's Existing addresses from here</div>
          <div class="job-site__help--text">OR</div>
          <div>Use Contact Manager tab where you can:</div>
          <div class="job-site__help--options">
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Switch to a Different customer</div>
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Edit this address for THIS customer</div>
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Add a new address for THIS customer</div>
          </div>
        </fw-info>
      </div>
      <div class="jsm__actions__items">
        <div class="jsm__actions__items__yb">
          Year Built: <span class="jsm__actions__items__yb__val" [ngClass]="yearClass">{{ jobSiteAddress.yearBuilt }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- PHONE -->
  <div class="jsm">
    <div class="jsm__icon">
      <i class="fas fa-phone"></i>
    </div>
    <div class="jsm__values">
      <fw-phone *ngIf="(jobSitePhone$ | async) && (jobSitePhone$ | async).phoneNumber" [phoneNumber]="(jobSitePhone$ | async).phoneNumber"></fw-phone>
      <span *ngIf="(jobSitePhone$ | async) && !(jobSitePhone$ | async).phoneNumber" class="app-text--danger"><b>Phone Not Found.</b> </span>
    </div>
    <div class="jsm__actions">
      <div class="jsm__actions__items">
        <fw-multi-tier-menu *ngIf="!justInfo" class="jsm__actions__items__btn"
                            [menu]="contactPhones$ | async"
                            [hoverText]="!(contactPhones$ | async) ? 'No Other Phones Exist For this Customer' : 'Select Different Phone For This Customer' "
                            [disabled]="!canIEdit  || projectStatus === myConstants.projectStatusClosed || !(contactPhones$ | async)"
                            [customMultiBtnCss]="'job-site-multi-menu'"
                            (selected)="onMechanismChanged($event, myConstants.emitterEventPhoneChanged)">
        </fw-multi-tier-menu>
        <fw-info *ngIf="canIEdit && !justInfo" [infoTitle]="''">
          <div class="job-site__help--text">Switch between this customer's Existing phones from here</div>
          <div class="job-site__help--text">OR</div>
          <div>Use Contact Manager tab where you can:</div>
          <div class="job-site__help--options">
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Switch to a Different customer</div>
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Edit this phone for THIS customer</div>
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Add a new phone for THIS customer</div>
          </div>
        </fw-info>
      </div>
    </div>
  </div>
  <!-- EMAIL -->
  <div class="jsm">
    <div class="jsm__icon">
      <i class="fas fa-envelope"></i>
    </div>
    <div class="jsm__values">
      <fw-email *ngIf="(jobSiteEmail$ | async) as jobSiteEmail" [emailAddress]="jobSiteEmail.emailAddress" [forHighLevelSummary]="true"></fw-email>
    </div>
    <div class="jsm__actions">
      <div class="jsm__actions__items">
        <fw-multi-tier-menu *ngIf="!justInfo" class="jsm__actions__items__btn"
                            [menu]="contactEmails$ | async"
                            [hoverText]="!(contactEmails$ | async) ? 'No Other Emails Exist For this Customer' : 'Select Different Email For This Customer' "
                            [disabled]="!canIEdit  || projectStatus === myConstants.projectStatusClosed || !(contactEmails$ | async)"
                            [customMultiBtnCss]="'job-site-multi-menu'"
                            (selected)="onMechanismChanged($event, myConstants.emitterEventEmailChanged)">
        </fw-multi-tier-menu>
        <fw-info *ngIf="canIEdit && !justInfo" [infoTitle]="''">
          <div class="job-site__help--text">Switch between this customer's Existing emails from here</div>
          <div class="job-site__help--text">OR</div>
          <div>Use Contact Manager tab where you can:</div>
          <div class="job-site__help--options">
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Switch to a Different customer</div>
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Edit this email for THIS customer</div>
            <div><i class="fas fa-square-full app-icon--xs job-site__help--icon"></i>Add a new email for THIS customer</div>
          </div>
        </fw-info>
      </div>
    </div>
  </div>
</div>

  <fw-component-footer [lastUpdate]="lastUpdated$ | async"></fw-component-footer>


