  /*
Custom Methods Used by Dynamic-List specific to project and its child entities.
Set in ICustomButton object within the list definition, row specific
*/
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { IWorkOrder, IPurchaseOrder, IPurchaseOrderInventoryAllocation, IWorkOrderItem, IPurchaseOrderItem, IChargeBack, ICommunicationEvent } from '../../../app/portals/view-models/index';
import { UserPriviledgesService } from '../../../auth/services/index';
import { IEntityDocument } from '../../../app/portals/view-models/index_two';
import { DocumentTypeName } from '../../../app/file-upload/enums/file-upload.enums';

@Injectable()
export class ProjectDynamicListService {

  constructor(public userPriviledgesService: UserPriviledgesService,
                      public datePipe: DatePipe) { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {
      case 'getWarrantyButtonCss':
        return this.getWarrantyButtonCss(row);
      case 'getAccntSyncErrorCss':
        return this.getAccntSyncErrorCss(row);
      case 'canLogCustomerAction':
        return this.canLogCustomerAction(row);
      case 'canViewWarrantyScript':
        return this.canViewWarrantyScript(row);
      case 'canViewWoPacket':
        return this.canViewWoPacket(row);
      case 'canCloseWo':
        return this.canCloseWo(row);
      case 'canAddNote':
        return this.canAddNote(row);
      case 'canViewExternalData':
        return this.canViewExternalData(row);
      case 'canViewMeasure':
        return this.canViewMeasure(row);
      case 'canClosePo':
        return this.canClosePo(row);
      case 'canClosePoAndBypassChecks':
        return this.canClosePoAndBypassChecks(row);
      case 'canSelectMeasure':
        return this.canSelectMeasure(row);
      case 'getOverdueInventoryCss':
        return this.getOverdueInventoryCss(row);
      case 'getIsBaseWorkOrderCss':
        return this.getIsBaseWorkOrderCss(row, portalEntityId);
      case 'getIsCustomLineCss':
        return this.getIsCustomLineCss(row);
      case 'getIsCustomPoLineCss':
        return this.getIsCustomPoLineCss(row);
      case 'canIPrintChargeBackNotice':
        return this.canIPrintChargeBackNotice(row);
      case 'getCommunicationEventCss':
        return this.getCommunicationEventCss(row);
      case 'canDeleteChargeBack':
        return this.canDeleteChargeBack(row);
      case 'canUploadDocument':
        return this.canUploadDocument(row);
      case 'canDownloadDocument':
        return this.canDownloadDocument(row);
      case 'canSignDocument':
        return this.canSignDocument(row);
      case 'canPrintDocument':
        return this.canPrintDocument(row);
      case 'canDeleteRequiredDocument':
        return this.canDeleteRequiredDocument(row);
   default:
        return false;
    }
  }

/*CUSTOM WORK ORDER METHODS TO SET CSS */
  getWarrantyButtonCss(row: IWorkOrder): string {
    return row && row.hasOwnProperty('workOrderWarrantyCount') && row.workOrderWarrantyCount ? 'app-btn-icon--warning' : '';
  }

  /*CUSTOM WORK ORDER METHODS TO DETERMINE BUTTON ENABLED */
  canDeleteChargeBack(row: IChargeBack): boolean {
    return row && !row.submitted;
  }

  canLogCustomerAction(row: IWorkOrder): boolean {
    return row && row.hasOwnProperty('workOrderScheduleText')
      && (row.workOrderScheduleText === '' || row.workOrderScheduleText === 'None'
      || row.workOrderScheduleText === 'To Be Scheduled' || row.workOrderInstallerName === 'None');
  }

  canViewWarrantyScript(row: IWorkOrder): boolean {
    //note hover/title is ste to row.workOrderWarrantyText + '\nClick to view warm/cold handoff scripts';
    //click: $parent.openWarranty.bind($parent, workOrderId), visible: workOrderStatusText() == 'Work Complete' || workOrderStatusText() == 'Dispatched'" >
    //  <img alt="Warranty Claims" src = "/Content/Images/transparent.gif" class="buttonnav-small warranty" data - bind="css: { warning: workOrderWarrantyCount }" />
    //    </a>
    return row && row.hasOwnProperty('workOrderScheduleText') && (row.workOrderStatusText === 'Work Complete' || row.workOrderStatusText === 'Dispatched');
  }

  canViewWoPacket(row: IWorkOrder): boolean {
    return row && row.hasOwnProperty('metaData') && row['metaData'].hasOwnProperty('workOrderWorkPacket') && row['metaData']['workOrderWorkPacket'];
  }

  canCloseWo(row: IWorkOrder): boolean {
    let canEdit: boolean = this.userPriviledgesService.canIEdit(row);
    let afterScheduleDate: boolean = false;
    if (row.workOrderScheduleDate) {
      let scheduleDate = new Date(row.workOrderScheduleDate);
      let tomorrow = new Date().addDays(1);
      afterScheduleDate = scheduleDate < tomorrow;
    }
    return row && canEdit && row.hasOwnProperty('workOrderScheduleText') && (row.workOrderStatusText === 'Dispatched' || row.workOrderStatusText === 'Accounting Error') && afterScheduleDate;
  }

  canAddNote(row: IWorkOrder): boolean {
    return this.userPriviledgesService.canIEdit(row);
  }

  canDeleteWorkOrder(row: IWorkOrder): boolean {
    return this.userPriviledgesService.canIDelete(row) && this.userPriviledgesService.canEditWorkOrder(row);
  }

  canViewExternalData(row: IPurchaseOrder): boolean {
    return row && row.hasOwnProperty('hasExternalExtraData') && row.hasExternalExtraData;
  }

  canViewMeasure(row: IPurchaseOrder): boolean {
    return row && row.hasOwnProperty('metaData') && row['metaData'].hasOwnProperty('measureUrl') && row['metaData']['measureUrl'].length > 0;
  }

  canClosePo(row: IPurchaseOrder): boolean {
    return this.userPriviledgesService.canIEdit(row) && row && row.hasOwnProperty('poCloseEligible') && row.poCloseEligible;
  }

  canClosePoAndBypassChecks(row: IPurchaseOrder): boolean {
    return this.canClosePo(row) && this.userPriviledgesService.canBypassExternalPriceCheck$.getValue();
  }

  getAccntSyncErrorCss(row: IPurchaseOrder): string {
    return row && row.hasOwnProperty('purchaseOrderAccntSyncLog') && row.purchaseOrderAccntSyncLog ? 'dynamic-list__item--danger' : '';
  }

  canSelectMeasure(row: IPurchaseOrder): boolean {
    return this.userPriviledgesService.canIEdit(row);
  }

  canDownloadDocument(row: IEntityDocument): boolean {
    return row.fileUrl ? true : false;
  }

  canUploadDocument(row: IEntityDocument): boolean {
    return true;
  }

  canSignDocument(row: IEntityDocument): boolean {
    //var allowExpertOverride = row.
    return true;
  }

  canPrintDocument(row: IEntityDocument): boolean {
    return row.fileUrl ? true : false;
  }

  canDeleteRequiredDocument(row: IEntityDocument): boolean {
    return row && (row.documentTypeName === DocumentTypeName.leadExceptionForm || row.documentTypeName === DocumentTypeName.leadSafeForm)
      ? this.userPriviledgesService.canManageLSWP$.value
      : true;
  }

  getOverdueInventoryCss(row: IPurchaseOrderInventoryAllocation): string {
    if (!row || !row.estimatedDeliveryDate || row.receiveDate) {
      return '';
    }
    const todayDate = new Date(this.datePipe.transform(Date.now(), 'MM/dd/yyyy'));
    const deliveryDate = new Date(this.datePipe.transform(row.estimatedDeliveryDate, 'MM/dd/yyyy'));
    return (todayDate > deliveryDate) ? 'dynamic-list__item--danger' : '';
  }

  getIsBaseWorkOrderCss(row: IWorkOrder, portalEntityId: number): string {
    const projectIds: number[] = row && row.hasOwnProperty('warrantyProjectIds') && row['warrantyProjectIds'];
    return projectIds && projectIds.findIndex(x => x === portalEntityId) !== -1 ? 'dynamic-list__item--selected' : '';
  }

  
  getIsCustomLineCss(row: IWorkOrderItem): string {
    return row && row.hasOwnProperty('payout_payoutId') && row.payout_payoutId ? '' : 'dynamic-list__item--customline';
  }

  getIsCustomPoLineCss(row: IPurchaseOrderItem): string {
    return row && row.hasOwnProperty('sku_skuId') && row.sku_skuId ? '' : 'dynamic-list__item--customline';
  }

  canIPrintChargeBackNotice(row: IChargeBack): boolean {
    return row && row.hasOwnProperty('metaData') && row['metaData'].hasOwnProperty('chargeBackNoticeUrl');
  }

  getCommunicationEventCss(row: ICommunicationEvent): string {
    if (!row || !row.hasErrorCondition) {
      return '';
    }
    return row.hasErrorCondition ? 'dynamic-list__item--danger' : '';
  }
}
