import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DragulaModule } from 'ng2-dragula';

import { HomPipesModule } from 'hom-lib/hom-pipes';
import { HomErrorLoggerModule } from 'hom-lib/hom-error-logger';
import { HomSignatureModule } from 'hom-lib/hom-signature';
import { HomEventEmitterModule } from 'hom-lib/hom-event-emitter';
import { environment } from './../environments/environment';

//store imports
import { rootReducers, metaReducers } from './store/reducers/root.reducer';

/** Imports for the application's components, services, and modules */
// Framework Module- header - content - footer.  Layout framework is all in here.
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FwModule } from '../fw/fw.module';
import { DevModule } from './dev/dev.module';
import { WidgetLayoutModule  } from './widget-layout/widget-layout.module';
import { ComplianceModule } from './portals/compliance/compliance.module';
import { AdminModule } from './portals/admin/admin.module';
import { AdminDashboardModule } from './portals/admin-dashboard/admin-dashboard.module';
import { AcctgDashboardModule } from './portals/acctg-dashboard/acctg-dashboard.module';
import { ManagerDashboardModule } from './portals/manager-dashboard/manager-dashboard.module';
import { UserDashboardModule } from './portals/user-dashboard/user-dashboard.module';
import { WarrantyDashboardModule } from './portals/warranty-dashboard/warranty-dashboard.module';
import { UtilitiesModule } from './portals/utilities/utilities.module';
import { ProjectModule } from './portals/project/project.module';
import { InstallerModule } from './portals/installer/installer.module';
import { PortalSharedModule } from './portals/portal-shared/portal-shared.module';
import { AuthModule } from '../auth/auth.module';
import { UserJobsModule } from './portals/user/user-jobs/user-jobs.module';
import { UserSettingsModule } from './portals/user/user-settings/user-settings.module';
import { UserPendingChangesModule } from './portals/user/user-pending-changes/user-pending-changes.module';
import { UserNotificationsModule } from './portals/user/user-notifications/user-notifications.module';
import { PricingSharedModule } from './portals/pricing-shared/pricing-shared.module';
import { PoImportModule } from './portals/po-import/po-import.module';
import { FileUploadModule } from './file-upload/file-upload.module';
import { ContactModule } from './contact/contact.module';
import { SchedulerModule } from './portals/scheduler/scheduler.module';
import { SmsModule } from "./sms/sms.module";

import { appProviders } from './app.providers'; //constant
import { HomComponent } from './hom/hom.component';
import { InvalidUserComponent } from './invalid-user/invalid-user.component';
import { PageNotFoundComponent } from './not-found/not-found.component';
import { LogOutComponent } from './logout/logout.component';

@NgModule({
  declarations: [
    AppComponent,                       // containing component for entire Angular app
    PageNotFoundComponent,
    InvalidUserComponent,
    HomComponent,
    LogOutComponent
],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DragulaModule.forRoot(),
    PickerModule,
    HomErrorLoggerModule,
    HomPipesModule,
    HomEventEmitterModule,
    DevModule,
    HomSignatureModule,
    FwModule,
    WidgetLayoutModule,
    ComplianceModule,
    AdminDashboardModule,
    AcctgDashboardModule,
    ManagerDashboardModule,
    UserDashboardModule,
    UtilitiesModule,
    WarrantyDashboardModule,
    ProjectModule,
    InstallerModule,
    PortalSharedModule,
    UserSettingsModule,
    UserJobsModule,
    UserPendingChangesModule,
    UserNotificationsModule,
    PricingSharedModule, 
    PoImportModule,
    FileUploadModule,
    ContactModule,
    SchedulerModule,
    AdminModule,
    SmsModule,
    BrowserAnimationsModule,
    AuthModule.forRoot(),
    StoreModule.forRoot(rootReducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ logOnly: environment.production , connectInZone: true}),
    EffectsModule.forRoot([]),
    //StoreRouterConnectingModule.forRoot(),  //not currently leveraging
    AppRoutingModule
  ],
  providers: [appProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
