/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ChargeBackDashboardService } from '../../../portal-shared/services/charge-back-dashboard.service';


@Component({
  selector: 'unsent-installments',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unsent-installments.component.html'
})
export class UnsentInstallmentsComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public chargeBacksService: ChargeBackDashboardService) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.chargeBacksService.loadDashUnsentInstallmentsListDefinition());
    });
  }
}
