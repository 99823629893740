import { Component, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { FwSearchBarService } from './services/fw-search-bar.service';
import { FrameworkConfigService } from '../fw-config/services/framework-config.service';
import { LocalStorage } from '../fw-shared/services/local-storage';
import { TabsService } from '../fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { DomainObjectService } from '../../shared/services/domain-object.service';
import { CommunicationEventService } from '../../app/portals/portal-shared/services/communication-event.service';
import { ContactUtilityService } from '../../app/contact/services';

import { ListFilter } from '../../fw/dynamic-list/interfaces/index';
import { IResponseBase, IErrorData } from '../../shared/interfaces/index';
import { ITabRequest } from '../fw-shared/components/fw-app-tabs/interfaces/i-tab-request';
import { IAppConstants, appConstants } from '../../shared/constants/index';
import { SearchType } from '../dynamic-list/enums/search-type.enums';
import { ISiteSearchViewModel } from '../../app/portals/view-models/i-site-search-view-model';
import * as fromFeature from '../../fw/dynamic-list/store/reducers/feature.reducer';
import { getObjectDataById } from '../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { ContactStore } from '../../app/contact/enums/contact.enums';
import { IContact } from '../../app/portals/view-models';
import { getMetaDataByType, IMetaDataState } from '../dynamic-list/store';
import { IFieldDefinition } from '../dynamic-forms';

@Component({
  selector: 'fw-search-bar',
  templateUrl: './fw-search-bar.component.html',
  providers: [FwSearchBarService]
})

export class SearchBarComponent implements OnDestroy {
  public response: {} | any = {};
  public container: [] | any;
  public infoTitle: string;
  public term: string;
  public showInfo: boolean = false;
  public invalid: boolean = false;
  public loading: boolean = false;
  public errorData: IErrorData[] = null;
  public contact$: BehaviorSubject<IContact> = new BehaviorSubject(null);
  listFilter: ListFilter = new ListFilter();
  lsh: LocalStorage = new LocalStorage;
  ready: boolean = false;
  subscription: Subscription = new Subscription();
  value: string = "";
  detail: ISiteSearchViewModel = null;
  contactSub: Subscription;
  preferredContactLabel: string = null;

  constructor(
    public router: Router,
    public fcs: FrameworkConfigService,
    public dos: DomainObjectService,
    public tabsService: TabsService,
    public ces: CommunicationEventService,
    public contactUtilityService: ContactUtilityService,
    public store: Store<fromFeature.IAllDynamicData>,
    public historyService: FwSearchBarService,
    @Inject(appConstants) public myConstants: IAppConstants) {
        this.lsh.register("hsuh", []);
  }

  getWarrantyLabel(label: string): string {
    return label.split(" ").find(x => x === 'Warranty') ? 'Warranty ' : '';
  }

  getContact(): void {
    let requested: boolean = false;
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    this.contact$.next(null);
    this.contactSub = this.store.pipe(select(getObjectDataById(ContactStore.contactInformation, this.detail.contactId)))
      .subscribe((data: IContact) => {
        if (!data && !requested) {
          if(this.detail && this.detail.contactId) this.contactUtilityService.dispatchGetContact(this.detail.contactId);
        } else {
          this.contact$.next(data);
        }
      });

    this.subscription.add(this.store.pipe(
      select(getMetaDataByType(ContactStore.contactInformation)),
      filter((data: IMetaDataState) => data.fieldDefinitions.length > 0))
      .subscribe((data: IMetaDataState) => {
        if (data) {
          const lblDef: IFieldDefinition = data.fieldDefinitions.find(x => x.key == 'communicationPreferenceValue');
          this.preferredContactLabel = lblDef ? lblDef.label : 'Contact Method';
        }
      }));
  }

  public showDetail(item: any): void {
    this.detail = item;
    if (this.detail && this.detail.contactId > 0) {
      this.getContact();
    }
  }

  public clearDetail(): void {
    this.detail = null;
    this.contact$.next(null);
  }

  public openLink(detail: ISiteSearchViewModel, isChildLink: boolean, doNothing: boolean): void {
    if (doNothing) return;
    const isInstaller = detail.installerInfo !== null;
    const data: ITabRequest = {
      text: detail.entityName.concat(' ', detail[isInstaller ? 'contactName' : 'entityId'].toString()),
      portalId: detail.portalId,
      portalEntityId: detail.entityId,
      level2EntityName: !isChildLink ? null : detail.subEntityName,
      level2EntityId: !isChildLink ? null : detail.childEntityId,
      level2Text: !isChildLink ? null : detail.childEntityId.toString(),
      level3EntityName: null,
      level3EntityId: null,
      level3Text: null
    }
    const event: IHomEventEmitter = {
      requestor: 'fw-search-bar',
      event: this.myConstants.emitterEventTabLevel1Open,
      action: '',
      data: data
    };
    this.tabsService.emitTabEvent(event);
    this.ready = false;
    this.clear();
  }

  public openDetailLink(entityId: any, isWo: boolean): void {
    const detail: ISiteSearchViewModel = this.detail;
    const isInstaller = detail.installerInfo !== null;
    const data: ITabRequest = {
      text: isInstaller ? detail.installerInfo.entityName.concat(' ', entityId) : detail.entityName.concat(' ', detail.entityId.toString()),
      portalId: !isInstaller ? detail.portalId : detail.installerInfo.portalId,
      portalEntityId: isInstaller ? entityId : detail.entityId,
      level2EntityName: isInstaller ? null : detail.projectInfo[isWo ? 'workOrders' : 'purchaseOrders'].entityName,
      level2EntityId: isInstaller ? null : entityId.purchaseOrderId || entityId,
      level2Text: isInstaller ? null : isWo ? entityId : entityId.purchaseOrderNumber,
      level3EntityName: null,
      level3EntityId: null,
      level3Text: null
    }
    const event: IHomEventEmitter = {
      requestor: 'fw-search-bar',
      event: this.myConstants.emitterEventTabLevel1Open,
      action: '',
      data: data
    };
    this.tabsService.emitTabEvent(event);
    this.ready = false;
    this.clear();
  }

  public search(page: number): void {
    this.value.length < 4 ? this.invalid = true : this.getList(page);
  }

  public clear(): void {
    this.value = "";
    this.ready = false;
    this.historyService.historyItems = [];
    this.detail = null;
    this.errorData = null;
    if (this.showInfo) this.showInfo = false;
  }

  public toggleInfo(val: string): void {
    this.infoTitle = val;
    this.showInfo = !this.showInfo;
  }

  public removeFromHistory(item: never): void {
    let history: [] | any = this.lsh.asset();
    history.splice(history.indexOf(item), 1);
    this.container.splice(this.container.indexOf(item), 1);
    this.lsh.update(history);
  }

  public onKeyup(value: string): void {
    const val = value;
    if (this.invalid) {
      if (!value.length || value.length >= 4) this.invalid = false;
    }
    this.value = val;
    const history: [] | any = this.lsh.asset();
    if (!history || !history.length) return;
    let i = history.length;
    this.container = [];
    if (val) while (i--) if (history[i].substring(0, val.length) === val && this.container.length < 10) this.container.push(history[i]);
  }

  getList(page: number): void {
    const val = this.value;
    const emailRgx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let txt = val;
    if (emailRgx.test(txt.replace(' ', ''))) {
      txt = ('"' + txt.replace(' ', '') + '"');
    } else {
      txt = txt.replace(/[^a-z0-9 "]/gi, '');
    }
    this.listFilter.currentPage = page;
    this.listFilter.searchTerm = [{ term: "default", value: txt, searchType: SearchType.Default, columnName: 'default', fieldType: this.myConstants.dataTypeString }];
    this.term = txt;
    this.request();
  }

  public getPagedList(page: number) {
    const items: [] | any = this.historyService.historyItems;
    let i = items.length;
    this.listFilter.currentPage = page;
    while (i--) if (items[i].page === page) return this.response = items[i].data;
    this.request();
  }

  public getListFromHistory(item: string, page: number): void {
    this.value = item;
    this.getList(page);
  }

  request(): void {
    this.container = [];
    this.loading = true;
    this.subscription.add(this.dos.getListByMethod("Search", "Index", "", this.listFilter)
      .subscribe((response: IResponseBase) => {
        this.response = response;
        this.loading = false;
        this.ready = true;
        if (response.success) {
          if (response.metaData.pageMetaData.totalItems) {
            if (response.metaData.pageMetaData.totalItems === 1) this.showDetail(response.data[0]);
            this.historyService.historyItems.push({
              page: this.listFilter.currentPage,
              data: this.response
            });
            this.addToHistory(this.term);
          }
        } else {
          this.errorData = response.errorData;
        }
    }));
  }

  addToHistory(val: string): void {
    const history: [] | any = this.lsh.asset();
    let i: number = history.length;
    if(i) while(i--) if(history[i] === val) return;
    history.push(val);
    this.lsh.update(history);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
