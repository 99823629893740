import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IErrorData } from '../../../../../shared/interfaces';
import { IDashboardCount } from '../../../portal-shared/interfaces';
import { IProviderUserCountViewModel } from '../../../portal-shared/interfaces/i-provider-user-count-view-model';
import { ChargeBackCountType, ChargeBackCountTabLabel } from '../../../portal-shared/enums/charge-back-dashboard.enums';

import * as fromRoot from '../../../../store/reducers/index';
import { DomainObjectService } from '../../../../../shared/services';
import { AcctgDashStore } from '../../enums/acctg-dashboard.enums';
import { AcctgDashboardService } from '../../services/acctg-dashboard.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { ChargeBackDashboardService } from '../../../portal-shared/services/charge-back-dashboard.service';

@Component({
  selector: 'acctg-dashboard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './acctg-dashboard.component.html'
})
export class AcctgDashboardComponent implements OnInit, OnDestroy {
  public portalId: number = 16;  //manager_portal_id
  public tabContainerName = 'acctg-dash'; //portal_tab_containers tab_container_name
  public title: string = 'Accounting Dashboard';
  public dashCounts$: BehaviorSubject<IDashboardCount[]> = new BehaviorSubject(null);
  public dash2Counts$: BehaviorSubject<IDashboardCount[]> = new BehaviorSubject(null);
  public loading$: Observable<boolean> = of(true);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errorMessage: string = '';

  subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public domainObjectService: DomainObjectService,
    public acctgDashboardService: AcctgDashboardService,
    public userPriviledgesService: UserPriviledgesService,
    public chargeBackService: ChargeBackDashboardService) {
  }

  ngOnInit() {
    const dashStores: string[] = Object.keys(AcctgDashStore);

    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => dashStores.includes(x.requestor)),
      map(x => x.show));

    this.activeRoute.paramMap.subscribe(() => {
      this.newRequest();
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //wait for existance of tabs before start looping through count api calls for better ui experience
  public onTabsLoaded(loaded: boolean): void {
    if (loaded) {
      if (!this.acctgDashboardService.lastDashCounts || this.acctgDashboardService.lastDashCounts.length === 0) {
        this.loadCounts();
      }
    }
  }

  newRequest() {
    if (this.acctgDashboardService.lastDashCounts && this.acctgDashboardService.lastDashCounts.length > 0) {
      this.dashCounts$.next(this.acctgDashboardService.lastDashCounts);
    } else {
      this.setDashCounts();
    }
    if (this.acctgDashboardService.lastDash2Counts && this.acctgDashboardService.lastDash2Counts.length > 0) {
      this.dash2Counts$.next(this.acctgDashboardService.lastDash2Counts);
    } else {
      this.setDash2Counts();
    }
  }

  setDashCounts(): void {
    const dashboard: string = 'Chargeback Counts';

    const dashCounts: IDashboardCount[] = [
      {
        //original/default pull
        type: ChargeBackCountType.unSubmittedNotRefrain, label: ChargeBackCountTabLabel.unSubmittedNotRefrain, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(ChargeBackCountType.unSubmittedNotRefrain)
      },
      {
        type: ChargeBackCountType.unAckChargeBacks, label: ChargeBackCountTabLabel.unAckChargeBacks, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(ChargeBackCountType.unAckChargeBacks)
      },
      {
        type: ChargeBackCountType.ackUnSubmitChargeBacks, label: ChargeBackCountTabLabel.ackUnSubmitChargeBacks, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(ChargeBackCountType.ackUnSubmitChargeBacks)
      },
      {
        type: ChargeBackCountType.onHoldChargeBacks, label: ChargeBackCountTabLabel.onHoldChargeBacks, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(ChargeBackCountType.onHoldChargeBacks)
      }
    ];

    this.dashCounts$.next(dashCounts);
  }

  setDash2Counts(): void {
    const dashboard: string = 'Installment Counts';

    const dashCounts: IDashboardCount[] = [
      {
        type: ChargeBackCountType.unSentInstallments, label: ChargeBackCountTabLabel.unSentInstallments, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(ChargeBackCountType.unSentInstallments)
      }
    ];

    this.dash2Counts$.next(dashCounts);
  }

  getCountByType(countType: IDashboardCount): void {
    const params = this.userPriviledgesService.currentUserId$.value + '?type=' + countType.type;
    const method: string = countType.type === ChargeBackCountType.unSentInstallments ? 'GetInstallmentCountByType' : 'GetChargeBackCountByType';
    this.subscription.add(this.domainObjectService.getObjectByMethod('ProviderUserCountViewModel', method, params)
      .subscribe(result => {
        if (result.success) {
          const data = <IProviderUserCountViewModel>result.data;
          const dashCounts: IDashboardCount[] = countType.type === ChargeBackCountType.unSentInstallments
            ? this.dash2Counts$.getValue()
            : this.dashCounts$.getValue();
          let idx: number = dashCounts.findIndex(x => x.type === countType.type);
          if (idx > -1) {
            dashCounts[idx].value = data.dataCount;
          }
          if (countType.type === ChargeBackCountType.unSentInstallments) {
            this.dash2Counts$.next(dashCounts);
          } else {
            this.dashCounts$.next(dashCounts);
          }
          this.chargeBackService.lastDashCounts = dashCounts;
        } else {
          this.errorData$.next(result.errorData);
        }
      }, error => this.errorMessage = <any>error)
    );
  }

  loadCounts(): void {
    const dashCounts = this.dashCounts$.getValue();
    const dash2Counts = this.dash2Counts$.getValue();

    dashCounts.forEach(type => {
      this.getCountByType(type);
    })

    dash2Counts.forEach(type => {
      this.getCountByType(type);
    })
  }

  getComponentName(countType: string): string {
    switch (countType) {
      case ChargeBackCountType.unSubmittedNotRefrain:
        return 'UnsubmittedChargebacksComponent';
      case ChargeBackCountType.unAckChargeBacks:
        return 'UnacknowledgedChargebacksComponent';
      case ChargeBackCountType.ackUnSubmitChargeBacks:
        return 'AcknowledgedUnsubmittedChargebacksComponent';
      case ChargeBackCountType.onHoldChargeBacks:
        return 'OnHoldChargebacksComponent';
      case ChargeBackCountType.unSentInstallments:
        return 'UnsentInstallmentsComponent';
      default:
        return '';
    }
  }
}
