
export enum AcctgDashType {
  unsubmittedChargeback = 'unsubmittedChargeback',
  unacknowledgedChargeback = 'unacknowledgedChargeback',
  acknowledgedUnsubmittedChargeback = 'acknowledgedUnsubmittedChargeback',
  onHoldChargeback = 'onHoldChargeback',
  unsentInstallment = 'unsentInstallment'
}

export enum AcctgDashCountType {
  unsubmittedChargebackCount = 'unsubmittedChargebackCount',
  unacknowledgedChargebackCount = 'unacknowledgedChargebackCount',
  acknowledgedUnsubmittedChargebackCount = 'acknowledgedUnsubmittedChargebackCount',
  onHoldChargebackCount = 'onHoldChargebackCount',
  unsentInstallmentCount = 'unsentInstallmentCount'
}

export enum AcctgDashTabLabel {
  unsubmittedChargebacks = 'Unsubmitted Chargebacks',
  unacknowledgedChargebacks = 'Unacknowledged Chargebacks',
  acknowledgedUnsubmittedChargebacks = 'Acknowledged Unsubmitted CBs',
  onHoldChargebacks = 'On Hold Chargebacks',
  unsentInstallments = 'Unsent Installments'
}

//may be temp.  dup of charge-back-dashboard.enums
export enum AcctgDashStore {
  dashChargeBacks = 'dashChargeBacks',
  dashUnAckChargeBacks = 'dashUnAckChargeBacks',
  dashOnHoldChargeBacks = 'dashOnHoldChargeBacks',
  dashAckUnSubmitChargeBacks = 'dashAckUnSubmitChargeBacks',
  dashChargeBackInstallments = 'dashChargeBackInstallments'
}
