import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListColumn, ListColumn, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IDetailRequest } from '../../../../fw/dynamic-detail/interfaces/index';
import { ITabRequest } from '../../../../fw/fw-shared/components/fw-app-tabs/interfaces/i-tab-request';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { IHomDictionary, HomDictionary } from '../../../../shared/interfaces';
import { ProjectEvent, ProjectListStore, ProjectObjectStore } from '../enums/project.enums';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { IWarrantySubType } from '../../view-models';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';

import * as DynamicListActions from '../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as DynamicObjectActions from '../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import * as fromFeature from '../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { UserPriviledgesService } from '../../../../auth/services/index';
import { TabsService } from '../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { RequiredDocumentService } from '../../portal-shared/services';

@Injectable()
export class ProjectService {
  woDisplayFields: string[] = ['workOrderId', 'providerLocation', 'service', 'scheduleLocked', 'startDate', 'workOrderItemTotal'];
  public warrantySubTypes: { id: number, data: IWarrantySubType[] }[] = [];

  constructor(public userPriviledgesService: UserPriviledgesService,
    public tabsService: TabsService,
    public emitterService: HomEventEmitterService,
    public store: Store<fromFeature.IAllDynamicData>,
    public dynamicListService: DynamicListService,
    public rds: RequiredDocumentService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  //Project Events
  emitTaskReloadEvent(requestor: string, projectId: number): void {
    const data: IHomDictionary = new HomDictionary('projectId', projectId);
    let emitter: IHomEventEmitter = {
      requestor: requestor, event: this.myConstants.emitterEventListReload, action: '', data: data
    };
    this.emitterService.emitTaskEvent(emitter);
  }

  dispatchRequiredDocumentsGet(projectId: number, canIEdit: boolean): void {
      const docListDef: IListDefinition = this.rds.loadProjectRequiredDocumentsListDefinition(projectId, canIEdit);
      this.store.dispatch(new DynamicListActions.GetList({ listDefinition: docListDef, listFilter: docListDef.defaultListFilter, parentId: docListDef.parentId }));
  }

  dispatchUnreceivedInventoryGet(projectId: number, canEdit: boolean, setListDefinition: boolean = false): void {
    const unReceivedDef: IListDefinition = this.loadProjectUnreceivedInventoryListDefinition(projectId, canEdit);
    if (setListDefinition) {
      this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: ProjectListStore.projectUnreceivedInventory, parentId: projectId, listDefinition: unReceivedDef }));
    }
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: unReceivedDef, listFilter: unReceivedDef.defaultListFilter, parentId: unReceivedDef.parentId }));
  }

  dispatchProjectNotesGet(projectId: number, canIEdit: boolean): void {
    const docListDef: IListDefinition = this.loadProjectNoteListDefinition(projectId, canIEdit);
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: docListDef, listFilter: docListDef.defaultListFilter, parentId: docListDef.parentId }));
  }

  openProjectTab(projectId: number, requestor: string): void {
    const data: ITabRequest = {
      text: 'Project ' + projectId.toString(),
      portalId: 1,
      portalEntityId: projectId,
      level2EntityName: null,
      level2EntityId: null,
      level3EntityName: null,
      level3EntityId: 0,
      level2Text: null,
      level3Text: null
    },
      event: IHomEventEmitter = {
        requestor: requestor,
        event: this.myConstants.emitterEventTabLevel1Open,
        action: '',
        data: data
      };

    this.tabsService.emitTabEvent(event);
  }

  openProjectWorkOrderTab(projectId: number, workOrderId: number, requestor: string): void {
    const data: ITabRequest = {
      text: 'Project ' + projectId.toString(),
      portalId: 1,
      portalEntityId: projectId,
      level2EntityName: 'WorkOrder',
      level2EntityId: workOrderId,
      level3EntityName: null,
      level3EntityId: 0,
      level2Text: workOrderId.toString(),
      level3Text: null
    },
      event: IHomEventEmitter = {
        requestor: requestor,
        event: this.myConstants.emitterEventTabLevel1Open,
        action: '',
        data: data
      };

    this.tabsService.emitTabEvent(event);
  }

  openProjectPurchaseOrderTab(projectId: number, purchaseOrderId: number, purchaseOrderNumber: string, requestor: string): void {
    const data: ITabRequest = {
      text: 'Project ' + projectId.toString(),
      portalId: 1,
      portalEntityId: projectId,
      level2EntityName: 'PurchaseOrder',
      level2EntityId: purchaseOrderId,
      level3EntityName: null,
      level3EntityId: 0,
      level2Text: purchaseOrderNumber.toString(),
      level3Text: null
    },
      event: IHomEventEmitter = {
        requestor: requestor,
        event: this.myConstants.emitterEventTabLevel1Open,
        action: '',
        data: data
      };

    this.tabsService.emitTabEvent(event);
  }

  dispatchProjectObject(projectId: number, displayFields: string[] = []): void {
    let objectRequest: IDetailRequest = this.loadProjectObjectRequest(projectId, displayFields);
    this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));
  }

  dispatchPurchaseOrders(requestor: string, projectId: number, canEdit: boolean): void {
    const poListDef: IListDefinition = this.loadProjectPurchaseOrderListDefinition(projectId, canEdit),
      poItemListDef: IListDefinition = this.loadProjectPurchaseOrderItemListDefinition(projectId, canEdit);
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: poListDef, listFilter: poListDef.defaultListFilter, parentId: poListDef.parentId }));
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: poItemListDef, listFilter: poItemListDef.defaultListFilter, parentId: poItemListDef.parentId }));
    this.dispatchUnreceivedInventoryGet(projectId, canEdit);
    this.dispatchRequiredDocumentsGet(projectId, canEdit);
    this.emitTaskReloadEvent(requestor, projectId);
  }

  dispatchWorkOrders(requestor: string, projectId: number, canEdit: boolean, reloadTasks: boolean = true): void {
    const woListDef: IListDefinition = this.loadProjectWorkOrderListDefinition(projectId, canEdit),
      woItemListDef: IListDefinition = this.loadProjectWorkOrderItemListDefinition(projectId, canEdit);
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: woListDef, listFilter: woListDef.defaultListFilter, parentId: woListDef.parentId }));
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: woItemListDef, listFilter: woItemListDef.defaultListFilter, parentId: woItemListDef.parentId }));
    if (reloadTasks) {
      this.emitTaskReloadEvent(requestor, projectId);
    }
  }

  /*Load Object Methods*/
  loadProjectObjectRequest(projectId: number, displayFields: string[] = []): IDetailRequest {
    return {
      controllerName: 'Project',
      storeName: ProjectObjectStore.projectInformation,
      rowKeyId: 'projectId',
      objectId: projectId,
      displayFields: displayFields,
      metaUrl: '',
      controllerMethod: 'DetailsWithMetaData', //defaults to Index if not specified
      methodParameters: projectId.toString()
    };
  }

  loadWorkOrderObjectRequest(workOrderId: number, displayFields: string[] = []): IDetailRequest {
    return {
      controllerName: 'WorkOrder',
      storeName: ProjectObjectStore.workOrderInformation,
      rowKeyId: 'workOrderId',
      objectId: workOrderId,
      displayFields: displayFields,
      metaUrl: '',
      controllerMethod: 'DetailsWithMetaData', //defaults to Index if not specified
      methodParameters: workOrderId.toString()
    };
  }

  //Work Orders by Project
  loadProjectWorkOrderListDefinition(projectId: number, canIEdit: boolean, isSummary: boolean = false, isModal: boolean = false) {
    const listColumns = isSummary ? this.loadWorkOrderSummaryListColumns() : this.loadWorkOrderListColumns();
    const listObjectLabel = 'Work Order';
    const listObjectController = 'WorkOrder';
    const listStoreName = ProjectListStore.projectWorkOrders;
    const detailRoutePath = isModal ? 'project-work-order-create' : 'work-order-create';
    const listRowKeyId = 'workOrderId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('workOrderStatusText')];

    const routerOutlet = isModal ? 'modal' : 'workOrderCreateOutlet';
    let listDefinition = this.dynamicListService.createListDefinition(routerOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';
    //do not turn this to true - this list also loads the project high-level summary counts/summaries
    listDefinition.showFilter = false;  
    listDefinition.allowSaveFilter = false;
    //listFilter is ignored in controller method
    listDefinition.revokeSort = true;

    if (!isSummary) {
      if (canIEdit) {
        const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
        listDefinition.toolButtons.splice(0, 0, newTools[0]);
      }

      const delDefaults: ICustomButton = {
        title: '',
        icon: '',
        cssName: '',
        enabled: canIEdit,
        eventName: ProjectEvent.deleteWorkOrder,
        inOverflow: true
     }
      let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: delDefaults}];
      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

      listDefinition.rowButtons.splice(0, 0,
        //{ //commented out per the business for now 1/24/2020
        //  title: 'Add Work Order Note',
        //  icon: 'fas fa-comment-plus',
        //  cssName: 'app-btn-icon--primary',
        //  enabled: true,
        //  eventName: ProjectEvent.addNote
        //},
        {
          title: 'Log Customer Action',
          icon: 'fas fa-file-signature',
          cssName: 'app-btn-icon--custom-4', // in _button.scss
          enabled: true,
          enabledMethod: 'canLogCustomerAction',
          eventName: ProjectEvent.logCustomerAction,
          methodService: 'projectDynamicListService'
        },
        {
          title: 'View Work Order Packet',
          icon: 'fas fa-print',
          cssName: 'app-btn-icon--custom-1', // in _button.scss
          cssMethod: '',
          enabled: true,
          enabledMethod: 'canViewWoPacket',
          eventName: ProjectEvent.showWOPacket,
          methodService: 'projectDynamicListService'
        },
        {
          title: 'View/Create Warranty',
          icon: 'fas fa-ambulance',
          cssName: 'app-btn-icon--neutral',
          cssMethod: 'getWarrantyButtonCss',
          enabled: true,
          enabledMethod: 'canViewWarrantyScript',
          eventName: ProjectEvent.openWarrantyScript,
          methodService: 'projectDynamicListService',
          inOverflow: true
        },
        {
          title: 'Peek at Work Order Items',
          icon: 'fad fa-clipboard-list-check',
          cssName: 'app-btn-icon--custom-2',
          enabled: true,
          eventName: ProjectEvent.peekWOItemSummary,
          inOverflow: true
        }
      );
    };

    listDefinition.groupSelect = isSummary ? null : {
      title: 'Close Work Order',
      enabled: true,
      allowSelectAll: false,
      enabledMethod: 'canCloseWo',
      eventName: ProjectEvent.closeWorkOrder,
      methodService: 'projectDynamicListService'
    };

    listDefinition.rowStatus = !isSummary ? null : {
      cssMethod: 'getIsBaseWorkOrderCss',
      methodService: 'projectDynamicListService'
    };

    return listDefinition; //set at end to signify ready to instantiate the list.
  }

  loadWorkOrderListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderServiceName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderStatusText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderScheduleText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderInstallerName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderItemTotal');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('callLog');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWorkOrderSummaryListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderStatus');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderScheduleDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderInstallerName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('callLog');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWorkOrderItemListDefinition(workOrderId: number, canIEdit: boolean, isSummary: boolean = false, isSchedule: boolean = false) {
    const listColumns = this.loadWorkOrderItemListColumns(isSummary, isSchedule);
    const listObjectLabel = 'Work Order Item';
    const listObjectController = 'WorkOrderItem';
    const listStoreName = ProjectListStore.workOrderItems;
    const detailRoutePath = !isSchedule ? 'work-order-item-detail' : '';
    const listRowKeyId = 'workOrderItemId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true,
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition(!isSchedule ? 'workOrderItemDetailOutlet' : '',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath,
      !isSchedule );

    listDefinition.controllerMethod = 'ByWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();
    listDefinition.parentId = workOrderId;
    listDefinition.parentKey = 'workOrder_workOrderId';
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;
    listDefinition.revokeSort = true;

    listDefinition.rowStatus = {
      cssMethod: 'getIsCustomLineCss',
      methodService: 'projectDynamicListService'
    };

    const btnDefaults: ICustomButton = {
      title: '',
      icon: '',
      cssName: '',
      enabled: true,
      eventName: '',
      inOverflow: true
    }

    let crudButtons: IListButtonType[] = isSummary || isSchedule ? [] : [{ type: ButtonType.detail, defaults: btnDefaults } ];
    if (!isSummary && !isSchedule && canIEdit) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      if (listDefinition.toolButtons.length) {
        listDefinition.toolButtons.splice(0, 0, newTools[0]);
      } else {
        listDefinition.toolButtons = newTools;
      }

      crudButtons.push(
        { type: ButtonType.edit, defaults: btnDefaults },
        { type: ButtonType.delete, defaults: btnDefaults }
      );
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  //WO Items by Work order
  loadWorkOrderItemListColumns(isSumary: boolean, isSchedule: boolean = false): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('itemName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('quantity');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    if (!isSchedule) {
      if (!isSumary) {
        colDef = new ListColumn('unitMeasure');
        colDef.visibleOnSmall = false;
        colDef.visibleOnMedium = false;
        columnDefinitions.push(colDef);
      }

      colDef = new ListColumn('unitPrice');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      if (isSumary) {
        colDef = new ListColumn('calcWOItemTotal');
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);

        colDef = new ListColumn('purchaseOrderNumber');
        colDef.visibleOnMedium = false;
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);
      } else {
        colDef = new ListColumn('purchaseOrderNumber');
        colDef.visibleOnSmall = false;
        colDef.visibleOnMedium = false;
        columnDefinitions.push(colDef);

        colDef = new ListColumn('calcWOItemTotal');
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);
      }
    }

    return columnDefinitions;
  }

  //WO Items by Project - used by high-level-sumary
  loadProjectWorkOrderItemListDefinition(projectId: number, canIEdit: boolean) {
    const listColumns = [];//undefined
    const listObjectLabel = 'Work Order Item';
    const listObjectController = 'WorkOrderItem';
    const listStoreName = ProjectListStore.projectWorkOrderItems;
    const detailRoutePath = '';//leave blank
    const listRowKeyId = 'workOrderItemId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true,
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';
    listDefinition.revokeSort = true;

    //no crud buttons
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    return listDefinition;
  }

  //Purchase Orders by Project
  loadProjectPurchaseOrderListDefinition(projectId: number, canIEdit: boolean, isSummary: boolean = false, isModal: boolean = false) {
    let canIViewPOBalance = this.userPriviledgesService.canViewPoBalance$.getValue();
    const listColumns = isSummary ? this.loadPurchaseOrderSummaryListColumns() : this.loadPurchaseOrderListColumns(canIViewPOBalance);
    const listObjectLabel = 'Purchase Orders';
    const listObjectController = 'PurchaseOrder';
    const listStoreName = ProjectListStore.projectPurchaseOrders;
    const detailRoutePath = isModal ? 'project-purchase-order-create' : 'purchase-order-create';
    const listRowKeyId = 'purchaseOrderId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    const routerOutlet = isModal ? 'modal' : 'purchaseOrderCreateOutlet';
    let listDefinition = this.dynamicListService.createListDefinition(routerOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath );

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';
    //do not turn this to true - this list also loads the project high-level summary counts/summaries
    listDefinition.showFilter = false;  
    listDefinition.allowSaveFilter = false;
    listDefinition.revokeSort = true;

    if (!isSummary) {
      if (canIEdit) {
        const newTools: ICustomButton[] = this.dynamicListService
          .loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
        listDefinition.toolButtons.splice(0, 0, newTools[0]);
      }

      const delDefaults: ICustomButton = {
        title: '',
        icon: '',
        cssName: '',
        enabled: canIEdit,
        eventName: ProjectEvent.deletePurchaseOrder,
        inOverflow: true
      };
      let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: delDefaults }];
      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'Add Purchase Order Note',
          icon: 'fas fa-comment-plus',
          cssName: 'app-btn-icon--primary',
          enabled: true,
          eventName: ProjectEvent.addNote,
          inOverflow: true
        },
        {
          title: 'Peek at Purchase Order Items',
          icon: 'fad fa-ballot',
          cssName: 'app-btn-icon--custom-3',
          enabled: true,
          eventName: ProjectEvent.peekPOItemSummary,
          inOverflow: true
        },
        {
          title: 'View External Data',
          icon: 'far fa-house-signal',
          cssName: 'app-btn-icon--neutral',
          enabled: true,
          enabledMethod: 'canViewExternalData',
          eventName: ProjectEvent.viewExternalData,
          methodService: 'projectDynamicListService',
          inOverflow: true
        },
      );
    }

    listDefinition.groupSelect = isSummary ? null : {
      title: 'Close Purchase Order',
      enabled: true,
      allowSelectAll: false,
      enabledMethod: 'canClosePo',
      eventName: ProjectEvent.closePurchaseOrder,
      methodService: 'projectDynamicListService'
    };

    listDefinition.rowStatus = isSummary ? null : {
      cssMethod: 'getAccntSyncErrorCss',
      methodService: 'projectDynamicListService'
    };

    return listDefinition; //set at end to signify ready to instantiate the list.
  }

  loadPurchaseOrderListColumns(canIViewPOBalance: boolean): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('orderDate');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('purchaseOrderNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchProgram');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('orderStatus');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sum');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    if (canIViewPOBalance) {
      colDef = new ListColumn('calcPOBalance');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);
    }

    return columnDefinitions;
  }

  loadPurchaseOrderSummaryListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('purchaseOrderNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchProgram');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('orderStatus');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  //PO Items by PO
  loadPurchaseOrderItemListDefinition(purchaseOrderId: number, canIEdit: boolean, isSummary: boolean = false) {
    const listColumns = this.loadPurchaseOrderItemListColumns(isSummary);
    const listObjectLabel = 'Purchase Order Item';
    const listObjectController = 'PurchaseOrderItem';
    const listStoreName = ProjectListStore.purchaseOrderItems;
    const detailRoutePath = 'purchase-order-item-detail';
    const listRowKeyId = 'purchaseOrderItemId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true,
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition('purchaseOrderItemDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByPurchaseOrder';
    listDefinition.methodParameters = purchaseOrderId.toString();
    listDefinition.parentId = purchaseOrderId;
    listDefinition.parentKey = 'purchaseOrder_purchaseOrderId';

    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;
    listDefinition.revokeSort = true;

    const btnDefaults: ICustomButton = {
      title: '',
      icon: '',
      cssName: '',
      enabled: true,
      eventName: '',
      inOverflow: true
    }
    let crudButtons: IListButtonType[] = isSummary ? [] : [{ type: ButtonType.detail, defaults: btnDefaults }];
    if (!isSummary && canIEdit) {
      const newTools: ICustomButton[] =
        this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);

      crudButtons.push(
        { type: ButtonType.edit, defaults: btnDefaults },
        { type: ButtonType.delete, defaults: btnDefaults }
      );

    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowStatus = {
      cssMethod: 'getIsCustomPoLineCss',
      methodService: 'projectDynamicListService'
    };

    return listDefinition;
  }

  loadPurchaseOrderItemListColumns(isSummary: boolean): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('itemName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('itemType');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('quantity');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitPrice');
    columnDefinitions.push(colDef);

    if (!isSummary) {
      colDef = new ListColumn('unitMeasure');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('calcPOItemTotal');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderId');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  //PO Items by Project
  loadProjectPurchaseOrderItemListDefinition(projectId: number, canIEdit: boolean) {
    const listColumns = []; //TBD
    const listObjectLabel = 'Purchase Order Item';
    const listObjectController = 'PurchaseOrderItem';
    const listStoreName = ProjectListStore.projectPurchaseOrderItems;
    const detailRoutePath = ''; //tbd - 
    const listRowKeyId = 'purchaseOrderItemId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true,
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';

    //no crud buttons
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;
    listDefinition.revokeSort = true;

    return listDefinition;
  }

  //Charge Backs by Project
  loadProjectChargeBackListDefinition(projectId: number, canIEdit: boolean, isSummary: boolean = false, isModal: boolean = false) {
    const listColumns = this.loadChargeBackListColumns(isSummary);
    const listObjectLabel = 'Charge Back';
    const listObjectController = 'ChargeBack';
    const listStoreName = ProjectListStore.projectChargeBacks;
    const detailRoutePath = isModal ? 'project-charge-back-create' : 'charge-back-create';
    const listRowKeyId = 'chargeBackId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    const routerOutlet = isModal ? 'modal' : 'chargeBackCreateOutlet';
    let listDefinition = this.dynamicListService.createListDefinition(routerOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;

    if (!isSummary) {
      if (canIEdit) {
        const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
        listDefinition.toolButtons.splice(0, 0, newTools[0]);
      }
      
      const delDefaults: ICustomButton = {
        title: '',
        icon: '',
        cssName: '',
        enabled: canIEdit,
        enabledMethod: 'canDeleteChargeBack',
        eventName: ProjectEvent.deleteChargeBack,
        methodService: 'projectDynamicListService'
      };
      let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: delDefaults }];
      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'Print Installer Chargeback Notice',
          icon: 'fas fa-print',
          cssName: 'app-btn-icon--print',
          enabled: true,
          eventName: ProjectEvent.printChargeBackNotice,
          enabledMethod: 'canIPrintChargeBackNotice',
          methodService: 'projectDynamicListService'
        }
      );
    }

    return listDefinition; //set at end to signify ready to instantiate the list.
  }

  loadChargeBackListColumns(isSummary: boolean): IListColumn[] {

    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('chargeBackId');
    columnDefinitions.push(colDef);

    if (!isSummary) {
      colDef = new ListColumn('createDate');
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);
    }
    colDef = new ListColumn('chargeBackStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('installer');
    columnDefinitions.push(colDef);

    if (!isSummary) {
      colDef = new ListColumn('chargeBackNotes');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      colDef.visibleOnLarge = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('acknowledged');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('submitted');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('amountTotal');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);
    }

    return columnDefinitions;
  }

  loadProjectChargeBackItemListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('name');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('chargeBackType');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('units');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitPrice');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('calcCBItemTotal');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  //Charge Back items By Project
  loadProjectChargeBackItemListDefinition(projectId: number, canIEdit: boolean) {
    const listColumns = this.loadProjectChargeBackItemListColumns();
    const listObjectLabel = 'Charge Back Item';
    const listObjectController = 'ChargeBackItem';
    const listStoreName = ProjectListStore.projectChargeBackItems;
    const detailRoutePath = '';
    const listRowKeyId = 'chargeBackItemId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true,
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';

    //no crud buttons
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;

    return listDefinition;
  }

  //Charge Back Items by Charge Back
  loadChargeBackItemListDefinition(chargeBackId: number, canIEdit: boolean) {
    const listColumns = this.loadChargeBackItemListColumns();
    const listObjectLabel = 'Charge Back Item';
    const listObjectController = 'ChargeBackItem';
    const listStoreName = ProjectListStore.chargeBackItems;
    const detailRoutePath = 'charge-back-item-detail';
    const listRowKeyId = 'chargeBackItemId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true,
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition('chargeBackItemDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByChargeBack';
    listDefinition.methodParameters = chargeBackId.toString();
    listDefinition.parentId = chargeBackId;
    listDefinition.parentKey = 'chargeBack_chargeBackId';

    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    if (canIEdit) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);

      crudButtons.push(
        { type: ButtonType.edit, defaults: null },
        { type: ButtonType.delete, defaults: null }
      );
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadChargeBackItemListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('name');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('chargeBackType');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('units');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitPrice');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('calcCBItemTotal');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  //Notes by Project
  loadProjectNoteListDefinition(projectId: number, canIEdit: boolean = true) {
    const listObjectLabel = 'Project Note';
    const listObjectController = 'Note';
    const listStoreName = ProjectListStore.projectNotes;
    const detailRoutePath = '';
    const listRowKeyId = 'noteId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('createDate', false)]
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      null,
      detailRoutePath);

    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';
    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();

    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    if (canIEdit) {
      crudButtons.push(
        { type: ButtonType.edit, defaults: null },
        { type: ButtonType.delete, defaults: null }
      );
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  //Notes by Work Order
  loadWorkOrderNoteListDefinition(workOrderId: number) {
    const listObjectLabel = 'Work Order Note ';
    const listObjectController = 'Note';
    const listStoreName = 'workOrderNotes';
    const detailRoutePath = '';
    const listRowKeyId = 'noteId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('createDate', false)]
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      null,
      detailRoutePath);

    listDefinition.parentId = workOrderId;
    listDefinition.parentKey = 'workOrder_workOrderId';
    listDefinition.controllerMethod = 'ByWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();
    listDefinition.allowSaveFilter = false;

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  //Notes by Purchase Order
  loadPurchaseOrderNoteListDefinition(purchaseOrderId: number) {
    const listObjectLabel = 'Purchase Order Note ';
    const listObjectController = 'Note';
    const listStoreName = 'purchaseOrderNotes';
    const detailRoutePath = '';
    const listRowKeyId = 'noteId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('createDate', false)]
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      null,
      detailRoutePath);

    listDefinition.allowSaveFilter = false;
    listDefinition.parentId = purchaseOrderId;
    listDefinition.parentKey = 'purchaseOrder_purchaseOrderId';
    listDefinition.controllerMethod = 'ByPurchaseOrder';
    listDefinition.methodParameters = purchaseOrderId.toString();

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition; //set at end to signify ready to instantiate the list.
  }

  //Warranties by Work Order 
  loadWorkOrderWarrantyListDefinition(workOrderId: number) {
    const listColumns = this.loadWorkOrderWarrantyListColumns();
    const listObjectLabel = 'Work Order Warranty Project ';
    const listObjectController = 'Project';
    const listStoreName = ProjectListStore.workOrderWarranties;
    const detailRoutePath = 'project-summary';
    const listRowKeyId = 'projectId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('createDate', false)]
    };

    let listDefinition = this.dynamicListService.createListDefinition('woWarrantyOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = workOrderId;
    listDefinition.parentKey = 'workOrder_workOrderId';
    listDefinition.controllerMethod = 'ByWarrantyWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();
    listDefinition.allowSaveFilter = false;

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition; 
  }

  loadWorkOrderWarrantyListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('startDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('endDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantyTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantyIsUrgent');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWorkOrderCallLogResponseListDefinition(workOrderId: number) {
    const listColumns = this.loadWorkOrderCallResponseLogListColumns();
    const listObjectLabel = 'Work Order Call Log ';
    const listObjectController = 'ProjectCallLogResponse';
    const listStoreName = ProjectListStore.workOrderCallLogResponses;
    const detailRoutePath = '';
    const listRowKeyId = 'projectCallLogResponseId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('createDate', false)],
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = workOrderId;
    listDefinition.parentKey = 'workOrder_workOrderId';
    listDefinition.controllerMethod = 'ByWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();

    //no crud buttons
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;

    return listDefinition; //set at end to signify ready to instantiate the list.
  }

  loadWorkOrderCallResponseLogListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('callLogType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('callLogResponseName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('noteRequired');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    return columnDefinitions;
  }


  loadProjectUnreceivedInventoryListDefinition(projectId: number, canEdit: boolean) {
    const listColumns = this.loadProjectUnreceivedInventoryListColumns();
    const listObjectLabel = 'Unreceived Inventory';
    const listObjectController = 'PurchaseOrderInventoryAllocation';
    const listStoreName = ProjectListStore.projectUnreceivedInventory;
    const detailRoutePath = 'project-unreceived-inv';
    const listRowKeyId = 'purchaseOrderInventoryAllocationId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false, //want paged data
      currentPage: 1,
      searchTerm: [{ term: 'isAllocated', value: false, searchType: SearchType.Equals, columnName: 'isAllocated', fieldType: this.myConstants.dataTypeBool }],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'projectId';
    listDefinition.revokeSort = true;

    //no crud buttons
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    listDefinition.groupSelect = {
      title: 'Receive Inventory',
      enabled: canEdit, //cannot rely on meta data in row for unreceived, so setting it at this level
      allowSelectAll: false,
      eventName: ProjectEvent.receiveInventory
    };

    listDefinition.rowStatus = {
      cssMethod: 'getOverdueInventoryCss',
      methodService: 'projectDynamicListService'
    };
    return listDefinition;
  }

  loadProjectUnreceivedInventoryListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('purchaseOrderNumber');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('materialName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('vendorPoNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('manufacturerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('units');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('measureDescription');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('estimatedDeliveryDate');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProjectHistoryListDefinition(projectId: number) {
    const listColumns = this.loadProjectHistoryListColumns();
    const listObjectLabel = 'History';
    const listObjectController = 'HistoryLog';
    const listStoreName = ProjectListStore.projectHistory;
    const detailRoutePath = '';
    const listRowKeyId = 'historyLogId';
    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false,
      currentPage: 1,
      searchTerm: [
      ],
      orderTerm: [new OrderTerm('eventDate', false)]
    };

    const routerOutlet = '';
    let listDefinition = this.dynamicListService.createListDefinition(routerOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByAggRoot';
    listDefinition.methodParameters = projectId.toString() + '?entityName=Project';
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'aggEntityPkid';

    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = true;

    //no crud buttons
    return listDefinition; 
  }

  loadProjectHistoryListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('eventDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('eventTime');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('providerUser');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('eventAction');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('entity');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('entitySpecificName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('logEntityLabel');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  //State of the PO from the GC perspective.
  //Includes actions, dates and key fields that are pertinent to the GC
  loadPoExternalDataListDefinition(purchaseOrderId: number): IListDefinition {
    const listColumns = this.loadPoExternalDataListColumns();
    const listObjectLabel = 'Purchase Order External Data';
    const listObjectController = 'PurchaseOrderFlexFieldViewModel';
    const listStoreName = ProjectListStore.purchaseOrderExternalData;
    const detailRoutePath = ''; //no detail
    const listRowKeyId = 'PurchaseOrderFlexFieldId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true, //want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('CreateDate')]
    };

    let listDefinition = this.dynamicListService.createListDefinition('modal',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByPurchaseOrder';
    listDefinition.methodParameters = purchaseOrderId.toString();
    listDefinition.parentId = purchaseOrderId;
    listDefinition.parentKey = 'purchaseOrderId';

    //no crud buttons
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;

    return listDefinition;
  }

  loadPoExternalDataListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('fieldName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('fieldValue');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProjectCommunicationEventsListDefinition(projectId: number) {
    const listColumns = this.loadProjectCommunicationEventListColumns();
    const listObjectLabel = 'Communication Events';
    const listObjectController = 'CommunicationEvent';
    const listStoreName = ProjectListStore.projectCommunicationEvents;
    const detailRoutePath = 'project-communication-event';
    const listRowKeyId = 'communicationEventId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('createDate', false)];

    const routerOutlet = 'communicationEventOutlet';
    let listDefinition = this.dynamicListService.createListDefinition(routerOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'project_projectId';
    listDefinition.showFilter = true;
    listDefinition.allowSaveFilter = false;

    listDefinition.rowStatus = {
      cssMethod: 'getCommunicationEventCss',
      methodService: 'projectDynamicListService'
    };

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition; 
  }

  loadProjectCommunicationEventListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workOrder');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationEventType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('hasErrorCondition');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('feedback');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    return columnDefinitions;
  }

  loadProjectCommunicationEventActionsListDefinition(communicationEventId: number) {
    const listColumns = this.loadProjectCommunicationEventActionListColumns();
    const listObjectLabel = 'Communication Event Actions';
    const listObjectController = 'CommunicationEventAction';
    const listStoreName = ProjectListStore.communicationEventActions;
    const detailRoutePath = '';
    const listRowKeyId = 'communicationEventActionId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    //defaultListFilter.orderTerm = 'createDate';

    const routerOutlet = '';
    let listDefinition = this.dynamicListService.createListDefinition(routerOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByCommunicationEvent';
    listDefinition.methodParameters = communicationEventId.toString();
    listDefinition.parentId = communicationEventId;
    listDefinition.parentKey = 'communicationEventId';
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    //no crud buttons
    return listDefinition;  
  }

  loadProjectCommunicationEventActionListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('actionName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('actionValue');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('errorCondition');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadAQTChangesListDefinition(projectId: number, workOrderId: number = 0): IListDefinition {
    const listColumns = this.loadAQTChangesListColumns(workOrderId);
    const listObjectLabel = 'Finalized Approval';
    const listObjectController = 'ApprovalQueueTransaction';
    const listStoreName = workOrderId > 0 ? ProjectListStore.workOrderAQTChanges : ProjectListStore.projectAQTChanges;
    const detailRoutePath = workOrderId > 0 ? 'wo-aqt-chgs' : 'project-aqt-chgs'; //not currently using the project aqt detail, but may
    const listRowKeyId = 'approvalQueueTransactionId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [
      { term: 'status', value: 'Denied', searchType: SearchType.Equals, columnName: 'status', fieldType: this.myConstants.dataTypeString },
      { term: 'status', value: 'Approved', searchType: SearchType.Equals, columnName: 'status', fieldType: this.myConstants.dataTypeString },
    ];

    if (workOrderId > 0) {
      defaultListFilter.searchTerm.push({
        term: 'parentPrimaryKeyId',
        value: workOrderId.toString(),
        searchType: SearchType.Equals,
        columnName: 'parentPrimaryKeyId',
        fieldType: this.myConstants.dataTypeInt
      });
    } else {
      defaultListFilter.searchTerm.push({
        term: 'aggregateRootPrimaryKeyId',
        value: projectId.toString(),
        searchType: SearchType.Equals,
        columnName: 'aggregateRootPrimaryKeyId',
        fieldType: this.myConstants.dataTypeInt
      });
    }

    defaultListFilter.orderTerm = workOrderId > 0
      ? [new OrderTerm('status')]
      : [new OrderTerm('aggregateRootPrimaryKeyId')];

    let listDefinition = this.dynamicListService.createListDefinition('aqtChgsOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = workOrderId > 0 ? workOrderId : projectId;
    listDefinition.parentKey = workOrderId > 0 ? 'parentPrimaryKeyId' : 'aggregateRootPrimaryKeyId';
    listDefinition.controllerMethod = 'Index';
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;
    listDefinition.showFilter = false;

    //readonly
    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    return listDefinition;
  }

  loadAQTChangesListColumns(workOrderId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('entityName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('transactionContext');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    if (workOrderId === 0) {
      colDef = new ListColumn('parentPrimaryKeyId');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('status');
    colDef.visibleOnSmall = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('approvalUserText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    
    colDef = new ListColumn('denyReason');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);


    return columnDefinitions;
  }


}
