import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';

import {
  AcctgDashboardComponent,
  UnsubmittedChargebacksComponent,
  UnacknowledgedChargebacksComponent,
  AcknowledgedUnsubmittedChargebacksComponent,
  OnHoldChargebacksComponent,
  UnsentInstallmentsComponent
} from './containers/index';


import {  EmptyComponent } from '../portal-shared/components/index';
import { LoadingPageComponent } from '../../../shared/components';


const portalRoutes: Routes = [
  {
    path: 'acctg-dash', //componentless route
    children: [
      {
        path: ':portalId', //not really needed at this time, but keeping so tab factory can always call the same route structure
        component: AcctgDashboardComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'acctg-dash-empty', //verify path, result of tab component concatenation
            outlet: 'portal-detail',
            component: EmptyComponent
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'unsubmitted-chargebacks',
            outlet: 'portal-detail',
            component: UnsubmittedChargebacksComponent
          },
          {
            path: 'unack-chargebacks',
            outlet: 'portal-detail',
            component: UnacknowledgedChargebacksComponent
          },
          {
            path: 'ack-unsub-chargebacks',
            outlet: 'portal-detail',
            component: AcknowledgedUnsubmittedChargebacksComponent
          },
          {
            path: 'onhold-chargebacks',
            outlet: 'portal-detail',
            component: OnHoldChargebacksComponent
          },
          {
            path: 'unsent-installments',
            outlet: 'portal-detail',
            component: UnsentInstallmentsComponent
          }
       ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class AcctgDashboardRoutingModule { }
