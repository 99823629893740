import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import {
  EmptyComponent,
  AQTChangeComponent,
  ScheduleLockedDaysCreateComponent
} from '../portal-shared/components/index';
import {
  SelectionListResolver,
  AllMyProviderLocationsResolver
} from '../resolvers/index';

import {
  ContactMetaDataResolver, ContactTypeSpecificationResolver,
  ContactPhoneMetaDataResolver, ContactEmailMetaDataResolver,
  ContactAddressMetaDataResolver
} from '../../contact/resolvers/index';

import {
  ScheduleLockedDaysComponent,
  ChargeBackInstallmentManagerComponent
} from '../portal-shared/containers/index';

import {
  ManagerDashboardComponent,
  InventoryOrdersComponent,
  PendingApprovalsComponent,
  PendingChangeDenyComponent,
  InventoryNeedsComponent,
  ManagerToolsComponent,
  PurchaseOrderCreationWizardComponent,
  CommunicationEventLogsComponent,
  OutstandingEntityDocumentsComponent,
  AccountingChargeBacksComponent
} from './containers/index';

import {
  InventoryOrderItemsComponent,
  WorkOrdersRequiringInventoryComponent,  InventoryOrderRequestComponent,
  CommunicationEventLogComponent
} from './components/index';
import { LoadingPageComponent } from '../../../shared/components';

const portalRoutes: Routes = [
  {
    path: 'mgr-dash', //componentless route 
    children: [
      {
        path: ':portalId',  
        component: ManagerDashboardComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'manager-dash-empty',  
            outlet: 'portal-detail',
            component: EmptyComponent  
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'pending-approvals',
            outlet: 'portal-detail',
            component: PendingApprovalsComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'dash-pending-approval/:id',
                outlet: 'pendingApprovalOutlet',
                component: AQTChangeComponent
              }
            ]
          },
          {
            path: 'inventory-orders',
            outlet: 'portal-detail',
            component: InventoryOrdersComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'dash-shipper-po-items/:id',
                outlet: 'shipperPOItemsOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
               component: InventoryOrderItemsComponent
              }
            ]
          },
          {
            path: 'inventory-needs',
            outlet: 'portal-detail',
            component: InventoryNeedsComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'dash-inventory-work-orders/:id',
                outlet: 'inventoryWosOutlet',
                component: WorkOrdersRequiringInventoryComponent
              }
            ]
          },
          {
            path: 'accounting-charge-backs',
            outlet: 'portal-detail',
            component: AccountingChargeBacksComponent,
            data: {
              includeReadOnly: false
            },
            resolve: {
              providerLocations: AllMyProviderLocationsResolver
            }
          },
          {
            path: 'lock-day-manager',
            outlet: 'portal-detail',
            component: ScheduleLockedDaysComponent,
            children: [
              {
                path: 'schedule-locked-days-create/:id',
                outlet: 'scheduleLockedDaysCreateOutlet',
                component: ScheduleLockedDaysCreateComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'manager-dash-tools',
            outlet: 'portal-detail',
            component: ManagerToolsComponent
          },
          { 
            path: 'manager-dash-communication-event-logs',
            outlet: 'portal-detail',
            component: CommunicationEventLogsComponent,
            children: [
              {
                path: 'dash-communication-event-log/:id',
                outlet: 'communicationEventLogOutlet',
                component: CommunicationEventLogComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'manager-dash-outstanding-docs',
            outlet: 'portal-detail',
            component: OutstandingEntityDocumentsComponent
          }
        ]
      }
    ]
  },
  //BEGIN MODAL route tos
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'mgr-dash-charge-back-installments/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    component: ChargeBackInstallmentManagerComponent
  },
  {
    path: 'mgr-dash-inventory-order-request',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: InventoryOrderRequestComponent
  },
  {
    path: 'mgr-dash-inventory-request-result/:id',
    outlet: 'modal',
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    component: InventoryOrderItemsComponent
  },
  {
    path: 'mgr-dash-deny-pending-change/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: PendingChangeDenyComponent
  },
  {
    path: 'create-purchase-order',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: PurchaseOrderCreationWizardComponent,
    data: {
      contactTypeId: '1'
    },
    title: 'create-purchase-order',
    resolve: {
      hasContactMetaData: ContactMetaDataResolver,
      hasTypeData: ContactTypeSpecificationResolver,
      hadPhoneMetaData: ContactPhoneMetaDataResolver,
      hasEmailMetaData: ContactEmailMetaDataResolver,
      hasAddrMetaData: ContactAddressMetaDataResolver
    }
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class ManagerDashboardRoutingModule { }
