/*
  This is a common reducer that is utilized by all the widgets rendered via dynamic-list
*/
import { cloneDeep, chain, zipObject } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IListFilter, ListDefinition, IListDefinition } from '../../../dynamic-list/interfaces/index';
import { IErrorData, IListMetaData } from '../../../../shared/interfaces/index';
import { DynamicListActions, DynamicListActionTypes } from '../actions/dynamic-list.actions';
import { IKey } from '../interfaces/index';


export interface IDynamicListState {
  storeName: string;
  merge: boolean;
  initOnDestroy: boolean;
  objData: IListObjectData[];
}

class DynamicListState implements IDynamicListState {
  storeName: string;
  merge: boolean;
  initOnDestroy: boolean;
  objData: IListObjectData[];
  //entities: {}

  constructor(storeName: string, merge: boolean = false, initOnDestroy: boolean = false) {
    this.storeName = storeName;
    this.merge = merge;
    this.initOnDestroy = initOnDestroy;
    this.objData = [];
  }
}

export interface IListsState {
  addresses: IDynamicListState;
  availableWorkCrews: IDynamicListState;
  branches: IDynamicListState;
  branchNotes: IDynamicListState;
  branchPrograms: IDynamicListState;
  branchProviderLocation: IDynamicListState;
  branchRegions: IDynamicListState;
  branchSkuPrices: IDynamicListState;

  certifications: IDynamicListState;
  chargebacks: IDynamicListState;
  chargeBackItems: IDynamicListState;
  chargeBackSkus: IDynamicListState;
  chargeBackSkuPrices: IDynamicListState;
  chargeBackTypes: IDynamicListState;
  contactCustomerFlags: IDynamicListState;
  contactMechanismCategories: IDynamicListState;
  contactMechanismCategoryTypes: IDynamicListState;

  contactNotes: IDynamicListState;
  contactProjects: IDynamicListState;
  contactProjectWOs: IDynamicListState;
  contactProjectPOs: IDynamicListState;
  contactTypes: IDynamicListState;
  contactTypeSpecifications: IDynamicListState;

  communicationEventActions: IDynamicListState;
  communicationEventFeedbacks: IDynamicListState;
  communicationEventTypes: IDynamicListState;
  communicationShortcuts: IDynamicListState;
  communicationDocumentTypes: IDynamicListState;
  customerFlags: IDynamicListState;

  dashBranchMapping: IDynamicListState;
  dashCalendar: IDynamicListState;
  dashChargeBacks: IDynamicListState;
  dashUnAckChargeBacks: IDynamicListState;
  dashOnHoldChargeBacks: IDynamicListState;
  dashAckUnSubmitChargeBacks: IDynamicListState;

  dashChargeBackInstallments: IDynamicListState;
  dashCommunicationEventLogs: IDynamicListState;
  dashCustomers: IDynamicListState;
  dashCustomerProjects: IDynamicListState;
  dashDispatchedWorkOrders: IDynamicListState;
  dashEmailImports: IDynamicListState;
  dashFileUploads: IDynamicListState;
  dashInstallerVacations: IDynamicListState;
  dashInventoryNeeds: IDynamicListState;
  dashInventoryWos: IDynamicListState;
  dashOpenExternalNotes: IDynamicListState;
  dashOpenProjects: IDynamicListState;
  dashOpenWarranties: IDynamicListState;
  dashOutstandingDocuments: IDynamicListState;
  dashPendingCloseProjects: IDynamicListState;
  dashPendingTasks: IDynamicListState;
  dashPendingApprovals: IDynamicListState;
  dashProgamServiceMapping: IDynamicListState;
  dashUnsentInstallments: IDynamicListState;
  dashScheduleMoveUps: IDynamicListState;
  dashShipperPos: IDynamicListState;
  dashShipperPoItems: IDynamicListState;
  dashSkuMapping: IDynamicListState;
  dashSSSInvites: IDynamicListState;
  dashSSSRequiringActions: IDynamicListState;
  dashSSSOptionCounts: IDynamicListState;
  dashUnassignedExternalNotes: IDynamicListState;
  dashUnassignedWarranties: IDynamicListState;
  dashUndispatchedWorkOrders: IDynamicListState;
  dashUnitMeasureMapping: IDynamicListState;
  dashUnreceivedInventory: IDynamicListState;
  dashUnscheduledWorkOrders: IDynamicListState;
  dashWorkOrderSurveys: IDynamicListState;
  dashWorkOrderSurveyResponses: IDynamicListState;
  documentTypes: IDynamicListState;

  emails: IDynamicListState;
  entities: IDynamicListState;

  generalContractors: IDynamicListState;
  generalContractorInstallerRequirements: IDynamicListState;

  homHolidays: IDynamicListState;

  installers: IDynamicListState;
  installerCompatibilityPreferences: IDynamicListState;
  installerCertifications: IDynamicListState;
  installerCertificationsByRange: IDynamicListState;
  installerCommunicationEvents: IDynamicListState;
  installerEligibility: IDynamicListState;
  installerGcRequirements: IDynamicListState;
  installerNotes: IDynamicListState;
  installerOldestCertifications: IDynamicListState; //custom view
  installerScheduleExclusions: IDynamicListState;
  installerTechnicians: IDynamicListState;
  installerTechnicianCertifications: IDynamicListState;
  installerTechnicianGcRequirements: IDynamicListState;
  installerWorkCrews: IDynamicListState;

  jobScheduleJobParameters: IDynamicListState;

  lookupTypes: IDynamicListState;
  lookupValues: IDynamicListState;
  locationBranches: IDynamicListState;
  locationServices: IDynamicListState;

  notifications: IDynamicListState;

  payouts: IDynamicListState;
  payoutPrices: IDynamicListState;
  payoutSkus: IDynamicListState;
  phones: IDynamicListState;
  plsCertifications: IDynamicListState;
  poImportGroupedErrors: IDynamicListState;
  poImportGroupedHeaders: IDynamicListState;
  poImportHeaders: IDynamicListState;
  poImportLines: IDynamicListState;

  privilegeTemplates: IDynamicListState;
  privilegeTemplateRoles: IDynamicListState;
  privilegeTemplatePrivileges: IDynamicListState;
  privilegeTemplatePrivilegeValues: IDynamicListState;
  privilegeRuleEntitySpecifiers: IDynamicListState;
  privilegeFilterWhereUsed: IDynamicListState;
  privileges: IDynamicListState;
  privilegeWhereUsed: IDynamicListState;
  programs: IDynamicListState;
  programServices: IDynamicListState;

  //parent is projectId
  projectChargeBacks: IDynamicListState;
  projectChargeBackItems: IDynamicListState;
  projectCommunicationEvents: IDynamicListState;
  projectContactMechanisms: IDynamicListState;
  projectEntityDocuments: IDynamicListState;
  projectFiles: IDynamicListState;
  projectHistory: IDynamicListState;
  projectNotes: IDynamicListState;
  projectPurchaseOrders: IDynamicListState;
  projectPurchaseOrderItems: IDynamicListState;
  projectAQTChanges: IDynamicListState;
  projectWorkOrders: IDynamicListState;
  projectWorkOrderItems: IDynamicListState;
  projectUnreceivedInventory: IDynamicListState;

  providerLocations: IDynamicListState;
  providerPayouts: IDynamicListState;
  providerScheduleExclusions: IDynamicListState;
  providerLocationScheduleMoveups: IDynamicListState;
  providerRoles: IDynamicListState;
  psBranchPrograms: IDynamicListState;
  psFiles: IDynamicListState;
  psSkuPrices: IDynamicListState;

  purchaseOrderFiles: IDynamicListState;
  purchaseOrderItems: IDynamicListState;
  purchaseOrderMeasures: IDynamicListState;
  purchaseOrderNotes: IDynamicListState;
  purchaseOrderExternalData: IDynamicListState;

  regions: IDynamicListState;
  regionBranches: IDynamicListState;
  regionSkuPrices: IDynamicListState;
  roleWidgets: IDynamicListState;
  roleUsers: IDynamicListState;
  rolePrivilegeTemplates: IDynamicListState;

  sentNotifications: IDynamicListState;
  serviceGroups: IDynamicListState;
  serviceGroupServices: IDynamicListState;
  services: IDynamicListState;
  servicePls: IDynamicListState;
  skus: IDynamicListState;
  skuPayouts: IDynamicListState;
  skuPrices: IDynamicListState;

  scheduleMeasures: IDynamicListState;
  servicePrograms: IDynamicListState;
  shippers: IDynamicListState;
  shipperLocations: IDynamicListState;
  shipperPayouts: IDynamicListState;
  shipperLocationPayouts: IDynamicListState;

  slotScheduleCalendarLockedDays: IDynamicListState;
  sssGeneralContractors: IDynamicListState;
  sssPrograms: IDynamicListState;
  sssProgramServices: IDynamicListState;
  sssPSBranches: IDynamicListState;
  surveys: IDynamicListState;
  surveyImportMappings: IDynamicListState;
  surveyProgramServices: IDynamicListState;
  surveyProjectMapFields: IDynamicListState;
  surveyQuestions: IDynamicListState;
  surveyQuestionDataTypes: IDynamicListState;

  technicians: IDynamicListState; //all technicians
  technicianCertificationsByRange: IDynamicListState;
  technicianRoles: IDynamicListState;  
  unavailableWorkCrews: IDynamicListState;

  userJobAlerts: IDynamicListState;
  userJobs: IDynamicListState;
  userJobSchedules: IDynamicListState;
  userPendingChanges: IDynamicListState;
  userDelegateSchedules: IDynamicListState;
  userListFilters: IDynamicListState;
  userNotes: IDynamicListState;
  userSecurity: IDynamicListState;

  warrantyTypes: IDynamicListState;
  warrantySubTypes: IDynamicListState;
  //parent is workcrewId
  workCrewEligibility: IDynamicListState;
  workCrewMetrics: IDynamicListState;
  workCrewPlServices: IDynamicListState;
  workCrewScheduleExclusions: IDynamicListState;
  workCrewTechnicians: IDynamicListState;
  workCrewWorkCategories: IDynamicListState;
  workCrewZones: IDynamicListState;

  workOrderAQTChanges: IDynamicListState;
  workOrderCallLogResponses: IDynamicListState;
  workOrderItems: IDynamicListState;
  workOrderNotes: IDynamicListState;
  workOrderWarranties: IDynamicListState;
  workCategories: IDynamicListState;
  workCategoryPayouts: IDynamicListState;

  zones: IDynamicListState;

  dataSlice: IDynamicListState;
}

const initialState: IListsState = {

  addresses: new DynamicListState('addresses', true),
  availableWorkCrews: new DynamicListState('availableWorkCrews', false, true),
  branches: new DynamicListState('branches', true, true),
  branchNotes: new DynamicListState('branchNotes', true, true),
  branchRegions: new DynamicListState('branchRegions', true, true),
  branchProviderLocation: new DynamicListState('branchProviderLocation', true, true),
  branchPrograms: new DynamicListState('branchPrograms', true, true),
  branchSkuPrices: new DynamicListState('branchSkuPrices', true, true),

  certifications: new DynamicListState('certifications', false),
  chargebacks: new DynamicListState('chargebacks', true),
  chargeBackItems: new DynamicListState('chargeBackItems', true, false),
  chargeBackSkus: new DynamicListState('chargeBackSkus', false, true),
  chargeBackSkuPrices: new DynamicListState('chargeBackSkuPrices', true, true),
  chargeBackTypes: new DynamicListState('chargeBackTypes', true, true),
  contactCustomerFlags: new DynamicListState('contactCustomerFlags', true),
  contactMechanismCategories: new DynamicListState('contactMechanismCategories', true, true),
  contactMechanismCategoryTypes: new DynamicListState('contactMechanismCategoryTypes', true, true),
  contactNotes: new DynamicListState('contactNotes', true),
  contactProjects: new DynamicListState('contactProjects', true, true),
  contactProjectWOs: new DynamicListState('contactProjectWOs', true, true),
  contactProjectPOs: new DynamicListState('contactProjectPOs', true, true),
  contactTypes: new DynamicListState('contactTypes', true, true),
  contactTypeSpecifications: new DynamicListState('contactTypeSpecifications', true, true),

  communicationEventActions: new DynamicListState('communicationEventActions', true, false),
  communicationEventFeedbacks: new DynamicListState('communicationEventFeedbacks', false, false),
  communicationEventTypes: new DynamicListState('communicationEventTypes', false, true),
  communicationShortcuts: new DynamicListState('communicationShortcuts', true),
  communicationDocumentTypes: new DynamicListState('communicationDocumentTypes', false),
  customerFlags: new DynamicListState('customerFlags', false, true),

  dashAckUnSubmitChargeBacks: new DynamicListState('dashAckUnSubmitChargeBacks', false, false),
  dashBranchMapping: new DynamicListState('dashBranchMapping', false, false),
  dashCalendar: new DynamicListState('dashCalendar', false, false),
  dashChargeBacks: new DynamicListState('dashChargeBacks', false, false),
  dashChargeBackInstallments: new DynamicListState('dashChargeBackInstallments', true, false), //merge, so works with all cb summary lists
  dashCommunicationEventLogs: new DynamicListState('dashCommunicationEventLogs', false),
  dashCustomers: new DynamicListState('dashCustomers', false, false), //customer search component
  dashCustomerProjects: new DynamicListState('dashCustomerProjects', false, true),
  dashDispatchedWorkOrders: new DynamicListState('dashDispatchedWorkOrders', false, false), //turn off init on destroy
  dashEmailImports: new DynamicListState('dashEmailImports', true),
  dashFileUploads: new DynamicListState('dashFileUploads', true),
  dashInstallerVacations: new DynamicListState('dashInstallerVacations', false, false),
  dashInventoryNeeds: new DynamicListState('dashInventoryNeeds', false, true),
  dashInventoryWos: new DynamicListState('dashInventoryWos', true, true),
  dashOnHoldChargeBacks: new DynamicListState('dashOnHoldChargeBacks', false, false),
  dashOpenProjects: new DynamicListState('dashOpenProjects', false, false), //turn off init on destroy
  dashOpenExternalNotes: new DynamicListState('dashOpenExternalNotes', false, false), //turn off init on destroy
  dashOpenWarranties: new DynamicListState('dashOpenWarranties', false, false),//turn off init on destroy
  dashOutstandingDocuments: new DynamicListState('dashOutstandingDocuments', false,false),
  dashPendingApprovals: new DynamicListState('dashPendingApprovals', false, false),
  dashPendingCloseProjects: new DynamicListState('dashPendingCloseProjects', false, false), //turn off init on destroy
  dashPendingTasks: new DynamicListState('dashPendingTasks', false, false), //turn off init on destroy
  dashProgamServiceMapping: new DynamicListState('dashProgamServiceMapping', true, false),
  dashUnsentInstallments: new DynamicListState('dashUnsentInstallments', false, false),
  dashScheduleMoveUps: new DynamicListState('dashScheduleMoveUps', true, false),
  dashShipperPos: new DynamicListState('dashShipperPos', false, false),
  dashShipperPoItems: new DynamicListState('dashShipperPoItems', true, false),
  dashSkuMapping: new DynamicListState('dashSkuMapping', true, false),
  dashSSSRequiringActions: new DynamicListState('dashSSSRequiringActions', false, false), //turn off init on destroy
  dashSSSOptionCounts: new DynamicListState('dashSSSOptionCounts', true, false), //turn off init on destroy
  dashSSSInvites: new DynamicListState('dashSSSInvites', true, false), //turn off init on destroy
  dashUnAckChargeBacks: new DynamicListState('dashUnAckChargeBacks', false, false),
  dashUnassignedExternalNotes: new DynamicListState('dashUnassignedExternalNotes', false, false), //turn off init on destroy
  dashUnassignedWarranties: new DynamicListState('dashUnassignedWarranties', false, false),
  dashUndispatchedWorkOrders: new DynamicListState('dashUndispatchedWorkOrders', false, false), //turn off init on destroy
  dashUnitMeasureMapping: new DynamicListState('dashUnitMeasureMapping', false, false),
  dashUnreceivedInventory: new DynamicListState('dashUnreceivedInventory', false, false), //turn off init on destroy
  dashUnscheduledWorkOrders: new DynamicListState('dashUnscheduledWorkOrders', false, false), //turn off init on destroy
  dashWorkOrderSurveys: new DynamicListState('dashWorkOrderSurveys', false, false),
  dashWorkOrderSurveyResponses: new DynamicListState('dashWorkOrderSurveyResponses', true, false),
  documentTypes: new DynamicListState('documentTypes', false, true),

  emails: new DynamicListState('emails', true),
  entities: new DynamicListState('entities', false),

  generalContractors: new DynamicListState('generalContractors', false, true),
  generalContractorInstallerRequirements: new DynamicListState('generalContractorInstallerRequirements', false),
  homHolidays: new DynamicListState('homHolidays', false, false),

  installers: new DynamicListState('installers', false),
  installerCompatibilityPreferences: new DynamicListState('installerCompatibilityPreferences', true),
  installerCertifications: new DynamicListState('installerCertifications', true),
  installerCertificationsByRange: new DynamicListState('installerCertificationsByRange', true),
  installerCommunicationEvents: new DynamicListState('installerCommunicationEvents', true),
  installerEligibility: new DynamicListState('installerEligibility', true),
  installerGcRequirements: new DynamicListState('installerGcRequirements', true),
  installerOldestCertifications: new DynamicListState('installerOldestCertifications', false),
  installerNotes: new DynamicListState('installerNotes', true),
  installerScheduleExclusions: new DynamicListState('installerScheduleExclusions', true),
  installerTechnicians: new DynamicListState('installerTechnicians', true),
  installerTechnicianCertifications: new DynamicListState('installerTechnicianCertifications', true),
  installerTechnicianGcRequirements: new DynamicListState('installerTechnicianGcRequirements', true),
  installerWorkCrews: new DynamicListState('installerWorkCrews', true, false),

  jobScheduleJobParameters: new DynamicListState('jobScheduleJobParameters', true, true),

  lookupTypes: new DynamicListState('lookupTypes', false, true),
  lookupValues: new DynamicListState('lookupValues', true, true),
  locationBranches: new DynamicListState('locationBranches', true, true),
  locationServices: new DynamicListState('locationServices', true, true),

  notifications: new DynamicListState('notifications', false, true),

  payouts: new DynamicListState('payouts', false, true),
  payoutPrices: new DynamicListState('payoutPrices', true, true),
  payoutSkus: new DynamicListState('payoutSkus', true, true),
  providerPayouts: new DynamicListState('providerPayouts', true, true),
  phones: new DynamicListState('phones', true),
  plsCertifications: new DynamicListState('plsCertifications', true, false),

  poImportGroupedHeaders: new DynamicListState('poImportGroupedHeaders', false, false),
  poImportGroupedErrors: new DynamicListState('poImportGroupedErrors', false, true), //karl, admin errored line items
  poImportHeaders: new DynamicListState('poImportHeaders', true, false), //keep true
  poImportLines: new DynamicListState('poImportLines', true, true),

  privilegeTemplates: new DynamicListState('privilegeTemplates', true, true),
  privilegeTemplateRoles: new DynamicListState('privilegeTemplateRoles', true, true),
  privilegeTemplatePrivileges: new DynamicListState('privilegeTemplatePrivileges', true, true),
  privilegeTemplatePrivilegeValues: new DynamicListState('privilegeTemplatePrivilegeValues', true, true),
  privilegeRuleEntitySpecifiers: new DynamicListState('privilegeRuleEntitySpecifiers', true, true),
  privilegeFilterWhereUsed: new DynamicListState('privilegeFilterWhereUsed', true, true),
  privileges: new DynamicListState('privileges', true, true),
  privilegeWhereUsed: new DynamicListState('privilegeWhereUsed', true, true),
  programs: new DynamicListState('programs', true, true),
  programServices: new DynamicListState('programServices', true, true),

  //project
  projectChargeBackItems: new DynamicListState('projectChargeBackItems', true, false),
  projectContactMechanisms: new DynamicListState('projectContactMechanisms', true),
  projectChargeBacks: new DynamicListState('projectChargeBacks', true, false),
  projectCommunicationEvents: new DynamicListState('projectCommunicationEvents', true, false),
  projectEntityDocuments: new DynamicListState('projectEntityDocuments', true, false),
  projectHistory: new DynamicListState('projectHistory', true, false),
  projectNotes: new DynamicListState('projectNotes', true, false),
  projectFiles: new DynamicListState('projectFiles', true),
  projectPurchaseOrders: new DynamicListState('projectPurchaseOrders', true, false),
  projectPurchaseOrderItems: new DynamicListState('projectPurchaseOrderItems', true, false),
  projectAQTChanges: new DynamicListState('projectAQTChanges', true, false),  
  projectWorkOrders: new DynamicListState('projectWorkOrders', true, false),
  projectWorkOrderItems: new DynamicListState('projectWorkOrderItems', true, false),
  projectUnreceivedInventory: new DynamicListState('projectUnreceivedInventory', true, false),

  providerLocations: new DynamicListState('providerLocations', false, true),
  providerScheduleExclusions: new DynamicListState('providerScheduleExclusions', false, false),
  providerLocationScheduleMoveups: new DynamicListState('providerLocationScheduleMoveups', true, false),
  providerRoles: new DynamicListState('providerRoles', true, true),
  psBranchPrograms: new DynamicListState('psBranchPrograms', true, true),
  psFiles: new DynamicListState('psFiles', true, true),
  psSkuPrices: new DynamicListState('psSkuPrices', true, true),

  purchaseOrderNotes: new DynamicListState('purchaseOrderNotes', true, false),
  purchaseOrderItems: new DynamicListState('purchaseOrderItems', true, false),
  purchaseOrderFiles: new DynamicListState('purchaseOrderFiles', true, false),
  purchaseOrderExternalData: new DynamicListState('purchaseOrderExternalData', true, false),
  purchaseOrderMeasures: new DynamicListState('purchaseOrderMeasures', true, false),

  regions: new DynamicListState('regions', true, true),
  regionBranches: new DynamicListState('regionBranches', true, true),
  regionSkuPrices: new DynamicListState('regionSkuPrices', true, true),

  roleWidgets: new DynamicListState('roleWidgets', true, true),
  roleUsers: new DynamicListState('roleUsers', true, true),
  rolePrivilegeTemplates: new DynamicListState('rolePrivilegeTemplates', true, true),

  sentNotifications: new DynamicListState('sentNotifications', false, true),
  serviceGroups: new DynamicListState('serviceGroups', false, true),
  serviceGroupServices: new DynamicListState('serviceGroupServices', true, true),
  services: new DynamicListState('services', true, true),
  servicePls: new DynamicListState('servicePls', true, true),
  skus: new DynamicListState('skus', true, true),
  skuPayouts: new DynamicListState('skuPayouts', true, true),
  skuPrices: new DynamicListState('skuPrices', true, true),

  scheduleMeasures: new DynamicListState('scheduleMeasures', true, true),
  servicePrograms: new DynamicListState('servicePrograms', true, true),
  shippers: new DynamicListState('shippers', false, true),
  shipperLocations: new DynamicListState('shipperLocations', true, true),
  shipperPayouts: new DynamicListState('shipperPayouts', true, true),
  shipperLocationPayouts: new DynamicListState('shipperLocationPayouts', true, true),

  slotScheduleCalendarLockedDays: new DynamicListState('slotScheduleCalendarLockedDays', false, true),
  sssGeneralContractors: new DynamicListState('sssGeneralContractors', false, true),
  sssPrograms: new DynamicListState('sssPrograms', false, true),
  sssProgramServices: new DynamicListState('sssProgramServices', false, true),
  sssPSBranches: new DynamicListState('sssPSBranches', false, true),
  surveys: new DynamicListState('surveys', false, false),
  surveyImportMappings: new DynamicListState('surveyImportMappings', true, false),
  surveyProjectMapFields: new DynamicListState('surveyProjectMapFields', true, false),
  surveyProgramServices: new DynamicListState('surveyProgramServices', true, false),
  surveyQuestions: new DynamicListState('surveyQuestions', true, false),
  surveyQuestionDataTypes: new DynamicListState('surveyQuestionDataTypes', false, false),

  technicians: new DynamicListState('technicians', false),
  technicianCertificationsByRange: new DynamicListState('technicianCertificationsByRange', true, true),
  technicianRoles: new DynamicListState('technicianRoles', true, true),
  unavailableWorkCrews: new DynamicListState('unavailableWorkCrews', false, true),

  userDelegateSchedules: new DynamicListState('userDelegateSchedules', false),
  userJobs: new DynamicListState('userJobs', false),
  userJobAlerts: new DynamicListState('userJobAlerts', false),
  userJobSchedules: new DynamicListState('userJobSchedules', false),
  userListFilters: new DynamicListState('userListFilters', true, false),
  userNotes: new DynamicListState('userNotes', true), //true!
  userPendingChanges: new DynamicListState('userPendingChanges', false),
  userSecurity: new DynamicListState('userSecurity', false, true),

  warrantyTypes: new DynamicListState('warrantyTypes', false, true),
  warrantySubTypes: new DynamicListState('warrantySubTypes', true, true),

  workCrewEligibility: new DynamicListState('workCrewEligibility', true),
  workCrewMetrics: new DynamicListState('workCrewMetrics', true),
  workCrewPlServices: new DynamicListState('workCrewPlServices', true),
  workCrewScheduleExclusions: new DynamicListState('workCrewScheduleExclusions', true),
  workCrewTechnicians: new DynamicListState('workCrewTechnicians', true),
  workCrewWorkCategories: new DynamicListState('workCrewWorkCategories', true),
  workCrewZones: new DynamicListState('workCrewZones', true),

  workOrderAQTChanges: new DynamicListState('workOrderAQTChanges', true, true), //keep as init on destroy
  workOrderCallLogResponses: new DynamicListState('workOrderCallLogResponses', true, false),
  workOrderItems: new DynamicListState('workOrderItems', true, false),
  workOrderNotes: new DynamicListState('workOrderNotes', true, false),
  workOrderWarranties: new DynamicListState('workOrderWarranties', true, false),
  workCategories: new DynamicListState('workCategories', false, true),
  workCategoryPayouts: new DynamicListState('workCategoryPayouts', false, true),

  zones: new DynamicListState('zones', true, true),

  dataSlice: new DynamicListState('dataSlice', false, true),
};

export function dynamicListReducer(state = initialState, action: DynamicListActions): IListsState {
  switch (action.type) {

    case DynamicListActionTypes.SET_LIST_DEFINITION:
      {
        const storeName = action.payload.listDefinition.storeName;
        let listStore = cloneDeep(state[storeName]);

        let parentId = listStore.merge ? +action.payload.parentId : -1;  //+ converts string numeric to number
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          //add a new objData object to the array for this parent id--not pushing to the store, just to a local property
          listStore.objData.push({
            parentId: parentId,
            listDefinition: action.payload.listDefinition,
            data: null,
            listMetaData: { totalItems: 0 },
            errorData: [],
            extraData: null,
            message: '',
            listFilter: action.payload.listDefinition.defaultListFilter,
            working: true,
            event: null
          });
        } else {
          listStore.objData[index].listDefinition = action.payload.listDefinition;
          listStore.objData[index].listFilter = action.payload.listDefinition.defaultListFilter;
          listStore.objData[index].working = true;
        }

        const updatedData = {
          storeName: action.payload.storeName,
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.SET_LIST_FILTER:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          //console.log('***dynamic-list.reducer.SET_LIST_FILTER  ERROR store for this parent id not found', action.payload);
          return state;
        }
        listStore.objData[index].listFilter = action.payload.listFilter;
        listStore.objData[index].working = true;

        const updatedData = {
          storeName: action.payload.storeName,
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

      case DynamicListActionTypes.SET_LIST_RESULTS:
        {
          const storeName = action.payload.storeName;
          let listStore =cloneDeep( state[storeName]);
          let parentId = listStore.merge ? +action.payload.parentId : -1;
          const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
          if (index === -1) {
            //Did you set your list definition before dispatching?
            //console.log('***dynamic-list.reducer.SET_LIST_RESULTS  ERROR store for this parent id not found', action.payload);
            return state;
          }
          //console.log('dynamic-list.reducer setlistresults2 with : ',listStore.objData[index].listDefinition);
          let listDefinition: IListDefinition = listStore.objData[index].listDefinition;
         //if your results are returned without listMetaData, then your controller needs to be modified to wrap the response in apiresponsewrapper

          if (listDefinition) {
            if (action.payload.listMetaData.hasOwnProperty('pageMetaData') &&
              action.payload.listMetaData['pageMetaData'] &&
              action.payload.listMetaData['pageMetaData'].itemsPerPage) {
              //update itemsperpage - default was set to 10 during SetListDefinition
              listStore.objData[index].listDefinition.itemsPerPage = action.payload.listMetaData['pageMetaData'].itemsPerPage;
            }
          }

          //if working with a merge, append to records for this parentId
          //const excludeMyRecs = !listStore['merge'] || !listData.data.length ? [] :  listData.data.filter(x => x[listDefinition.parentKey] !== action.payload.data[0][listDefinition.parentKey]);
          //listStore.objData[index].data = listStore.merge ? { ...listStore.objData[index].data, ...action.payload.data } : action.payload.data;
          listStore.objData[index].data = action.payload.data;
          listStore.objData[index].listMetaData = action.payload.listMetaData;
          listStore.objData[index].errorData = action.payload.errorData;
          listStore.objData[index].message = action.payload.error;
          listStore.objData[index].staleData = false;
          listStore.objData[index].working = false;
          listStore.objData[index].event = action.payload.event ? action.payload.event : null;
          listStore.objData[index].lastUpdated = action.payload.lastUpdated ? action.payload.lastUpdated : 0;


          const updatedData = {
            storeName: action.payload.storeName,
            objData: [...listStore.objData],
          }

          return {
            ...state,
            [`${storeName}`]: { ...state[storeName], ...updatedData }

          }
      }


    case DynamicListActionTypes.UPDATE_COMPLETE_LIST:
      {
        const keyData = action.payload.keyData;
        const storeName = keyData.storeName;
        const skipDataUpdate = keyData.hasOwnProperty('skipDataUpdate') && keyData.skipDataUpdate;

        const responseBase = action.payload.responseBase;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +keyData.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***dynamic-list.reducer.UPDATE_COMPLETE  ERROR store for this parent id not found', action.payload);
          return state;
        }

        const detailIndex = listStore.objData[index].data
          ? listStore.objData[index].data.findIndex(data => data[keyData.key] == keyData.id)
          : -1;
        if (detailIndex === -1 && !skipDataUpdate) {
          console.log('***dynamic-list.reducer.UPDATE_COMPLETE  ERROR store for this detailIndex id not found', action.payload);
          return state;
        }
        //merge current values with returned values from update to create the updated record
        if (!skipDataUpdate) {
          listStore.objData[index].data[detailIndex] = responseBase.success ? responseBase.data : listStore.objData[index].data[detailIndex];
        }
        listStore.objData[index].errorData = responseBase.success ? [] : action.payload.responseBase.errorData;
        listStore.objData[index].working = false;
        listStore.objData[index].event = action.payload.event;


        const updatedData = {
          storeName: storeName,
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }


    case DynamicListActionTypes.CREATE_COMPLETE_LIST:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);

        let parentId = listStore.merge ? action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***dynamic-list.reducer.CREATE_COMPLETE  ERROR store for this parent id not found', action.payload);
          return state;
        }
        if (!listStore.objData[index].data) {
          listStore.objData[index].data = [];
        }
        // data will be empty for entities that need approval queue
        listStore.objData[index].data = action.payload.responseBase.success && action.payload.responseBase.data
          ? [...listStore.objData[index].data, action.payload.responseBase.data]
          : [...listStore.objData[index].data];
        listStore.objData[index].errorData = action.payload.responseBase.success ? [] : action.payload.responseBase.errorData;
        listStore.objData[index].listMetaData.totalItems = listStore.objData[index].listMetaData.totalItems > 0 ? listStore.objData[index].listMetaData.totalItems + 1 : listStore.objData[index].listMetaData.totalItems;
        listStore.objData[index].working = false;
        listStore.objData[index].event = action.payload.event;

        const updatedData = {
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.DELETE_COMPLETE_LIST:
      {
        const keyData = action.payload.keyData;
        const storeName = keyData.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +keyData.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***dynamic-list.reducer.DELETE_COMPLETE  ERROR store for this parent id not found', action.payload);
          return state;
        }

        let data = listStore.objData[index].data;
        if (action.payload.responseBase.success) {
          //remove this record from  state
          data = listStore.objData[index].data.filter(x => x[keyData.key] != keyData.id);
        }

        listStore.objData[index].data = data;
        listStore.objData[index].event = null;
        listStore.objData[index].errorData = action.payload.responseBase.success ? '' : action.payload.responseBase.errorData;
        const newTotal: number = action.payload.responseBase.success ? listStore.objData[index].listMetaData.totalItems > 0 ? listStore.objData[index].listMetaData.totalItems - 1 : listStore.objData[index].listMetaData.totalItems
          : listStore.objData[index].listMetaData.totalItems;
        listStore.objData[index].listMetaData.totalItems = newTotal;
        if (listStore.objData[index].listMetaData.hasOwnProperty('pageMetaData')) {
          listStore.objData[index].listMetaData.pageMetaData.totalItems = newTotal;
        }
        listStore.objData[index].event = action.payload.event;
        listStore.objData[index].working = false;

        const updatedData = {
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${keyData.storeName}`]: { ...state[keyData.storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.SET_LIST_LOAD_FAILURE:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***dynamic-list.reducer.SET_LIST_LOAD_FAILURE  ERROR store for this parent id not found', action.payload);
          return state;
        }

        listStore.objData[index].message = action.payload.error;

        const updatedData = {
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.SET_ERROR_RETURNED_LIST:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***dynamic-list.reducer.SET_ERROR_RETURNED  ERROR store for this parent id not found', action.payload);
          return state;
        }

        listStore.objData[index].message = action.payload.error;

        const updatedData = {
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.SET_STALE_DATA_CHECK_RESULT_LIST:
      {
        const result = action.payload.responseBase;
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        if (!listStore) return state;

        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***dynamic-list.reducer.SET_STALE_DATA_CHECK_RESULT_LIST  ERROR store for this parent id not found', action.payload);
          return state;
        }

        const error = action.payload.forRow
          ? 'Detail request was cancelled.  Local values for this record are out of sync with the values on the server.  Please refresh your data.'
          : 'Changes have been made to the parent record.  Local values are out of sync with the values on the server.  Please refresh your data.'

        let value: string[] = [];
        let errorData: IErrorData[] = [];
        value.push(error);
        errorData.push({ 'key': '__Model', 'value': value });

        listStore.objData[index].staleData = !result.success ? true : !result.data.isCurrent || !result.data.isAggregateRootCurrent ? true : false;
        listStore.objData[index].errorData = !result.success ? errorData : !result.data.isCurrent || !result.data.isAggregateRootCurrent ? errorData : [];

        const updatedData = {
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.INITIALIZE_LIST:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        //If store is set up to not init and this request is from ondestroy (versus a force - just init me please)
        if (action.payload.forOnDestroy && !listStore.initOnDestroy) return state;

        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName] && state[storeName].objData ? state[storeName].objData.findIndex(x => x.parentId == parentId) : -1;
        if (index === -1) {
          return state;
        }
        if (!listStore.merge) {
          //just init the data
          listStore.objData[index].listDefinition = null;
          listStore.objData[index].data = null;
          listStore.objData[index].listMetaData = null;
          listStore.objData[index].errorData = [];
          listStore.objData[index].extraData = null;
          listStore.objData[index].message = '';
          listStore.objData[index].listFilter = null;
          listStore.objData[index].staleData = false;
          listStore.objData[index].lastUpdated = null;
        }

        // if merge type of store, remove the entire objData reference for that parent
        let objData = listStore.merge ? listStore.objData.filter(x => x.parentId !== parentId) : listStore.objData;
        const updatedData = {
          storeName: action.payload.storeName,
          objData: [...objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }

        }
      }

    case DynamicListActionTypes.INITIALIZE_LIST_DATA:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);

        //If store is set up to not init and this request is from ondestroy (versus a force - just init me please)
        //if (action.payload.forOnDestroy && !listStore.initOnDestroy) return state;

        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName] && state[storeName].objData ? state[storeName].objData.findIndex(x => x.parentId == parentId) : -1;
        if (index === -1) {
          return state;
        }

        listStore.objData[index].data = null;
        listStore.objData[index].errorData = [];
        if (listStore.objData[index].listMetaData) {
          listStore.objData[index].listMetaData.totalItems = 0;
          if (listStore.objData[index].listMetaData.pageMetaData) {
            listStore.objData[index].listMetaData.pageMetaData.currentPage = 1;
            listStore.objData[index].listMetaData.pageMetaData.totalItems = 0;
          }
        }
        listStore.objData[index].message = '';
        listStore.objData[index].staleData = false;
        listStore.objData[index].working = false;

        // if merge type of store, remove the entire objData reference for that parent
        let objData = listStore.merge ? listStore.objData.filter(x => x.parentId !== parentId) : listStore.objData;
        const updatedData = {
          storeName: action.payload.storeName,
          objData: [...objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }

        }
      }

    case DynamicListActionTypes.SET_WORKING_LIST:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          return state;
        }
        listStore.objData[index].working = action.payload.working;
        listStore.objData[index].errorData = [];
        listStore.objData[index].message = '';

        const updatedData = {
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.SET_EVENT_LIST:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          return state;
        }

        listStore.objData[index].event = action.payload.event;

        const updatedData = {
          objData: [...listStore.objData]
        }
        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.CLEAR_EVENT_LIST:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          return state;
        }

        listStore.objData[index].event = null;

        const updatedData = {
          objData: [...listStore.objData]
        }
        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.CLEAR_ERRORS_LIST:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***DEV INFO: dynamic-list.reducer.CLEAR_ERRORS  store for this parent id not found', action.payload);
          return state;
        }
        listStore.objData[index].errorData = [];
        listStore.objData[index].message = '';
        listStore.objData[index].event = null;

        const updatedData = {
          objData: [...listStore.objData]
        }
        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }


    case DynamicListActionTypes.SET_CALCULATED_PROPERTY:
      {
        const keyData: IKey = action.payload.keyData;
        const storeName = action.payload.keyData.storeName;

        //const responseBase = action.payload.responseBase;
        let listStore = cloneDeep(state[storeName]);
        let parentId = listStore.merge ? +action.payload.keyData.parentId : -1;
        const index = state[storeName].objData.findIndex(x => x.parentId == parentId);
        if (index === -1) {
          console.log('***dynamic-list.reducer.SET_CALCULATED_PROPERTY  ERROR store for this parent id not found', listStore, action.payload);
          return state;
        }

        //keyData.key should isolate to a single record projectWorkOrders keyData.key would be workOrderId for example, not parentKey, but rowKey
        const detailIndex = listStore.objData[index].data
          ? listStore.objData[index].data.findIndex(data => data[keyData.key] == keyData.id)
          : -1;
        if (detailIndex === -1) {
          console.log('***dynamic-list.reducer.SET_CALCULATED_PROPERTY  ERROR store for this detailIndex id not found', listStore.objData[index], action.payload);
          return state;
        }

        //merge current values with returned values from update to create the updated record
        if (listStore.objData[index].data[detailIndex].hasOwnProperty(action.payload.propertyName)) {
          listStore.objData[index].data[detailIndex][action.payload.propertyName] = action.payload.propertyValue;
        }

        const updatedData = {
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.SET_LIST_META_DATA:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        if (!listStore.objData[0]) {
          return state;
        }
        listStore.objData[0].listMetaData = action.payload.metaData.fieldMetaData;
        listStore.objData[0].working = false;

        const updatedData = {
          storeName: action.payload.storeName,
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

    case DynamicListActionTypes.RESET_ALL_LIST_STORES:
      {
        return initialState
      }

    case DynamicListActionTypes.SORT_IN_MEMORY_COMPLETE:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);
        const index = state[storeName].objData.findIndex(x => x.parentId == action.payload.parentId);
        if (index === -1) {
          return state;
        }

        listStore.objData[index].data = action.payload.data;
        listStore.objData[index].listFilter = action.payload.listFilter;
        listStore.objData[index].working = false;
        listStore.objData[index].event = action.payload.event;
        const updatedData = {
          storeName: storeName,
          objData: [...listStore.objData],
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }

        }
      }

    case DynamicListActionTypes.SPLIT_BY_PARENT:
      {
        const storeName = action.payload.storeName;
        let listStore = cloneDeep(state[storeName]);

        if (!action.payload.splitOnConfig || !listStore.objData || !listStore.objData.length || listStore.objData.length > 1) {
          //No Split Key or Parent has already been split - check redux';
          return state;
        }

        const storeTemplate: IListObjectData = cloneDeep(listStore.objData[0]);
        let origListDef = cloneDeep(storeTemplate.listDefinition);
        origListDef.parentKey = 'listComponentId';
        origListDef.controllerMethod = action.payload.splitOnConfig.controllerMethod || origListDef.controllerMethod;
        let origMetaData = cloneDeep(storeTemplate.listMetaData);
        let origListFilter = cloneDeep(storeTemplate.listFilter);

        //group data by property name (like listComponent_listComponentId)
        //then map it into an array with properties parentId and data.
        var uniqueGroups: any[] = chain(listStore.objData[0].data)
          .groupBy(action.payload.splitOnConfig.splitOnKey)
          .toPairs()
          .map((currentData) => {
            return zipObject(['parentId', 'data'], currentData);
          })
          .value();

        //merge these new groups back into the original store but with the keyName value as the parentId
        uniqueGroups.forEach((x) => {
          let myListDef = cloneDeep(origListDef);
          if (action.payload.splitOnConfig.methodParameters) {
            //append to the method parameters
            //assumes new method parameter will be by this splitKey value.
            myListDef.parentId = +x.parentId;
            myListDef.methodParameters = action.payload.splitOnConfig.methodParameters + x.parentId.toString();
          }

          let myMetaData = cloneDeep(origMetaData); 
          if (myMetaData) {
            if (myMetaData.pageMetaData) {
              myMetaData.pageMetaData.totalItems = x.data.length;
            }
            myMetaData.totalItems = x.data.length;
          }

          let myListFilter = cloneDeep(origListFilter);
          myListFilter.getAll = false;  //may want to pass this in as part of config.

          listStore.objData.push({
            parentId: +x.parentId, //must be numeric
            listDefinition: myListDef,  //need to modify this?
            data: x.data,
            listMetaData: myMetaData, 
            errorData: [],
            message: null,
            extraData: null,
            listFilter: origListFilter,
            working: false,
            event: null,
            lastUpdated: storeTemplate.lastUpdated
          });
        });

        //remove [0] item from objData: no longer needed.
        listStore.objData = listStore.objData.filter(x => x.parentId !== -1);

        const updatedData = {
          storeName: action.payload.storeName,
          objData: [...listStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }

      }

    //end default return value
    default:
      return state;
  }
}


export interface IListObjectData {
  listDefinition: ListDefinition;  //lets us know what object we are getting data for
  parentId: number;
  data: any[];
  listMetaData: IListMetaData;
  errorData: IErrorData[];
  extraData: any;
  message: string;
  listFilter: IListFilter;
  staleData: boolean;
  working: boolean;
  event: IHomEventEmitter;
  lastUpdated: number;
}
//export const getDynamicLists = (state: IState) => state;
export const getListObjData = (state: IDynamicListState) => state.objData;
