import { IAddress } from '../view-models/index';
import { ICrud } from '../../../shared/interfaces';

export interface IWorkOrder {
  workOrderId: number;   
  project_projectId: number;
  workOrderStatus_lookupValueId: number;
  service_serviceId: number;
  providerLocation_providerLocationId: number;
  startDate: string;
  endDate: string;
  capacityUnits: number;
  scheduleLocked: boolean;
  workOrderSelfServiceEligibilityId: number;
  allowSlotBufferScheduling: boolean;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
  updateDate: string;

  //extra data
  allowScheduleBeforeInventory: boolean;
  branchName: string;
  canAddItems: boolean;
  generalContractorId: number;
  hasFurniture: boolean;
  hasCommunicationEvents: boolean;
  hasStairs: boolean;
  isSssEligible: boolean;
  linkedPurchaseOrders: string;
  notSchedulableReason: string;
  projectCreationDate: string;
  providerLocationServiceId: number;
  scheduleId: number;
  selfServiceState: string;
  slotBuffer: number;
  workOrderCustomerPhone: string;
  workOrderCustomerEmail: string;
  workOrderCustomerAddress: IAddress;
  workOrderProgramText: string;
  workOrderInstallerName: string;
  workOrderItemCount: number;
  workOrderServiceName: string;
  workOrderCapacityText: string;
  workOrderWarrantyText: string;
  workOrderWarrantyCount: number;
  workOrderCustomerName: string;
  workOrderCity: string;
  workOrderScheduleDate: string;
  workOrderStatusText: string;
  workOrderScheduleText: string;
  workOrderMaxScheduleHours: number;
  workOrderAccntSyncLog: string;
  warrantyBranchId: number;
  warrantyProjectIds: number[];

  metaData: {
    //add other urls here if needed.
    crud: ICrud;
  };

}


export class WorkOrder implements IWorkOrder {
  workOrderId: number;
  project_projectId: number;
  workOrderStatus_lookupValueId: number;
  service_serviceId: number;
  providerLocation_providerLocationId: number;
  startDate: string;
  endDate: string;
  capacityUnits: number;
  scheduleLocked: boolean;
  workOrderSelfServiceEligibilityId: number;
  allowSlotBufferScheduling: boolean;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
  updateDate: string;

  //extra data
  allowScheduleBeforeInventory: boolean;
  branchName: string;
  canAddItems: boolean;
  generalContractorId: number;
  hasFurniture: boolean;
  hasCommunicationEvents: boolean;
  hasStairs: boolean;
  isSssEligible: boolean;
  linkedPurchaseOrders: string;
  notSchedulableReason: string;
  projectCreationDate: string;
  providerLocationServiceId: number;
  scheduleId: number;
  selfServiceState: string;
  slotBuffer: number;
  workOrderCustomerPhone: string;
  workOrderCustomerEmail: string;
  workOrderCustomerAddress: IAddress;
  workOrderProgramText: string;
  workOrderInstallerName: string;
  workOrderItemCount: number;
  workOrderServiceName: string;
  workOrderCapacityText: string;
  workOrderWarrantyText: string;
  workOrderWarrantyCount: number;
  workOrderCustomerName: string;
  workOrderCity: string;
  workOrderScheduleDate: string;
  workOrderStatusText: string;
  workOrderScheduleText: string;
  workOrderMaxScheduleHours: number;
  workOrderAccntSyncLog: string;
  warrantyBranchId: number;
  warrantyProjectIds: number[];

  metaData: {
    crud: ICrud;
  };

  constructor() {
    this.workOrderId = 0;
    this.project_projectId = null;
    this.service_serviceId = null;
    this.workOrderStatus_lookupValueId = null;
    this.providerLocation_providerLocationId = null;
    this.startDate = '';
    this.endDate = '';
    this.capacityUnits = null;
    this.scheduleLocked = false;
    this.workOrderSelfServiceEligibilityId = null;
    this.allowSlotBufferScheduling = false;

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }

}

