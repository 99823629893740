/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ChargeBackDashboardService } from '../../../portal-shared/services/charge-back-dashboard.service';
import { ChargeBackStore } from '../../../portal-shared/enums/charge-back-dashboard.enums';


@Component({
  selector: 'acknowledged-unsubmitted-chargebacks',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './acknowledged-unsubmitted-chargebacks.component.html'
})
export class AcknowledgedUnsubmittedChargebacksComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public chargeBacksService: ChargeBackDashboardService) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.chargeBacksService.loadDashChargeBackSummaryListDefinition(ChargeBackStore.dashAckUnSubmitChargeBacks));
    });
  }
}
