import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HomPipesModule } from 'hom-lib/hom-pipes';
import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedRoutingModule } from './portal-shared-routing.module';
import { HomSignatureModule } from 'hom-lib/hom-signature';

//services
import {
  JobScheduleService,
  NoteService,
  TaskManagerService,  ContactProjectService,  RequiredDocumentService} from './services/index'

//components
import { 
  NoteComponent,
  NotesComponent,
  NotificationsComponent,
  InstallerTechnicianWithCertificationRangeComponent,
  PlsEntityDetailComponent,
  ContactCustomerFlagComponent,
  ContactCustomerFlagDetailComponent,
  EmptyComponent,
  NoteReplyComponent,
  TaskActionComponent,
  TaskActionKeyRecComponent,
  TaskActionScheduleWoComponent,
  TaskComponent,
  DashboardCountComponent,
  PurchaseOrdersSummaryComponent,
  WorkOrderCallLogComponent,
  WorkOrdersSummaryComponent,
  PostItNoteComponent,
  NoteListItemComponent,
  AQTChangeComponent,
  ScheduleLockedDaysCreateComponent,
  ChargeBacksSummaryComponent,
  NoteRecipientsComponent,
  ContactProjectsComponent,
  ContactProjectWorkOrdersComponent,
  ContactProjectPurchaseOrdersComponent,
  FileToolsComponent,
  PurchaseOrderCreateComponent,  RequiredDocumentCreateComponent,
  InstallerDetailComponent,
  LswpOverrideReasonComponent,
  LswpPurchaseOrderComponent,
  ChargeBackInstallmentsComponent
} from './components/index';

//containers
import {
  PortalDetailLayoutContainerComponent,
  PortalJustTabsLayoutContainerComponent,
  ContactCustomerFlagContainerComponent,
  ContactCustomerFlagsComponent,
  TaskManagerComponent,
  DashboardCountsComponent,
  PurchaseOrdersSummaryContainerComponent,
  WorkOrderCallLogsComponent,
  WorkOrdersSummaryContainerComponent,
  WorkOrderNotesComponent,
  DispatchManagerComponent,
  PostItNotesComponent,
  NoteReplyManagerComponent,
  NotesManagerComponent,
  ScheduleLockedDaysComponent,
  DocumentUploadManagerComponent,
  ProjectRequiredDocumentsComponent,
  RequiredDocumentCreateContainerComponent,
  LswpDocumentMangagerComponent,
  LswpOverrideReasonsComponent,
  InstallerComponent,  ChargeBackInstallmentManagerComponent
} from './containers/index';

import { ProjectWorkOrderItemSummaryComponent } from '../project/containers/index';
import { EnumResolver } from '../resolvers';
import { ChargeBackDashboardService } from './services/charge-back-dashboard.service';

@NgModule({
  declarations: [
    NoteComponent,
    NotesComponent,
    NotificationsComponent,
    NotesManagerComponent,
    InstallerTechnicianWithCertificationRangeComponent,
    PortalDetailLayoutContainerComponent,
    PortalJustTabsLayoutContainerComponent,
    PlsEntityDetailComponent,
    ContactCustomerFlagComponent,
    ContactCustomerFlagDetailComponent,
    ContactCustomerFlagContainerComponent,
    ContactCustomerFlagsComponent,
    TaskManagerComponent,
    EmptyComponent,
    NoteReplyComponent,
    TaskActionComponent,
    TaskActionKeyRecComponent,
    TaskActionScheduleWoComponent,
    TaskComponent,
    DashboardCountComponent,
    DashboardCountsComponent,
    PurchaseOrdersSummaryComponent,
    PurchaseOrdersSummaryContainerComponent,
    WorkOrderCallLogComponent,
    WorkOrderCallLogsComponent,
    WorkOrdersSummaryComponent,
    WorkOrdersSummaryContainerComponent,
    WorkOrderNotesComponent,
    PostItNoteComponent,
    PostItNotesComponent,
    DispatchManagerComponent,
    NoteReplyManagerComponent,
    NoteListItemComponent,
    AQTChangeComponent,
    ProjectWorkOrderItemSummaryComponent,
    ScheduleLockedDaysComponent,
    ScheduleLockedDaysCreateComponent,
    ChargeBacksSummaryComponent,
    NoteRecipientsComponent,
    ContactProjectsComponent,
    ContactProjectWorkOrdersComponent,
    ContactProjectPurchaseOrdersComponent,
    FileToolsComponent,
    PurchaseOrderCreateComponent,
    DocumentUploadManagerComponent,
    RequiredDocumentCreateComponent,
    RequiredDocumentCreateContainerComponent,
    ProjectRequiredDocumentsComponent,
    InstallerDetailComponent,
    LswpDocumentMangagerComponent,
    LswpOverrideReasonsComponent,
    LswpOverrideReasonComponent,
    LswpPurchaseOrderComponent,
    InstallerComponent,
    ChargeBackInstallmentManagerComponent,
    ChargeBackInstallmentsComponent
],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HomPipesModule,
    FwSharedModule,
    FwModule,
    SharedModule,
    PortalSharedRoutingModule,
    HomSignatureModule
  ],
  exports: [
    NoteComponent,
    InstallerTechnicianWithCertificationRangeComponent,
    PortalDetailLayoutContainerComponent,
    PortalJustTabsLayoutContainerComponent,
    PlsEntityDetailComponent,
    ContactCustomerFlagComponent,
    ContactCustomerFlagDetailComponent,
    ContactCustomerFlagContainerComponent,
    ContactCustomerFlagsComponent,
    TaskManagerComponent,
    TaskActionComponent,
    TaskActionKeyRecComponent,
    TaskActionScheduleWoComponent,
    TaskComponent,
    EmptyComponent,
    DashboardCountComponent,
    DashboardCountsComponent,
    PurchaseOrdersSummaryComponent,
    PurchaseOrdersSummaryContainerComponent,
    WorkOrderCallLogComponent,
    WorkOrdersSummaryComponent,
    WorkOrdersSummaryContainerComponent,
    WorkOrderNotesComponent,
    PostItNoteComponent,
    PostItNotesComponent,
    DispatchManagerComponent,
    NoteReplyManagerComponent,
    NoteListItemComponent,
    NotesManagerComponent,
    AQTChangeComponent,
    ProjectWorkOrderItemSummaryComponent,
    ScheduleLockedDaysComponent,
    ScheduleLockedDaysCreateComponent,
    ChargeBacksSummaryComponent,
    NoteRecipientsComponent,
    ContactProjectsComponent,
    ContactProjectWorkOrdersComponent,
    ContactProjectPurchaseOrdersComponent,
    FileToolsComponent,
    PurchaseOrderCreateComponent,
    DocumentUploadManagerComponent,
    RequiredDocumentCreateComponent,
    RequiredDocumentCreateContainerComponent,
    ProjectRequiredDocumentsComponent,
    InstallerComponent,
    InstallerDetailComponent,
    ChargeBackInstallmentManagerComponent,
    ChargeBackInstallmentsComponent
  ],
  providers: [
    EnumResolver
  ]//the providers pulled in for use within this module
})
export class PortalSharedModule {
  static forRoot(): ModuleWithProviders<PortalSharedModule> {
    return {
      ngModule: PortalSharedModule,
      providers: [
        JobScheduleService,
        NoteService,
        TaskManagerService,
        ContactProjectService,
        RequiredDocumentService,
        ChargeBackDashboardService
      ] //the providers this module opens up for others to use
    };
  }
}
