/*
  This is a common reducer used to store meta data that defines how fields, by object type, should be rendered
*/
import { IFieldDefinition } from '../../../dynamic-forms/index';
import * as MetaDataActions from '../actions/meta-data.actions';

//State interface for each store
export interface IMetaDataState {
  storeName: string;
  fieldDefinitions: IFieldDefinition[];
  seedData: any; //not typable, varies by object aside from objects of:  fks, metaData, needed for create and enum api load calls
}

class MetaDataState implements IMetaDataState {
  storeName: string;
  fieldDefinitions: IFieldDefinition[];
  seedData: any;

  constructor(storeName: string) {
    this.storeName = storeName;
    this.fieldDefinitions = [];
    this.seedData = {};
  }
}

export interface IMetasState {
  addresses: IMetaDataState;
  availableWorkCrews: IMetaDataState;
  branches: IMetaDataState;
  branchNotes: IMetaDataState;
  branchPrograms: IMetaDataState;
  branchProviderLocation: IMetaDataState;
  branchRegions: IMetaDataState;
  branchSkuPrices: IMetaDataState;

  certifications: IMetaDataState;
  chargebacks: IMetaDataState;
  chargeBackItems: IMetaDataState;
  chargeBackSkus: IMetaDataState;
  chargeBackSkuPrices: IMetaDataState;
  chargeBackTypes: IMetaDataState;
  contactInformation: IMetaDataState; //for object store
  contactCustomerFlags: IMetaDataState;
  contactMechanismCategories: IMetaDataState;
  contactMechanismCategoryTypes: IMetaDataState;
  contactNotes: IMetaDataState;
  contactProjects: IMetaDataState;
  contactProjectWOs: IMetaDataState;
  contactProjectPOs: IMetaDataState;
  contactTypes: IMetaDataState;
  contactTypeSpecifications: IMetaDataState;
  communicationDocumentTypes: IMetaDataState;
  communicationEventActions: IMetaDataState;
  communicationEventFeedbacks: IMetaDataState;
  communicationEventTypes: IMetaDataState;
  communicationShortcuts: IMetaDataState;
  contactCommunicationPreference: IMetaDataState;
  customerFlags: IMetaDataState;

  dashBranchMapping: IMetaDataState;
  dashCalendar: IMetaDataState;
  dashChargeBacks: IMetaDataState;
  dashUnAckChargeBacks: IMetaDataState;
  dashOnHoldChargeBacks: IMetaDataState;
  dashAckUnSubmitChargeBacks: IMetaDataState;

  dashChargeBackInstallments: IMetaDataState;
  dashCommunicationEventLogs: IMetaDataState;
  dashCustomers: IMetaDataState;
  dashCustomerProjects: IMetaDataState;
  dashDispatchedWorkOrders: IMetaDataState;
  dashEmailImports: IMetaDataState;
  dashFileUploads: IMetaDataState;
  dashInstallerVacations: IMetaDataState;
  dashInventoryNeeds: IMetaDataState;
  dashInventoryWos: IMetaDataState;
  dashOpenExternalNotes: IMetaDataState;
  dashOpenProjects: IMetaDataState;
  dashOpenWarranties: IMetaDataState;
  dashOutstandingDocuments: IMetaDataState;
  dashPendingApprovals: IMetaDataState;
  dashPendingCloseProjects: IMetaDataState;
  dashPendingTasks: IMetaDataState;
  dashProgamServiceMapping: IMetaDataState;
  dashUnsentInstallments: IMetaDataState;
  dashScheduleMoveUps: IMetaDataState;
  dashShipperPos: IMetaDataState;
  dashShipperPoItems: IMetaDataState;
  dashSkuMapping: IMetaDataState;
  dashSSSRequiringActions: IMetaDataState;
  dashSSSOptionCounts: IMetaDataState;
  dashSSSInvites: IMetaDataState;
  dashUnassignedExternalNotes: IMetaDataState;
  dashUnassignedWarranties: IMetaDataState;
  dashUndispatchedWorkOrders: IMetaDataState;
  dashUnitMeasureMapping: IMetaDataState;
  dashUnreceivedInventory: IMetaDataState;
  dashUnscheduledWorkOrders: IMetaDataState;
  dashWorkOrderSurveys: IMetaDataState;
  dashWorkOrderSurveyResponses: IMetaDataState;
  documentTypes: IMetaDataState;

  emails: IMetaDataState;

  generalContractors: IMetaDataState;
  generalContractorInstallerRequirements: IMetaDataState;

  homHolidays: IMetaDataState;

  jobScheduleJobParameters: IMetaDataState;

  lookupTypes: IMetaDataState;
  lookupValues: IMetaDataState;
  locationBranches: IMetaDataState;
  locationServices: IMetaDataState;

  installers: IMetaDataState;
  installerCompatibilityPreferences: IMetaDataState;
  installerCertifications: IMetaDataState;
  installerCertificationsByRange: IMetaDataState;
  installerCommunicationEvents: IMetaDataState;
  installerEligibility: IMetaDataState;
  installerGcRequirements: IMetaDataState;
  installerInformation: IMetaDataState;
  installerOldestCertifications: IMetaDataState; //custom view
  installerNotes: IMetaDataState;
  installerScheduleExclusions: IMetaDataState;
  installerTechnicians: IMetaDataState;
  installerTechnicianCertifications: IMetaDataState;
  installerTechnicianGcRequirements: IMetaDataState;
  installerWorkCrews: IMetaDataState;
  purchaseOrderExternalData: IMetaDataState;

  managerDashboard: IMetaDataState;
  notifications: IMetaDataState;

  payouts: IMetaDataState;
  payoutPrices: IMetaDataState;
  payoutSkus: IMetaDataState;
  phones: IMetaDataState;
  plsCertifications: IMetaDataState;
  poImportGroupedHeaders: IMetaDataState;
  poImportGroupedErrors: IMetaDataState;
  poImportHeaders: IMetaDataState;
  poImportLines: IMetaDataState;

  privilegeTemplates: IMetaDataState;
  privilegeTemplateRoles: IMetaDataState;
  privilegeTemplatePrivileges: IMetaDataState;
  privilegeTemplatePrivilegeValues: IMetaDataState;
  privilegeRuleEntitySpecifiers: IMetaDataState;
  privilegeFilterWhereUsed: IMetaDataState;
  privileges: IMetaDataState;
  privilegeWhereUsed: IMetaDataState;

  programs: IMetaDataState;
  programServices: IMetaDataState;

  projectChargeBacks: IMetaDataState;
  projectChargeBacktems: IMetaDataState;
  projectCommunicationEvents: IMetaDataState;
  projectContactMechanism: IMetaDataState;
  projectEntityDocuments: IMetaDataState;
  projectDashboard: IMetaDataState;
  projectFiles: IMetaDataState;
  projectHistory: IMetaDataState;
  projectInformation: IMetaDataState;
  projectNotes: IMetaDataState;
  projectPurchaseOrders: IMetaDataState;
  projectPurchaseOrderItems: IMetaDataState;
  projectAQTChanges: IMetaDataState;
  projectWorkOrders: IMetaDataState;
  projectWorkOrderItems: IMetaDataState;
  projectUnreceivedInventory: IMetaDataState;

  providerLocations: IMetaDataState;
  providerPayouts: IMetaDataState;
  providerRoles: IMetaDataState;
  providerScheduleExclusions: IMetaDataState;
  providerLocationScheduleMoveups: IMetaDataState;
  psBranchPrograms: IMetaDataState;
  psFiles: IMetaDataState;
  psSkuPrices: IMetaDataState;

  purchaseOrderItems: IMetaDataState;
  purchaseOrderFiles: IMetaDataState;
  purchaseOrderMeasures: IMetaDataState;
  purchaseOrderNotes: IMetaDataState;

  regions: IMetaDataState;
  regionBranches: IMetaDataState;
  regionSkuPrices: IMetaDataState;
  roleWidgets: IMetaDataState;
  roleUsers: IMetaDataState;
  rolePrivilegeTemplates: IMetaDataState;

  savedReports: IMetaDataState;
  sentNotifications: IMetaDataState;
  serviceGroups: IMetaDataState;
  serviceGroupServices: IMetaDataState;
  servicePrograms: IMetaDataState;
  services: IMetaDataState;
  servicePls: IMetaDataState;
  skus: IMetaDataState;
  skuPayouts: IMetaDataState;
  skuPrices: IMetaDataState;
  schedules: IMetaDataState;
  scheduleMeasures: IMetaDataState;
  shippers: IMetaDataState;
  shipperLocations: IMetaDataState;
  shipperPayouts: IMetaDataState;
  shipperLocationPayouts: IMetaDataState;

  slotScheduleCalendarLockedDays: IMetaDataState;
  sssGeneralContractors: IMetaDataState;
  sssPrograms: IMetaDataState;
  sssProgramServices: IMetaDataState;
  sssPSBranches: IMetaDataState;
  sssPendingInvites: IMetaDataState;
  surveys: IMetaDataState;
  surveyImportMappings: IMetaDataState;
  surveyProgramServices: IMetaDataState;
  surveyProjectMapFields: IMetaDataState;
  surveyQuestions: IMetaDataState;
  surveyQuestionDataTypes: IMetaDataState;

  technicians: IMetaDataState; //all technicians
  technicianCertificationsByRange: IMetaDataState;
  technicianInformation: IMetaDataState;
  technicianRoles: IMetaDataState;

  unavailableWorkCrews: IMetaDataState;
  userJobAlerts: IMetaDataState;
  userJobs: IMetaDataState;
  userJobSchedules: IMetaDataState;
  userPendingChanges: IMetaDataState;
  userDelegateSchedules: IMetaDataState;
  userListFilters: IMetaDataState;
  userNotes: IMetaDataState;
  userPreferences: IMetaDataState;
  userSecurity: IMetaDataState;

  warrantyDashboard: IMetaDataState;
  warrantyInformation: IMetaDataState;
  warrantyTypes: IMetaDataState;
  warrantySubTypes: IMetaDataState;
  workCrewEligibility: IMetaDataState;
  workCrewMetrics: IMetaDataState;
  workCrewPlServices: IMetaDataState;
  workCrewScheduleExclusions: IMetaDataState;
  workCrewTechnicians: IMetaDataState;
  workCrewWorkCategories: IMetaDataState;
  workCrewZones: IMetaDataState;

  workOrderAQTChanges: IMetaDataState;
  workOrderCallLogResponses: IMetaDataState;
  workOrderInformation: IMetaDataState;
  workOrderItems: IMetaDataState;
  workOrderNotes: IMetaDataState;
  workOrderWarranties: IMetaDataState;
  workCategories: IMetaDataState;
  workCategoryPayouts: IMetaDataState;

  zones: IMetaDataState;

  xmlMappings: IMetaDataState;

}

//store names match store names in dynamic-list reducer (plural)
const initialState: IMetasState = {

  addresses: new MetaDataState('addresses'),
  availableWorkCrews: new MetaDataState('availableWorkCrews'),
  branches: new MetaDataState('branches'),
  branchNotes: new MetaDataState('branchNotes'),
  branchPrograms: new MetaDataState('branchPrograms'),
  branchProviderLocation: new MetaDataState('branchProviderLocation'),
  branchRegions: new MetaDataState('branchRegions'),
  branchSkuPrices: new MetaDataState('branchSkuPrices'),

  certifications: new MetaDataState('certifications'),
  chargebacks: new MetaDataState('chargebacks'),
  chargeBackItems: new MetaDataState('chargeBackItems'),
  chargeBackSkus: new MetaDataState('chargeBackSkus'),
  chargeBackSkuPrices: new MetaDataState('chargeBackSkuPrices'),
  chargeBackTypes: new MetaDataState('chargeBackTypes'),
  contactInformation: new MetaDataState('contactInformation'), //for object
  contactCustomerFlags: new MetaDataState('contactCustomerFlags'),
  contactMechanismCategories: new MetaDataState('contactMechanismCategories'),
  contactMechanismCategoryTypes: new MetaDataState('contactMechanismCategoryTypes'),
  contactNotes: new MetaDataState('contactNotes'),
  contactProjects: new MetaDataState('contactProjects'),
  contactProjectWOs: new MetaDataState('contactProjectWOs'),
  contactProjectPOs: new MetaDataState('contactProjectPOs'),
  contactTypes: new MetaDataState('contactTypes'),
  contactTypeSpecifications: new MetaDataState('contactTypeSpecifications'),
  communicationDocumentTypes: new MetaDataState('communicationDocumentTypes'),
  communicationEventActions: new MetaDataState('communicationEventActions'),
  communicationEventFeedbacks: new MetaDataState('communicationEventFeedbacks'),
  communicationEventTypes: new MetaDataState('communicationEventTypes'),
  communicationShortcuts: new MetaDataState('communicationShortcuts'),
  contactCommunicationPreference: new MetaDataState('contactCommunicationPreference'),
  customerFlags: new MetaDataState('customerFlags'),
  dashBranchMapping: new MetaDataState('dashBranchMapping'),
  dashCalendar: new MetaDataState('dashCalendar'),
  dashChargeBacks: new MetaDataState('dashChargeBacks'),
  dashUnAckChargeBacks: new MetaDataState('dashUnAckChargeBacks'),
  dashOnHoldChargeBacks: new MetaDataState('dashOnHoldChargeBacks'),
  dashAckUnSubmitChargeBacks: new MetaDataState('dashAckUnSubmitChargeBacks'),

  dashChargeBackInstallments: new MetaDataState('dashChargeBackInstallments'),
  dashCommunicationEventLogs: new MetaDataState('dashCommunicationEventLogs'),
  dashCustomers: new MetaDataState('dashCustomers'),
  dashCustomerProjects: new MetaDataState('dashCustomerProjects'),
  dashDispatchedWorkOrders: new MetaDataState('dashDispatchedWorkOrders'),
  dashEmailImports: new MetaDataState('dashEmailImports'),
  dashFileUploads: new MetaDataState('dashFileUploads'),
  dashInstallerVacations: new MetaDataState('dashInstallerVacations'),
  dashInventoryNeeds: new MetaDataState('dashInventoryNeeds'),
  dashInventoryWos: new MetaDataState('dashInventoryWos'),
  dashOpenExternalNotes: new MetaDataState('dashOpenExternalNotes'),
  dashOpenProjects: new MetaDataState('dashOpenProjects'),
  dashOpenWarranties: new MetaDataState('dashOpenWarranties'),
  dashOutstandingDocuments: new MetaDataState('dashOutstandingDocuments'),
  dashPendingApprovals: new MetaDataState('dashPendingApprovals'),
  dashPendingCloseProjects: new MetaDataState('dashPendingCloseProjects'),
  dashPendingTasks: new MetaDataState('dashPendingTasks'),
  dashProgamServiceMapping: new MetaDataState('dashProgamServiceMapping'),
  dashUnsentInstallments: new MetaDataState('dashUnsentInstallments'),
  dashScheduleMoveUps: new MetaDataState('dashScheduleMoveUps'),
  dashShipperPos: new MetaDataState('dashShipperPos'),
  dashShipperPoItems: new MetaDataState('dashShipperPoItems'),
  dashSkuMapping: new MetaDataState('dashSkuMapping'),
  dashSSSRequiringActions: new MetaDataState('dashSSSRequiringActions'),
  dashSSSOptionCounts: new MetaDataState('dashSSSOptionCounts'),
  dashSSSInvites: new MetaDataState('dashSSSInvites'),
  dashUnassignedExternalNotes: new MetaDataState('dashUnassignedExternalNotes'),
  dashUnassignedWarranties: new MetaDataState('dashUnassignedWarranties'),
  dashUnitMeasureMapping: new MetaDataState('dashUnitMeasureMapping'),
  dashUnreceivedInventory: new MetaDataState('dashUnreceivedInventory'),
  dashUnscheduledWorkOrders: new MetaDataState('dashUnscheduledWorkOrders'),
  dashUndispatchedWorkOrders: new MetaDataState('dashUndispatchedWorkOrders'),
  dashWorkOrderSurveys: new MetaDataState('dashWorkOrderSurveys'),
  dashWorkOrderSurveyResponses: new MetaDataState('dashWorkOrderSurveyResponses'),
  documentTypes: new MetaDataState('dashUndispatchedWorkOrders'),

  homHolidays: new MetaDataState('homHolidays'),

  locationBranches: new MetaDataState('locationBranches'),
  locationServices: new MetaDataState('locationServices'),
  lookupTypes: new MetaDataState('lookupTypes'),
  lookupValues: new MetaDataState('lookupValues'),

  emails: new MetaDataState('emails'),

  generalContractors: new MetaDataState('generalContractors'),
  generalContractorInstallerRequirements: new MetaDataState('generalContractorInstallerRequirements'),

  installers: new MetaDataState('installers'),
  installerCompatibilityPreferences: new MetaDataState('installerCompatibilityPreferences'),
  installerCertificationsByRange: new MetaDataState('installerCertificationsByRange'),
  installerCommunicationEvents: new MetaDataState('installerCommunicationEvents'),
  installerEligibility: new MetaDataState('installerEligibility '),
  installerGcRequirements: new MetaDataState('installerGcRequirements'),
  installerCertifications: new MetaDataState('installerCertifications'),
  installerInformation: new MetaDataState('installerInformation'),
  installerOldestCertifications: new MetaDataState('installerOldestCertifications'),
  installerNotes: new MetaDataState('installerNotes'),
  installerScheduleExclusions: new MetaDataState('installerScheduleExclusions'),
  installerTechnicianCertifications: new MetaDataState('installerTechnicianCertifications'),
  installerTechnicianGcRequirements: new MetaDataState('installerTechnicianGcRequirements'),
  installerTechnicians: new MetaDataState('installerTechnicians'),
  installerWorkCrews: new MetaDataState('installerWorkCrews'),

  jobScheduleJobParameters: new MetaDataState('jobScheduleJobParameters'),

  managerDashboard: new MetaDataState('managerDashboard'), //pending actions
  notifications: new MetaDataState('notifications'),

  payouts: new MetaDataState('payouts'),
  payoutPrices: new MetaDataState('payoutPrices'),
  payoutSkus: new MetaDataState('payoutSkus'),
  phones: new MetaDataState('phones'),
  plsCertifications: new MetaDataState('plsCertifications'),
  poImportGroupedHeaders: new MetaDataState('poImportGroupedHeaders'),
  poImportGroupedErrors: new MetaDataState('poImportGroupedErrors'),
  poImportHeaders: new MetaDataState('poImportHeaders'),
  poImportLines: new MetaDataState('poImportLines'),

  privilegeTemplates: new MetaDataState('privilegeTemplates'),
  privilegeTemplateRoles: new MetaDataState('privilegeTemplateRoles'),
  privilegeTemplatePrivileges: new MetaDataState('privilegeTemplatePrivileges'),
  privilegeTemplatePrivilegeValues: new MetaDataState('privilegeTemplatePrivilegeValues'),
  privilegeRuleEntitySpecifiers: new MetaDataState('privilegeRuleEntitySpecifiers'),
  privilegeFilterWhereUsed: new MetaDataState('privilegeFilterWhereUsed'),
  privileges: new MetaDataState('privileges'),
  privilegeWhereUsed: new MetaDataState('privilegeWhereUsed'),

  programs: new MetaDataState('programs'),
  programServices: new MetaDataState('programServices'),

  projectChargeBacks: new MetaDataState('projectChargeBacks'),
  projectChargeBacktems: new MetaDataState('projectChargeBacktems'),
  projectContactMechanism: new MetaDataState('projectContactMechanism'),
  projectEntityDocuments: new MetaDataState('projectEntityDocuments'),
  projectDashboard: new MetaDataState('projectDashboard'), //expert toolbox but labeled as Project Dashboard
  projectFiles: new MetaDataState('projectFiles'),
  projectInformation: new MetaDataState('projectInformation'),
  projectHistory: new MetaDataState('projectHistory'),
  projectNotes: new MetaDataState('projectNotes'),
  projectCommunicationEvents: new MetaDataState('projectCommunicationEvents'),
  projectPurchaseOrders: new MetaDataState('projectPurchaseOrders'),
  projectPurchaseOrderItems: new MetaDataState('projectPurchaseOrderItems'),
  projectAQTChanges: new MetaDataState('projectAQTChanges'),
  projectWorkOrders: new MetaDataState('projectWorkOrders'),
  projectWorkOrderItems: new MetaDataState('projectWorkOrderItems'),
  projectUnreceivedInventory: new MetaDataState('projectUnreceivedInventory'),

  providerLocations: new MetaDataState('providerLocations'),
  providerPayouts: new MetaDataState('providerPayouts'),
  providerRoles: new MetaDataState('providerRoles'),
  providerScheduleExclusions: new MetaDataState('providerScheduleExclusions'),
  providerLocationScheduleMoveups: new MetaDataState('providerLocationScheduleMoveups'),
  psBranchPrograms: new MetaDataState('psBranchPrograms'),
  psFiles: new MetaDataState('psFiles'),
  psSkuPrices: new MetaDataState('psSkuPrices'),

  purchaseOrderExternalData: new MetaDataState('purchaseOrderExternalData'),
  purchaseOrderItems: new MetaDataState('purchaseOrderItems'),
  purchaseOrderFiles: new MetaDataState('purchaseOrderFiles'),
  purchaseOrderMeasures: new MetaDataState('purchaseOrderMeasures'),
  purchaseOrderNotes: new MetaDataState('purchaseOrderNotes'),

  regions: new MetaDataState('regions'),
  regionBranches: new MetaDataState('regionBranches'),
  regionSkuPrices: new MetaDataState('regionSkuPrices'),

  roleWidgets: new MetaDataState('roleWidgets'),
  roleUsers: new MetaDataState('roleUsers'),
  rolePrivilegeTemplates: new MetaDataState('rolePrivilegeTemplates'),

  sentNotifications: new MetaDataState('sentNotifications'),
  serviceGroups: new MetaDataState('serviceGroups'),
  serviceGroupServices: new MetaDataState('serviceGroupServices'),
  servicePrograms: new MetaDataState('servicePrograms'),
  services: new MetaDataState('services'),
  servicePls: new MetaDataState('servicePls'),
  savedReports: new MetaDataState('savedReports'), //list of saved reports - report viewer
  skus: new MetaDataState('skus'),
  skuPayouts: new MetaDataState('skuPayouts'),
  skuPrices: new MetaDataState('skuPrices'),
  schedules: new MetaDataState('schedules'),
  scheduleMeasures: new MetaDataState('scheduleMeasures'),

  shippers: new MetaDataState('shippers'),
  shipperLocations: new MetaDataState('shipperLocations'),
  shipperPayouts: new MetaDataState('shipperPayouts'),
  shipperLocationPayouts: new MetaDataState('shipperLocationPayouts'),

  slotScheduleCalendarLockedDays: new MetaDataState('slotScheduleCalendarLockedDays'),
  sssGeneralContractors: new MetaDataState('sssGeneralContractors'),
  sssPrograms: new MetaDataState('sssPrograms'),
  sssProgramServices: new MetaDataState('sssProgramServices'),
  sssPSBranches: new MetaDataState('sssPSBranches'),
  sssPendingInvites: new MetaDataState('sssPendingInvites'), //sss service expert
  surveys: new MetaDataState('surveys'),
  surveyImportMappings: new MetaDataState('surveyImportMappings'),
  surveyProgramServices: new MetaDataState('surveyProgramServices'),
  surveyProjectMapFields: new MetaDataState('surveyProjectMapFields'),
  surveyQuestions: new MetaDataState('surveyQuestions'),
  surveyQuestionDataTypes: new MetaDataState('surveyQuestionDataTypes'),

  technicians: new MetaDataState('technicians'),
  technicianCertificationsByRange: new MetaDataState('technicianCertificationsByRange'),
  technicianInformation: new MetaDataState('technicianInformation'),
  technicianRoles: new MetaDataState('technicianRoles'),

  unavailableWorkCrews: new MetaDataState('unavailableWorkCrews'),
  userDelegateSchedules: new MetaDataState('userDelegateSchedules'),
  userJobAlerts: new MetaDataState('userJobAlerts'),
  userJobs: new MetaDataState('userJobs'),
  userJobSchedules: new MetaDataState('userJobSchedules'),
  userListFilters: new MetaDataState('userListFilters'),
  userNotes: new MetaDataState('userNotes'),
  userPendingChanges: new MetaDataState('userPendingChanges'),
  userPreferences: new MetaDataState('userPreferences'),
  userSecurity: new MetaDataState('userSecurity'),

  warrantyDashboard: new MetaDataState('warrantyDashboard'),
  warrantyInformation: new MetaDataState('warrantyInformation'),
  warrantyTypes: new MetaDataState('warrantyTypes'),
  warrantySubTypes: new MetaDataState('warrantySubTypes'),

  workCategoryPayouts: new MetaDataState('workCategoryPayouts'),
  workCrewEligibility: new MetaDataState('workCrewEligibility'),
  workCrewMetrics: new MetaDataState('workCrewMetrics'),
  workCrewPlServices: new MetaDataState('workCrewPlServices'),
  workCrewScheduleExclusions: new MetaDataState('workCrewScheduleExclusions'),
  workCrewTechnicians: new MetaDataState('workCrewTechnicians'),
  workCrewWorkCategories: new MetaDataState('workCrewWorkCategories'),
  workCrewZones: new MetaDataState('workCrewZones'),

  workOrderAQTChanges: new MetaDataState('workOrderAQTChanges'),
  workOrderCallLogResponses: new MetaDataState('workOrderCallLogResponses'),
  workOrderInformation: new MetaDataState('workOrderInformation'),
  workOrderItems: new MetaDataState('workOrderItems'),
  workOrderNotes: new MetaDataState('workOrderNotes'),
  workOrderWarranties: new MetaDataState('workOrderWarranties'),
  workCategories: new MetaDataState('workCategories'),

  zones: new MetaDataState('zones'),

  xmlMappings: new MetaDataState('xmlMappings')
};

export function metaDataReducer<T>(state = initialState, action: MetaDataActions.MetaDataActions): IMetasState {
    switch (action.type) {
     
      case MetaDataActions.SET_FIELD_DEFINITIONS:
      {
        // Update the object, setting the field definitions property
        const storeName = action.payload.storeName;

        const updatedData = {
          storeName: action.payload.storeName,
          fieldDefinitions: action.payload.fieldDefinitions,
          seedData: action.payload.seedData
        }
        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

      case MetaDataActions.INITIALIZE_META_DATA:
      {
          const storeName = action.payload.storeName;
          if (!state.hasOwnProperty(storeName)) {
            return state;
          }

          const updatedData = {
            storeName: action.payload.storeName,
            fieldDefinitions: [],
            seedData: {}
          }

          return {
            ...state,
            [`${storeName}`]: { ...state[storeName], ...updatedData }
          }
        }

      case MetaDataActions.RESET_ALL_META_STORES:
        {
          return initialState
        }


  //end default return value
    default:
      return state;
  }
}


