import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

import {
  IListColumn, ListColumn,
  IListFilter, IListButtonType, IListDefinition,
  ListFilter,  OrderTerm
} from '../../../../fw/dynamic-list/interfaces';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { SkuMaintEvent, AdminStore, AdminEvent } from '../enums/admin.enums';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { UserPriviledgesService } from '../../../../auth/services';
import { DynamicListMethod } from '../../../../fw/dynamic-list/enums/dynamic-list.enum';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AdminProviderService {
  isDeleteEnabled: boolean = false;

  constructor(public datePipe: DatePipe,
    public ups: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) {
    this.isDeleteEnabled = environment.adminDeleteEnabled
  }

  openRelatedEntityManager(listDefinition: IListDefinition, parentName: string, parentId: number): void {
    const params = {
      rowIndex: -1,
      storeName: listDefinition.storeName,
      key: listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }

    this.modalService.open({
      title: 'Add '.concat(listDefinition.objectLabel, 's to ', parentName),
      path: 'related-entity-manager',
      id: parentId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
      isSubModal: true
    });
  }

  public loadCommunicationShortcutsListDefinition(): IListDefinition {
    const listColumns = this.loadCommunicationShortcutsListColumns();
    const listObjectLabel = 'Communication Shortcut';
    const listObjectController = 'CommunicationShortcut';
    const listStoreName = AdminStore.communicationShortcuts;
    const detailRoutePath = 'communication-shortcut';
    const listRowKeyId = 'communicationShortcutId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [new OrderTerm('communicationShortcutName')];

    let listDefinition = this.dynamicListService.createListDefinition('communicationShortcutOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadCommunicationShortcutsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('communicationShortcutCategory');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('contactType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationShortcutName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationShortcutShortcut');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationShortcutTemplate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadCommunicationDocumentTypesListDefinition(): IListDefinition {
    const listColumns = this.loadCommunicationDocumentTypesListColumns();
    const listObjectLabel = 'Communication Document Type';
    const listObjectController = 'CommunicationDocumentType';
    const listStoreName = AdminStore.communicationDocumentTypes;
    const detailRoutePath = 'communication-document-type';
    const listRowKeyId = 'communicationDocumentTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [new OrderTerm('sortOrder', true)];

    let listDefinition = this.dynamicListService.createListDefinition('communicationDocumentTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);


    return listDefinition;
  }

  loadCommunicationDocumentTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('communicationShortcutCategory');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('contactType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('documentTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('documentTypeDescription');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('requiresProject');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('requiresWorkOrder');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sortOrder');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadProviderScheduleExclusionsListDefinition(): IListDefinition {
    const listColumns = this.loadProviderScheduleExclusionsListColumns();
    const listObjectLabel = 'Schedule Exclusion';
    const listObjectController = 'SlotScheduleExclusion';
    const listStoreName = AdminStore.providerScheduleExclusions;
    const detailRoutePath = 'admin-schedule-exclusions-create';
    const listRowKeyId = 'slotScheduleExclusionId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{ term: 'day', columnName: 'day', searchType: SearchType.GreaterThanEqual, value: this.datePipe.transform(new Date(new Date().getFullYear(), 0, 1), 'MM/dd/yyyy'), fieldType: this.myConstants.dataTypeDate }];
    defaultListFilter.orderTerm = [new OrderTerm('day', true)];

    let listDefinition = this.dynamicListService.createListDefinition('adminScheduleExclusionsCreateOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProvider';

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadProviderScheduleExclusionsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('day');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadZonesListDefinition(): IListDefinition {
    const listColumns = this.loadZonesListColumns();
    const listObjectLabel = 'Zone';
    const listObjectController = 'Zone';
    const listStoreName = AdminStore.zones;
    const detailRoutePath = 'zone-detail';
    const listRowKeyId = 'zoneId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('zoneName')];

    let listDefinition = this.dynamicListService.createListDefinition('zonesOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProvider';

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadZonesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('zoneName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('cities');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('zoneZipCodes');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadCustomerFlagsListDefinition(): IListDefinition {
    const listColumns = this.loadCustomerFlagsListColumns();
    const listObjectLabel = 'Customer Flag';
    const listObjectController = 'CustomerFlag';
    const listStoreName = AdminStore.customerFlags;
    const detailRoutePath = 'customer-flag-detail';
    const listRowKeyId = 'customerFlagId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('customerFlagName')];

    let listDefinition = this.dynamicListService.createListDefinition('customerFlagOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadCustomerFlagsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('customerFlagName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerFlagDescription');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerFlagColor');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadLookupTypesListDefinition(): IListDefinition {
    const listColumns = this.loadLookupTypesListColumns();
    const listObjectLabel = 'Lookup Type';
    const listObjectController = 'LookupType';
    const listStoreName = AdminStore.lookupTypes;
    const detailRoutePath = 'lookup-type-detail';
    const listRowKeyId = 'lookupTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('lookupTypeName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('lookupTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadLookupTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('lookupTypeName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('lookupTypeDescription');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locked');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadLookupValuesListDefinition(lookupTypeId: number, canEdit: boolean): IListDefinition {
    const listColumns = this.loadLookupValuesListColumns();
    const listObjectLabel = 'Lookup Value';
    const listObjectController = 'LookupValue';
    const listStoreName = AdminStore.lookupValues;
    const detailRoutePath = 'lookup-value-detail';
    const listRowKeyId = 'lookupValueId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('lookupCode', true)];

    let listDefinition = this.dynamicListService.createListDefinition('lookupValueOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByLookupType';
    listDefinition.methodParameters = lookupTypeId.toString();
    listDefinition.showFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    const enabledDefaults: ICustomButton = {
      title: '', icon: '', cssName: '', enabled: canEdit,
      enabledMethod: DynamicListMethod.alwaysEnabled, eventName: ''
    };

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: enabledDefaults },
      { type: ButtonType.delete, defaults: enabledDefaults }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadLookupValuesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('lookupCode');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('lookupMeaning');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadWorkCategoriesListDefinition(): IListDefinition {
    const listColumns = this.loadWorkCategoriesListColumns();
    const listObjectLabel = 'Work Category';
    const listObjectController = 'WorkCategory';
    const listStoreName = AdminStore.workCategories;
    const detailRoutePath = 'work-category-detail';
    const listRowKeyId = 'workCategoryId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('workCategoryName')];

    let listDefinition = this.dynamicListService.createListDefinition('workCategoryOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Work Category Payouts',
        icon: 'far fa-sack-dollar',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showWorkCategoryPayouts
      }
    );

    return listDefinition;
  }

  loadWorkCategoriesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workCategoryName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workCategoryDescription');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    
    colDef = new ListColumn('unitMeasure');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    

    colDef = new ListColumn('minimumPercentMetForSelfService');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('determinesJobLength');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadWorkCategoryPayoutsListDefinition(workCategoryId: number): IListDefinition {
    const listColumns = this.loadWorkCategoryPayoutsListColumns();
    const listObjectLabel = 'Work Category Payout';
    const listObjectController = 'WorkCategoryPayout';
    const listStoreName = AdminStore.workCategoryPayouts;
    const detailRoutePath = 'work-category-payout-detail';
    const listRowKeyId = 'workCategoryPayoutId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('payoutName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('workCategoryPayoutOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByWorkCategory';
    listDefinition.methodParameters = workCategoryId.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadWorkCategoryPayoutsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('payoutName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

   //Fake list definition. UI does not render list, just used to load data, only one record.
  loadInstallerCompatibilityPreferenceListDefinition(): IListDefinition {
    const listColumns = this.loadInstallerCompatibilityPreferenceListColumns();
    const listObjectLabel = 'Installer Scoring';
    const listObjectController = 'InstallerCompatibilityPreference';
    const listStoreName = AdminStore.installerCompatibilityPreferences;
    const detailRoutePath = '';
    const listRowKeyId = 'installerCompatibilityPreferenceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = false;

    return listDefinition;
  }

  //Fake list definition, only one record.
  loadInstallerCompatibilityPreferenceListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workCategoryCompatibilityWeight');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadChargeBackTypesListDefinition(): IListDefinition {
    const listColumns = this.loadChargeBackTypesListColumns();
    const listObjectLabel = 'Charge Back Type';
    const listObjectController = 'ChargeBackType';
    const listStoreName = AdminStore.chargeBackTypes;
    const detailRoutePath = 'charge-back-type';
    const listRowKeyId = 'chargeBackTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('chargeBackTypeName')];

    let listDefinition = this.dynamicListService.createListDefinition('chargeBackTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadChargeBackTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('chargeBackTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('glAccountPrefix');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadCommunicationEventTypesListDefinition(): IListDefinition {
    const listColumns = this.loadCommunicationEventTypesListColumns();
    const listObjectLabel = 'Communication Event Type';
    const listObjectController = 'CommunicationEventType';
    const listStoreName = AdminStore.communicationEventTypes;
    const detailRoutePath = 'comm-event-type';
    const listRowKeyId = 'communicationEventTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('eventTypeName')];

    let listDefinition = this.dynamicListService.createListDefinition('commEventTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadCommunicationEventTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('eventTypeName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadCommunicationEventFeedbacksListDefinition(): IListDefinition {
    const listColumns = this.loadCommunicationEventFeedbacksListColumns();
    const listObjectLabel = 'Communication Event Feedback';
    const listObjectController = 'CommunicationEventFeedback';
    const listStoreName = AdminStore.communicationEventFeedbacks;
    const detailRoutePath = 'comm-event-feedback';
    const listRowKeyId = 'communicationEventFeedbackId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('communicationEventType_eventTypeName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('commEventFeedbackOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadCommunicationEventFeedbacksListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('communicationEventType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationEventFeedbackText');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadContactMechanismCategoriesListDefinition(): IListDefinition {
    const listColumns = this.loadContactMechanismCategoriesListColumns();
    const listObjectLabel = 'Contact Mechanism Category';
    const listObjectController = 'ContactMechanismCategory';
    const listStoreName = AdminStore.contactMechanismCategories;
    const detailRoutePath = 'contact-mech-category';
    const listRowKeyId = 'contactMechanismCategoryId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('contactMechanismCategoryName')];

    let listDefinition = this.dynamicListService.createListDefinition('cmcOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Category Types',
        icon: 'fad fa-tags',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.manageContactMechanismCategoryTypes,
        enabled: true
      }
    );
    return listDefinition;
  }

  loadContactMechanismCategoriesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('contactMechanismCategoryName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadContactMechanismCategoryTypesListDefinition(contactMechanismCategoryId: number): IListDefinition {
    const listColumns = this.loadContactMechanismCategoryTypesListColumns();
    const listObjectLabel = 'Category Type';
    const listObjectController = 'ContactMechanismCategoryType';
    const listStoreName = AdminStore.contactMechanismCategoryTypes;
    const detailRoutePath = 'contact-mech-category-type';
    const listRowKeyId = 'contactMechanismCategoryTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('contactMechanismCategoryTypeName')];

    let listDefinition = this.dynamicListService.createListDefinition('cmctOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = contactMechanismCategoryId;
    listDefinition.parentKey = 'contactMechanismCategoryId';
    listDefinition.controllerMethod = 'ByContactMechanismCategory';
    listDefinition.methodParameters = contactMechanismCategoryId.toString();
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadContactMechanismCategoryTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('contactMechanismCategoryTypeName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadContactTypesListDefinition(): IListDefinition {
    const listColumns = this.loadContactTypesListColumns();
    const listObjectLabel = 'Contact Type';
    const listObjectController = 'ContactType';
    const listStoreName = AdminStore.contactTypes;
    const detailRoutePath = 'contact-type';
    const listRowKeyId = 'contactTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('contactTypeName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('contactTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Type Specifications',
        icon: 'fad fa-tags',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.manageContactTypeSpecifications,
        enabled: true
      }
    );
    return listDefinition;
  }

  loadContactTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('contactTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('entity');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadContactTypeSpecificationsListDefinition(contactTypeId: number): IListDefinition {
    const listColumns = this.loadContactTypeSpecificationsListColumns();
    const listObjectLabel = 'Type Specification';
    const listObjectController = 'ContactTypeSpecification';
    const listStoreName = AdminStore.contactTypeSpecifications;
    const detailRoutePath = 'contact-type-spec';
    const listRowKeyId = 'contactTypeSpecificationId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('typeSpecOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = contactTypeId;
    listDefinition.parentKey = 'contactTypeId';
    listDefinition.controllerMethod = 'ByContactType';
    listDefinition.methodParameters = contactTypeId.toString();
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadContactTypeSpecificationsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('contactMechanismCategoryName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('contactMechanismCategoryType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('minimum');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('maximum');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadServiceGroupsListDefinition(): IListDefinition {
    const listColumns = this.loadServiceGroupsListColumns();
    const listObjectLabel = 'Service Group';
    const listObjectController = 'ServiceGroup';
    const listStoreName = AdminStore.serviceGroups;
    const detailRoutePath = 'service-group-detail';
    const listRowKeyId = 'serviceGroupId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('serviceGroupName')];
    defaultListFilter.accessLevel = 'FullAccess';

    let listDefinition = this.dynamicListService.createListDefinition('serviceGroupOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Service Group Creation Wizard',
        icon: 'fad fa-wand-magic',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.launchServiceGroupsWizard
      }
    );

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Service Group Services',
        icon: 'fad fa-server',
        cssName: 'app-btn-icon--custom-1',
        enabled: true,
        eventName: AdminEvent.showServiceGroupServices
      }
    );

    return listDefinition; 
  } 

  loadServiceGroupsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('serviceGroupName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceGroupDescription');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadServiceGroupServicesListDefinition(id: number): IListDefinition {
    const listColumns = this.loadServiceGroupServicesListColumns();
    const listObjectLabel = 'Service Group Service';
    const listObjectController = 'ServiceGroupService';
    const listStoreName = AdminStore.serviceGroupServices;
    const detailRoutePath = 'service-group-service-create';
    const listRowKeyId = 'serviceGroupServiceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.orderTerm = [new OrderTerm('service')];

    let listDefinition = this.dynamicListService.createListDefinition('serviceGroupServiceOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = id;
    listDefinition.parentKey = 'serviceGroup_serviceGroupId';
    listDefinition.controllerMethod = 'ByServiceGroup';
    listDefinition.methodParameters = id.toString();
    
    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{
      type: ButtonType.create, defaults: {
        title: '',
        icon: '',
        cssName: '',
        enabled: true,
        eventName: AdminEvent.launchServiceGroupsWizard
      }
    }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);
    

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadServiceGroupServicesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('service');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadGeneralContractorsListDefinition(): IListDefinition {
    const listColumns = this.loadGeneralContractorsListColumns();
    const listObjectLabel = 'General Contractor';
    const listObjectController = 'GeneralContractor';
    const listStoreName = AdminStore.generalContractors;
    const detailRoutePath = 'general-contractor';
    const listRowKeyId = 'generalContractorId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('generalContractorName')];

    let listDefinition = this.dynamicListService.createListDefinition('gcOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
    ];
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Branches',
        icon: 'fad fa-project-diagram',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.manageGCBranches,
        enabled: true
      },
      {
        title: 'Manage Associated Programs',
        icon: 'fad fa-folder-tree',
        cssName: 'app-btn-icon--custom-2',
        eventName: AdminEvent.manageGCPrograms,
        enabled: true
      },
      {
        title: 'Manage Associated Regions',
        icon: 'fad fa-map-signs',
        cssName: 'app-btn-icon--custom-3',
        eventName: AdminEvent.manageGCRegions,
        enabled: true
      }
    );
    return listDefinition;
  }

  loadGeneralContractorsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('generalContractorName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('lienWaiver');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('providerMarginLow');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('providerMarginHigh');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadBranchesListDefinition(generalContractorId: number = 0): IListDefinition {
    const listColumns = this.loadBranchesListColumns(generalContractorId);
    const listObjectLabel = 'Branch';
    const listObjectController = 'Branch';
    const listStoreName = AdminStore.branches;
    const detailRoutePath = generalContractorId > 0 ? '' : 'branch';
    const listRowKeyId = 'branchId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('branchName')];

    let listDefinition = this.dynamicListService.createListDefinition(
      generalContractorId > 0 ? '' : 'branchOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = generalContractorId > 0 ? generalContractorId : -1;
    listDefinition.parentKey = generalContractorId > 0 ? 'generalContractor_generalContractorId' : '';
    listDefinition.controllerMethod = generalContractorId > 0 ? 'ByGeneralContractor' : 'ByProvider';
    listDefinition.methodParameters = generalContractorId > 0 ? generalContractorId.toString() : this.ups.providerId$.value.toString();
    listDefinition.showFilter = generalContractorId === 0;
    listDefinition.allowSaveFilter = generalContractorId === 0;

    if (generalContractorId <= 0) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    let crudButtons: IListButtonType[] = [];
    if (generalContractorId <= 0) {
      crudButtons.push(
        { type: ButtonType.detail, defaults: null },
        { type: ButtonType.edit, defaults: null }
      );
      if (this.isDeleteEnabled) {
        crudButtons.push({ type: ButtonType.delete, defaults: null });
      }
    }

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    if (generalContractorId <= 0) {
      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'Manage Branch Contact Information',
          icon: 'fad fa-address-card',
          cssName: 'app-btn-icon--custom-1',
          eventName: AdminEvent.manageBranchContact,
          enabled: true
        },
        {
          title: 'Manage Associated Program Services',
          icon: 'fad fa-folder-tree',
          cssName: 'app-btn-icon--custom-2',
          eventName: AdminEvent.manageBranchPrograms,
          enabled: true
        },
        {
          title: 'Manage Associated Provider Locations',
          icon: 'fad fa-location',
          cssName: 'app-btn-icon--custom-6',
          eventName: AdminEvent.manageBranchPLs,
          enabled: true
        },
        {
          title: 'Manage Associated Regions',
          icon: 'fad fa-map-signs',
          cssName: 'app-btn-icon--custom-3',
          eventName: AdminEvent.manageBranchRegions,
          enabled: true
        },
        {
          title: 'Manage Associated Notes',
          icon: 'fad fa-sticky-note',
          cssName: 'app-btn-icon--custom-5',
          eventName: AdminEvent.manageBranchNotes,
          enabled: true
        }
      );
    }
    return listDefinition;
  }

  loadBranchesListColumns(generalContractorId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('branchName');
    columnDefinitions.push(colDef);

    if (generalContractorId <= 0) {
      colDef = new ListColumn('generalContractor');
      columnDefinitions.push(colDef);
    }
    colDef = new ListColumn('accountingCustomerMapId');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProgramsListDefinition(generalContractorId: number): IListDefinition {
    const listColumns = this.loadProgramsListColumns(generalContractorId);
    const listObjectLabel = 'Program';
    const listObjectController = 'Program';
    const listStoreName = AdminStore.programs;
    const detailRoutePath = generalContractorId > 0 ? '' : 'program';
    const listRowKeyId = 'programId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('programName')];

    let listDefinition = this.dynamicListService.createListDefinition(
      generalContractorId > 0 ? '' : 'pgmOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = generalContractorId > 0 ? generalContractorId : -1;
    listDefinition.parentKey = generalContractorId > 0 ? 'generalContractor_generalContractorId' : '';
    listDefinition.controllerMethod = generalContractorId > 0 ? 'ByGeneralContractor' : 'ByProvider';
    listDefinition.methodParameters = generalContractorId > 0 ? generalContractorId.toString() : this.ups.providerId$.value.toString();
    listDefinition.showFilter = generalContractorId === 0;
    listDefinition.allowSaveFilter = generalContractorId === 0;

    if (generalContractorId <= 0) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    let crudButtons: IListButtonType[] = [];
    if (generalContractorId <= 0) {
      crudButtons.push(
        { type: ButtonType.detail, defaults: null },
        { type: ButtonType.edit, defaults: null });
    }
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }
   listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    if (generalContractorId <= 0) {
      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'View Associated Services',
          icon: 'fad fa-server',
          cssName: 'app-btn-icon--custom-1',
          eventName: AdminEvent.manageProgramServices,
          enabled: true
        }
      );
    }
    return listDefinition;
  }

  loadProgramsListColumns(generalContractorId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('programName');
    columnDefinitions.push(colDef);

    if (generalContractorId <= 0) {
      colDef = new ListColumn('generalContractor');
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('programDescription');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programPrecedence');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProgramServicesListDefinition(programId: number = 0, serviceId: number = 0): IListDefinition {
    const listColumns = this.loadProgramServicesListColumns(programId, serviceId);
    const listObjectLabel = programId > 0 ? 'Service' : serviceId > 0 ? 'Program' : 'Program Service';
    const listObjectController = 'ProgramService';
    const listStoreName = serviceId > 0 ? AdminStore.servicePrograms : AdminStore.programServices;
    const detailRoutePath = programId > 0 || serviceId > 0 ? '' : 'program-service';
    const listRowKeyId = 'programServiceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = false;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = programId > 0 ? [new OrderTerm('service_serviceName')]
      : serviceId > 0 ? [new OrderTerm('program_programName')]
        : [new OrderTerm('program_programName'), new OrderTerm('service_serviceName')];

    let listDefinition = this.dynamicListService.createListDefinition(programId > 0 || serviceId > 0
      ? '' : 'psOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = programId > 0 ? 'ByProgram'
      : serviceId > 0 ? 'ByService'
        : 'ByProvider';
    listDefinition.methodParameters = programId > 0 ? programId.toString()
      : serviceId > 0 ? serviceId.toString()
        : this.ups.providerId$.value.toString();
    listDefinition.showFilter = programId === 0 && serviceId == 0;
    listDefinition.allowSaveFilter = programId === 0 && serviceId == 0;

    let crudButtons: IListButtonType[] = [];
    if (programId === 0 && serviceId === 0) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);

      crudButtons.push(
        { type: ButtonType.detail, defaults: null },
        { type: ButtonType.edit, defaults: null }
      );
      if (this.isDeleteEnabled) {
        crudButtons.push({ type: ButtonType.delete, defaults: null });
      }
    }

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    if (programId === 0 && serviceId == 0) {
      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'Manage Associated Programs',
          icon: 'fad fa-project-diagram',
          cssName: 'app-btn-icon--custom-1',
          eventName: AdminEvent.managePSBranchPrograms,
          enabled: true
        },
        {
          title: 'Manage Associated Document Types',
          icon: 'fad fa-archive fa-swap-opacity', //fa-copy
          cssName: 'app-btn-icon--custom-6',
          eventName: AdminEvent.managePSFiles,
          enabled: true
        }

      );
    }
    return listDefinition;
  }

  loadProgramServicesListColumns(programId: number, serviceId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;
    const noChildId: boolean = programId === 0 && serviceId === 0 ? true : false;

    if (noChildId || serviceId > 0) {
      colDef = new ListColumn('program');
      columnDefinitions.push(colDef);
    }

    if (noChildId || programId > 0) {
      colDef = new ListColumn('service');
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('generalContractorName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('lienWaiver');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowScheduleBeforeInventory');
    columnDefinitions.push(colDef);

    if (noChildId) {
      colDef = new ListColumn('canRequirePermit');
      columnDefinitions.push(colDef);
    }

    return columnDefinitions;
  }

  loadProviderLocationsListDefinition(): IListDefinition {
    const listColumns = this.loadProviderLocationListColumns();
    const listObjectLabel = 'Provider Location';
    const listObjectController = 'ProviderLocation';
    const listStoreName = AdminStore.providerLocations;
    const detailRoutePath = 'provider-location';
    const listRowKeyId = 'providerLocationId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('locationName')];

    let listDefinition = this.dynamicListService.createListDefinition('providerLocationOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId =  -1;
    listDefinition.parentKey =  '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters =  this.ups.providerId$.value.toString();
    listDefinition.showFilter = true;
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
    ];
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Location Contact Information',
        icon: 'fad fa-address-card',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.manageLocationContact,
        enabled: true
      },
      {
        title: 'Manage Location Hours',
        icon: 'fad fa-clock',
        cssName: 'app-btn-icon--custom-2',
        eventName: AdminEvent.manageLocationHours,
        enabled: true
      },
      {
        title: 'Manage Location Services',
        icon: 'fad fa-server',
        cssName: 'app-btn-icon--custom-3',
        eventName: AdminEvent.manageLocationServices,
        enabled: true
      },
      {
        title: 'Manage Location Branches',
        icon: 'fad fa-project-diagram',
        cssName: 'app-btn-icon--custom-4',
        eventName: AdminEvent.manageLocationBranches,
        enabled: true
      }
    );
    return listDefinition;
  }

  loadProviderLocationListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('locationName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isPrimary');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('accountingInterface');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantyBranch');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

 loadRegionsListDefinition(generalContractorId: number = 0): IListDefinition {
    const listColumns = this.loadRegionsListColumns(generalContractorId);
    const listObjectLabel = 'Region';
    const listObjectController = 'Region';
    const listStoreName = AdminStore.regions;
    const detailRoutePath = generalContractorId > 0 ? '' : 'region';
    const listRowKeyId = 'regionId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('regionName')];

    let listDefinition = this.dynamicListService.createListDefinition(
      generalContractorId > 0 ? '' : 'regionOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = generalContractorId > 0 ? generalContractorId : -1;
    listDefinition.parentKey = generalContractorId > 0 ? 'generalContractor_generalContractorId' : '';
    listDefinition.controllerMethod = generalContractorId > 0 ? 'ByGeneralContractor' : 'ByProvider';
    listDefinition.methodParameters = generalContractorId > 0 ? generalContractorId.toString() : this.ups.providerId$.value.toString();
    listDefinition.showFilter = generalContractorId === 0;
    listDefinition.allowSaveFilter = generalContractorId === 0;

    if (generalContractorId <= 0) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    let crudButtons: IListButtonType[] = [];
    if (generalContractorId <= 0) {
      crudButtons.push(
        { type: ButtonType.detail, defaults: null },
        { type: ButtonType.edit, defaults: null });
    }
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    if (generalContractorId <= 0) {
      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'Manage Associated Branches',
          icon: 'fad fa-project-diagram',
          cssName: 'app-btn-icon--custom-1',
          eventName: AdminEvent.manageRegionBranches,
          enabled: true
        }
      );
    }
    return listDefinition;
  }

  loadRegionsListColumns(generalContractorId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('regionName');
    columnDefinitions.push(colDef);

    if (generalContractorId <= 0) {
      colDef = new ListColumn('generalContractor');
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('regionDescription');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadServicesListDefinition(): IListDefinition {
    const listColumns = this.loadServicesListColumns();
    const listObjectLabel = 'Service';
    const listObjectController = 'Service';
    const listStoreName = AdminStore.services;
    const detailRoutePath = 'service';
    const listRowKeyId = 'serviceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('serviceName')];

    let listDefinition = this.dynamicListService.createListDefinition('svcOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null }
    ];
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Programs',
        icon: 'fad fa-folder-tree',
        cssName: 'app-btn-icon--custom-2',
        eventName: AdminEvent.manageServicePrograms,
        enabled: true
      },
      {
        title: 'Manage Associated Locations',
        icon: 'fad fa-location',
        cssName: 'app-btn-icon--custom-6',
        eventName: AdminEvent.manageServicePLs,
        enabled: true
      }
    );
    return listDefinition;
  }

  loadServicesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('serviceName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceDescription');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitMeasure');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('servicePrecedence');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('capacityMultiplier');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadBranchProgramsListDefinition(branchId: number,  programServiceId: number): IListDefinition {
    const listColumns = this.loadBranchProgramsListColumns(branchId,  programServiceId);
    const listObjectLabel = 'Branch Program';
    const listObjectController = 'BranchProgram';
    const listStoreName = branchId > 0 ? AdminStore.branchPrograms : AdminStore.psBranchPrograms;
    const detailRoutePath = '';
    const listRowKeyId = 'branchProgramId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = branchId > 0 ? [new OrderTerm('programService_entityLabel')]
      : programServiceId > 0 ? [new OrderTerm('branch_branchName')]
        : [];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = branchId > 0 ? branchId : programServiceId;
    listDefinition.parentKey = branchId > 0 ? 'branch_branchId' :  'programService_programServiceId';
    listDefinition.controllerMethod = branchId > 0 ? 'ByBranch' :  'ByProgramService';
    listDefinition.methodParameters = branchId > 0 ? branchId.toString() : programServiceId.toString();
    listDefinition.showFilter = branchId === 0 && programServiceId == 0;
    listDefinition.allowSaveFilter = branchId === 0 && programServiceId == 0;

    listDefinition.toolButtons.splice(0, 0,
      {
        title: branchId > 0 ? 'Add New Program to Branch' : 'Add New Branch to Program',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [];
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadBranchProgramsListColumns(branchId: number, programServiceId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn(branchId > 0 ? 'programService' : 'branch');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('defaultCostCenter');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }
  
  loadBranchRegionsListDefinition(regionId: number, branchId: number): IListDefinition {
    const listColumns = this.loadBranchRegionsListColumns(regionId, branchId);
    const listObjectLabel = 'Branch Region';
    const listObjectController = 'BranchRegion';
    const listStoreName = regionId > 0 ? AdminStore.regionBranches : AdminStore.branchRegions;
    const detailRoutePath = regionId > 0 ? 'region-branch' : 'branch-region';
    const listRowKeyId = 'branchRegionId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = regionId > 0 ? [new OrderTerm('branch_branchName')] : [new OrderTerm('region_regionName')];

    let listDefinition = this.dynamicListService.createListDefinition('rbOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = regionId > 0 ? regionId : branchId;
    listDefinition.parentKey = regionId > 0 ? 'region_regionId' : 'branch_branchId';;
    listDefinition.controllerMethod = regionId > 0 ? 'ByRegion' : 'ByBranch';
    listDefinition.methodParameters = regionId > 0 ? regionId.toString() : branchId.toString();
    listDefinition.showFilter = branchId === 0 && regionId == 0;
    listDefinition.allowSaveFilter = branchId === 0 && regionId == 0;

    listDefinition.toolButtons.splice(0, 0,
      {
        title: branchId > 0 ? 'Add New Region to Branch' : 'Add New Branch to Region',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [ ];
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadBranchRegionsListColumns(regionId: number, branchId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn(regionId > 0 ? 'branch' : 'region');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProviderLocationBranchesListDefinition(branchId: number, providerLocationId: number): IListDefinition {
    const listColumns = this.loadProviderLocationBranchesListColumns(branchId, providerLocationId);
    const listObjectLabel = 'Provider Location Branch';
    const listObjectController = 'ProviderLocationBranch';
    const listStoreName = branchId > 0 ? AdminStore.branchProviderLocation : AdminStore.locationBranches;
    const detailRoutePath = '';
    const listRowKeyId = 'providerLocationBranchId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = branchId > 0 ? [new OrderTerm('providerLocation_locationName')]
      : branchId > 0 ? [new OrderTerm('branch_branchId')]
        : [];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = branchId > 0 ? branchId : providerLocationId;
    listDefinition.parentKey = branchId > 0 ? 'branch_branchId' : 'providerLocation_providerLocationId';
    listDefinition.controllerMethod = branchId > 0 ? 'ByBranch' : 'ByProviderLocation';
    listDefinition.methodParameters = branchId > 0 ? branchId.toString() : providerLocationId.toString();
    listDefinition.showFilter = branchId === 0 && providerLocationId == 0;
    listDefinition.allowSaveFilter = branchId === 0 && providerLocationId == 0;

    listDefinition.toolButtons.splice(0, 0,
      {
        title: branchId > 0 ? 'Add New Provider Location to Branch' : 'Add New Branch to Provider Location',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [];
    crudButtons.push({ type: ButtonType.delete, defaults: null });

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    return listDefinition;
  }

  loadProviderLocationBranchesListColumns(branchId: number, providerLocationId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn(branchId > 0 ? 'providerLocation' : 'branch');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProgramServiceFilesListDefinition(programServiceId: number): IListDefinition {
    const listColumns = this.loadProgramServiceFilesListColumns();
    const listObjectLabel = 'Document Type';
    const listObjectController = 'ProgramServiceDocumentType';
    const listStoreName = AdminStore.psFiles;
    const detailRoutePath = '';
    const listRowKeyId = 'programServiceDocumentTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    //defaultListFilter.orderTerm = [new OrderTerm('programService_documentType_documentTypeName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProgramService';
    listDefinition.parentId = programServiceId;
    listDefinition.parentKey = 'programService_programServiceId';
    listDefinition.methodParameters = programServiceId.toString();
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Add New Document Type to Program Service',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

   listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View File',
        icon: 'fad fa-file-image',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: AdminEvent.showFile
      }
    );
    return listDefinition;
  }

  loadProgramServiceFilesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('documentTypeName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('properties');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('actions');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    return columnDefinitions;
  }

  loadProviderLocationServicesListDefinition(serviceId: number, providerLocationId: number): IListDefinition {
    const listColumns = this.loadProviderLocationServicesListColumns(serviceId, providerLocationId);
    const listObjectLabel = 'Location Service';
    const listObjectController = 'ProviderLocationService';
    const listStoreName = serviceId > 0 ? AdminStore.servicePls : AdminStore.locationServices;
    const detailRoutePath = 'loc-svc';
    const listRowKeyId = 'providerLocationServiceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = serviceId > 0 ? [new OrderTerm('providerLocation_locationName')]
      : providerLocationId > 0 ? [new OrderTerm('service_serviceName')]
        : [];

    let listDefinition = this.dynamicListService.createListDefinition('locSvcOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = serviceId > 0 ? serviceId : providerLocationId;
    listDefinition.parentKey = serviceId > 0 ? 'service_serviceId' : 'providerLocation_providerLocationId';
    listDefinition.controllerMethod = serviceId > 0 ? 'ByService' : 'ByProviderLocation';
    listDefinition.methodParameters = serviceId > 0 ? serviceId.toString() : providerLocationId.toString();
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    return listDefinition;
  }

  loadProviderLocationServicesListColumns(serviceId: number, providerLocationId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;
    
    colDef = new ListColumn(serviceId > 0 ? 'providerLocation' : 'service');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('accountingLocationId');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('keyRecTime');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('slotBuffer');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadBranchNotesListDefinition(branchId: number): IListDefinition {
    const listObjectLabel = 'Branch Note';
    const listObjectController = 'Note';
    const listStoreName = AdminStore.branchNotes;
    const detailRoutePath = '';
    const listRowKeyId = 'noteId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('createDate', false)];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      null,
      detailRoutePath);

    listDefinition.parentId = branchId;
    listDefinition.parentKey = 'branch_branchId';
    listDefinition.controllerMethod = 'ByBranch';
    listDefinition.methodParameters = branchId.toString();
    listDefinition.allowSaveFilter = false;

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadDocumentTypesListDefinition(): IListDefinition {
    const listColumns = this.loadDocumentTypesListColumns();
    const listObjectLabel = 'Document Type';
    const listObjectController = 'DocumentType';
    const listStoreName = AdminStore.documentTypes;
    const detailRoutePath = 'document-type';  
    const listRowKeyId = 'documentTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('documentTypeName')];

    let listDefinition = this.dynamicListService.createListDefinition('documentTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey =  '';
    listDefinition.controllerMethod = 'Index';
    listDefinition.showFilter = true;
    listDefinition.allowSaveFilter = true;

    //Create and delete are only enabled in dev mode
    if (this.isDeleteEnabled) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
    ];
    if (this.isDeleteEnabled) {
      crudButtons.push({ type: ButtonType.delete, defaults: null });
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View File',
        icon: 'fad fa-file-image',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: AdminEvent.showFile,
        enabledMethod: 'canViewTemplate',
        methodService: 'adminDynamicListService',
      }
    );

    return listDefinition;
  }

  loadDocumentTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('documentTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('entity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isExternal');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isOutgoing');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isAssignable');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isRequired');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isVisible');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isVersioned');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    //colDef = new ListColumn('alwaysCreateNew');
    //colDef.visibleOnSmall = false;
    //colDef.visibleOnMedium = false;
    //colDef.visibleOnLarge = false;
    //columnDefinitions.push(colDef);

    //colDef = new ListColumn('allowMultiple');
    //colDef.visibleOnSmall = false;
    //colDef.visibleOnMedium = false;
    //colDef.visibleOnLarge = false;
    //columnDefinitions.push(colDef);

    colDef = new ListColumn('sortOrder');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadShippersListDefinition(): IListDefinition {
    const listColumns = this.loadShippersListColumns();
    const listObjectLabel = 'Shipper';
    const listObjectController = 'Shipper';
    const listStoreName = AdminStore.shippers;
    const detailRoutePath = 'shipper';
    const listRowKeyId = 'shipperId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('shipperName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('shipperOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Shipper Payouts',
        icon: 'far fa-sack-dollar',
        cssName: 'app-btn-icon--success',
        eventName: AdminEvent.manageShipperPayouts,
        enabled: true
      },
      {
        title: 'Manage Shipper Locations',
        icon: 'fad fa-location',
        cssName: 'app-btn-icon--custom-6',
        eventName: AdminEvent.manageShipperLocations,
        enabled: true
      }
    );
    return listDefinition;
  }

  loadShippersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('shipperName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('defaultLeadTimeDays');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('defaultShipperDesignation');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerNumber');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadShipperLocationsListDefinition(shipperId: number): IListDefinition {
    const listColumns = this.loadShipperLocationsListColumns();
    const listObjectLabel = 'Shipper Location';
    const listObjectController = 'ProviderLocationShipperInventorySetup';
    const listStoreName = AdminStore.shipperLocations;
    const detailRoutePath = 'shipper-location';
    const listRowKeyId = 'providerLocationShipperInventorySetupId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('providerLocation_locationName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('shipperLocationOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = shipperId;
    listDefinition.parentKey = 'shipper_shipperId';
    listDefinition.controllerMethod = 'ByShipper';
    listDefinition.methodParameters = shipperId.toString();
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Shipper Location Payouts',
        icon: 'far fa-sack-dollar',
        cssName: 'app-btn-icon--success',
        eventName: AdminEvent.manageShipperLocationPayouts,
        enabled: true
      }

    );
    return listDefinition;
  }

  loadShipperLocationsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('providerLocation');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('shipperDesignation');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('leadTimeDays');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('timeCutoff');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobScheduleSummary');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadShipperPayoutsListDefinition(shipperId: number): IListDefinition {
    const listColumns = this.loadShipperPayoutsListColumns();
    const listObjectLabel = 'Shipper Payout';
    const listObjectController = 'WorkOrderItemShipper';
    const listStoreName = AdminStore.shipperPayouts;
    const detailRoutePath = 'shipper-payout';
    const listRowKeyId = 'workOrderItemShipperId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('payout_payoutName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('shipperPayoutOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = shipperId;
    listDefinition.parentKey = 'shipper_shipperId';
    listDefinition.controllerMethod = 'ByShipper';
    listDefinition.methodParameters = shipperId.toString();
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadShipperPayoutsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('itemName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('shipperItemProductDesignation');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('inventoryItemUnit');
    colDef.visibleOnSmall = false;
   columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadShipperLocationPayoutsListDefinition(providerLocationShipperInventorySetupId: number): IListDefinition {
    const listColumns = this.loadShipperLocationPayoutsListColumns();
    const listObjectLabel = 'Shipper Location Payout';
    const listObjectController = 'WorkOrderItemShipperProviderLocation';
    const listStoreName = AdminStore.shipperLocationPayouts;
    const detailRoutePath = 'shipper-location-payout';
    const listRowKeyId = 'workOrderItemShipperProviderLocationId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('workOrderItemShipper_payout_payoutName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('shipperLocationPayoutOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = providerLocationShipperInventorySetupId;
    listDefinition.parentKey = 'providerLocationShipperInventorySetup_ providerLocationShipperInventorySetupId';
    listDefinition.controllerMethod = 'ByProviderLocationShipperInventorySetup';
    listDefinition.methodParameters = providerLocationShipperInventorySetupId.toString();
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadShipperLocationPayoutsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('payoutItemName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('leadTimeDays');
    colDef.visibleOnSmall = false;
   columnDefinitions.push(colDef);

    colDef = new ListColumn('buffer');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unallocated');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSurveysListDefinition(): IListDefinition {
    const listColumns = this.loadSurveysListColumns();
    const listObjectLabel = 'Survey';
    const listObjectController = 'Survey';
    const listStoreName = AdminStore.surveys;
    const detailRoutePath = 'survey';
    const listRowKeyId = 'surveyId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('surveyName')];

    let listDefinition = this.dynamicListService.createListDefinition('surveyOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Create Survey Wizard',
        icon: 'fad fa-wand-magic-sparkles',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.launchSurveyWizard
      }
    );

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Survey Questions',
        icon: 'fad fa-clipboard-question',
        cssName: 'app-btn-icon--custom-1',
        enabled: true,
        eventName: AdminEvent.showSurveyQuestions
      },
      {
        title: 'Related Program Services',
        icon: 'fad fa-folder-tree',
        cssName: 'app-btn-icon--custom-2',
        enabled: true,
        eventName: AdminEvent.showProgramServices
      }
    );

    return listDefinition;
  }

  loadSurveysListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('surveyName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('numQuestions');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isActive');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSurveyQuestionDataTypesListDefinition(): IListDefinition {
    const listColumns = this.loadSurveyQuestionDataTypesListColumns();
    const listObjectLabel = 'Survey Question Data Type';
    const listObjectController = 'SurveyQuestionDataType';
    const listStoreName = AdminStore.surveyQuestionDataTypes;
    const detailRoutePath = 'survey-question-data-type';
    const listRowKeyId = 'surveyQuestionDataTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('questionTypeName')];

    let listDefinition = this.dynamicListService.createListDefinition('surveyDataTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadSurveyQuestionDataTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('questionTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('parameterType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('canAggregate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('minValue');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('maxValue');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSurveyQuestionsListDefinition(surveyId: number): IListDefinition {
    const listColumns = this.loadSurveyQuestionsListColumns();
    const listObjectLabel = 'Survey Question';
    const listObjectController = 'SurveyQuestion';
    const listStoreName = AdminStore.surveyQuestions;
    const detailRoutePath = 'survey-question';
    const listRowKeyId = 'surveyQuestionId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('surveyQuestionText')];

    let listDefinition = this.dynamicListService.createListDefinition('surveyQuestionOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = surveyId;
    listDefinition.parentKey = 'survey_surveyId';
    listDefinition.controllerMethod = 'BySurvey';
    listDefinition.methodParameters = surveyId.toString();
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Project Mappings',
        icon: 'fad fa-swords',
        cssName: 'app-btn-icon--custom-3',
        enabled: true,
        eventName: AdminEvent.showQuestionProjectMappings
      },
      {
        title: 'Import Mappings',
        icon: 'fad fa-signs-post',
        cssName: 'app-btn-icon--custom-4',
        enabled: true,
        eventName: AdminEvent.showQuestionImportMappings
      }

    );

    return listDefinition;
  }

  loadSurveyQuestionsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('surveyQuestionText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('surveyQuestionDataType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('required');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowMultiple');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('usedInScoring');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isSurveyDate');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('impactsCrewAssignmentRate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
    }

  //At surveyQuestion level
  loadSurveyProjectMapFieldsListDefinition(surveyQuestionId: number): IListDefinition {
    const listColumns = this.loadSurveyProjectMapFieldsListColumns();
    const listObjectLabel = 'Survey Project Map Field';
    const listObjectController = 'SurveyProjectMapField';
    const listStoreName = AdminStore.surveyProjectMapFields;
    const detailRoutePath = 'survey-proj-map-field';
    const listRowKeyId = 'surveyProjectMapFieldId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;

    let listDefinition = this.dynamicListService.createListDefinition('surveyProjMapFieldOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = surveyQuestionId;
    listDefinition.parentKey = 'surveyQuestion_surveyQuestionId';
    listDefinition.controllerMethod = 'BySurveyQuestion';
    listDefinition.methodParameters = surveyQuestionId.toString();

    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadSurveyProjectMapFieldsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('entity');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('position');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('length');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('positionOrder');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  //At surveyQuestion level
  loadSurveyImportMappingsListDefinition(surveyQuestionId: number): IListDefinition {
    const listColumns = this.loadSurveyImportMappingsListColumns();
    const listObjectLabel = 'Survey Import Mapping';
    const listObjectController = 'SurveyImportMapping';
    const listStoreName = AdminStore.surveyImportMappings;
    const detailRoutePath = '';
    const listRowKeyId = 'surveyImportMappingId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = surveyQuestionId;
    listDefinition.parentKey = 'surveyQuestion_surveyQuestionId';
    listDefinition.controllerMethod = 'BySurveyQuestion';
    listDefinition.methodParameters = surveyQuestionId.toString();

    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;

    //Not implemented
    //const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    //listDefinition.toolButtons.splice(0, 0, newTools[0]);

    //let crudButtons: IListButtonType[] = [
    //  { type: ButtonType.detail, defaults: null },
    //  { type: ButtonType.edit, defaults: null },
    //  { type: ButtonType.delete, defaults: null }
    //];
    //listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadSurveyImportMappingsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('surveyQuestionChoice');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('importMappingText');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSurveyProgramServicesListDefinition(surveyId: number): IListDefinition {
    const listColumns = this.loadSurveyProgramServicesListColumns();
    const listObjectLabel = 'Survey Program Service';
    const listObjectController = 'SurveyProgramService';
    const listStoreName = AdminStore.surveyProgramServices;
    const detailRoutePath = 'survey-pgm-svc';
    const listRowKeyId = 'surveyProgramServiceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;

    let listDefinition = this.dynamicListService.createListDefinition('surveyPgmSvcOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = surveyId;
    listDefinition.parentKey = 'survey_surveyId';
    listDefinition.controllerMethod = 'BySurvey';
    listDefinition.methodParameters = surveyId.toString();

    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadSurveyProgramServicesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef: IListColumn;

    colDef = new ListColumn('programService');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWarrantyTypesListDefinition(): IListDefinition {
    const listColumns = this.loadWarrantyTypesListColumns();
    const listObjectLabel = 'Warranty Type';
    const listObjectController = 'WarrantyType';
    const listStoreName = AdminStore.warrantyTypes;
    const detailRoutePath = 'warranty-type';
    const listRowKeyId = 'warrantyTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('warrantyTypeName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('warrantyTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Sub Types',
        icon: 'fad fa-tags',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.manageWarrantyTypeSubTypes,
        enabled: true
      }
    );
    return listDefinition;
  }

  loadWarrantyTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('warrantyTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantyTypeDescription');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('impactsCrewAssignmentRate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWarrantySubTypesListDefinition(warrantyTypeId: number): IListDefinition {
    const listColumns = this.loadWarrantySubTypesListColumns();
    const listObjectLabel = 'Warranty Sub Type';
    const listObjectController = 'WarrantySubType';
    const listStoreName = AdminStore.warrantySubTypes;
    const detailRoutePath = 'warranty-sub-type';
    const listRowKeyId = 'warrantySubTypeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('warrantySubTypeName', true)];

    let listDefinition = this.dynamicListService.createListDefinition('warrantySubTypeOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = warrantyTypeId;
    listDefinition.parentKey = 'warrantyType_warrantyTypeId';
    listDefinition.controllerMethod = 'ByWarrantyType';
    listDefinition.methodParameters = warrantyTypeId.toString();
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadWarrantySubTypesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('warrantySubTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantySubTypeDescription');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}

